import { Component, OnInit, Input } from '@angular/core';
import { RouteService } from '@services/route.service';
import { UserService } from '@services/user.service';
import { ProductService } from '@services/product.service';
import { Product, Rating } from '@models/product';

@Component({
  selector: 'gob-rating-form',
  templateUrl: './rating-form.component.html',
  styleUrls: ['./rating-form.component.css']
})
export class RatingFormComponent implements OnInit {
  @Input() product: Product;
  loading: boolean=false;
  error: string;
  msg: string;
  rating: Rating;
  token: string;

  constructor(public userServ: UserService, public route: RouteService, private pServ: ProductService) {
    this.rating=new Rating();
    this.rating.rate=5;
  }

  ngOnInit(): void {
  }

  rate(){
    this.loading=true;
    this.pServ.rateProduct(this.product.ident, this.rating, this.token).subscribe({
      next: () => {
        this.msg="Merci d'avoir donné votre avis";
        this.loading=false;
      },
      error: e => {
        this.error=e;
        this.loading=false;
      }
    });
  }

}
