import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '../../ui/ui.module';
import { DepotPageComponent } from './depot-page/depot-page.component';
import { DepotListComponent } from './depot-list/depot-list.component';
import { DepotFormComponent } from './depot-form/depot-form.component';



@NgModule({
  declarations: [
    DepotPageComponent,
    DepotListComponent,
    DepotFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UiModule
  ],
  exports: [
    DepotPageComponent
  ]
})
export class StockModule { }
