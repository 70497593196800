<div class="search-container">
	<div>
		<div class="search-content">
			<div class="content">
				<h1>La Caverne Du Gobelin</h1>
				<div class="subtitle">
					+10 000 références. Ici, vous vous amuserez toujours
				</div>
				<div class="input-group mb-3">
				  <div class="form-floating">
				    <input (focus)="search.search(true)" type="text" class="border-gradient-left form-control" id="search-home" placeholder="Rechercher..."/>
				    <label for="search-home">Rechercher...</label>
				  </div>
				  <button class="input-group-text brun">
					Rechercher
				  </button>
				</div>
			</div>
			<div class="search-elements">
				<a title="Café jeux" class="border-gradient search-element box-shadow">
					<span class="search-element-icon">
						<span>
							<i class="fad fa-puzzle"></i>
						</span>
					</span>
					<span>
						Un RDV ludique ?
						Venez jouer dans nos café jeux
					</span>
				</a>
				<a title="nos animations" class="border-gradient search-element box-shadow">
					<span class="search-element-icon">
						<span>
							<i class="fad fa-trophy-star"></i>
						</span>
					</span>
					<span>
						Vivez l'action avec nos animations
					</span>
				</a>
				<a title="carte cadeaux" class="border-gradient search-element box-shadow">
					<span class="search-element-icon">
						<span>
							<i class="fad fa-gift-card"></i>
						</span>
					</span>
					<span>
						Offrez de l'aventure avec nos cartes cadeaux
					</span>
				</a>
			</div>
		</div>
	</div>
</div>
