import { Component, OnInit } from '@angular/core';
import { RouteService } from '@services/route.service';

@Component({
  selector: 'gob-depot-page',
  templateUrl: './depot-page.component.html',
  styleUrls: ['./depot-page.component.css']
})
export class DepotPageComponent implements OnInit {

  constructor(public route: RouteService) { }

  ngOnInit(): void {
  }

}
