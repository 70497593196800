export class MoneticoForm{
	TPE: string;
	contexte_commande: string;
	date: string;
	lgue: string;
	montant: string;
	reference: string;
	societe: string;
	url_retour_err: string;
	url_retour_ok: string;
	version: string;
	MAC: string;
}

export class Payment{
	ident: string;
	idOrder: string;
	dateCreate: string;
	type: string;
	attributes: any;
}