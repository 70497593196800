import { Ship } from '@models/shipping';
import { Payment } from '@models/paiement';
import { Address } from '@models/user';

export class Discount{
	ident: string;
	name: string;
	codeNumber: string;
	amount: number=0;
	amountUsed: number;
	type: '%' | '€'='%';
	isReusable: boolean=false;
	state: string;
}

export class StateOrder{
	ident: string;
	name: string;
	mail: string;
	editable: boolean=true;
	state: 'actif' | 'supprimé'='actif';
}

export class Order{
	ident?: string;
	dateCreate: string;
	customer: string;
	address: string | Address;
	addressInvoice?: string | Address;
	dCollect?: string;
	hCollect?: string=null;
	discount?: string;
	relayPoint?: string;
	shipper?: string;			//uniquement pour l'envoi au ws création commande
	amountHT: number;
	amountTTC: number;
	amountShipping: number;
	amountShippingHT: number;
	weight: number;
	publicComment: string;
	privateComment?: string;
	state: string;
	transactions: Transaction[]=[];
	ship?: string;
	shipObj?: Ship;
	payment?: string;
	paymentObj?: Payment;
	addressObj?: Address;
	addressInvoiceObj?: Address;
	refunds: Refund[]=[];
}

export class Transaction{
	ident?: string;
	refund?: string;	//refund
	tRefund?: string;	//transaction refund
	price: string;
	qte: number;
	name?: string;
	ref?: string;
	tva?: number;
	amountHT?: number;
	amountTTC?: number;
	amountHTRemise?: number;
	amountTTCRemise?: number;
	amountTVA?: number;

	constructor(price?: string, qte?: number){
		this.price=price;
		this.qte=qte;
	}
}

export class Refund{
	ident: string;
	idOrder: string;
	dCreate: string;
	hCreate: string;
	payment: string;
	amountHT: number;
	amountTTC: number;
	weight: number;
}

export class RefundRequest{
	transactions: {transaction: string, qte: number}[];
	restock: boolean=true;
	paymentType: 'paypal-payment' | 'tpe-payment' | 'divers-payment'=null;
	refund: boolean=true;
}