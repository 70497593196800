<div [loading]="loading">
	<h1 class="force-title">Mot de passe oublié</h1>
	<form (ngSubmit)="forgotPass(mail.value)" class="form-log" #forgot="ngForm">
		<gob-message [msg]="msg"></gob-message>
		<div class="form-floating mb-3">
		  <input type="text" class="form-control" id="log-log" placeholder="Login" name="mail" #mail ngModel required email/>
		  <label for="log-log">Login</label>
		</div>
		<button scrollTo type="submit" class="btn green" [disabled]="forgot.invalid">Valider</button>
	</form>
	<div class="form-footer">
		<a (click)="login.emit(true)">Retour à la page de connexion</a>
	</div>
</div>