<form (ngSubmit)="search.emit(filter)" #form="ngForm">
	<div class="row g-3">
		<!-- email -->
	  	<div *ngIf="admin" class="col-sm">
			<div class="form-floating mb-3 nsb">
			  <input type="text" class="form-control" id="order-filter-mail" placeholder="E-mail client" name="email" [(ngModel)]="filter.email"/>
			  <label for="order-filter-mail">E-mail client</label>
			</div>
		</div>

		<!-- shop -->
	  	<div class="col-sm">
	        <div class="form-floating mb-3 nsb">
	          <select class="form-select" id="order-filter-shop" name="shop" [(ngModel)]="filter.shop">
	            <option [ngValue]="null || undefined">Choisissez une boutique</option>
	            <option *ngFor="let depot of depots" [ngValue]="depot.address">{{depot.name}}</option>
	          </select>
	          <label for="order-filter-shop">Boutique Click & Collect</label>
	        </div>
	    </div>

		<!-- etat -->
	  	<div class="col-sm">
	        <div class="form-floating mb-3">
	          <select class="form-select" id="order-filter-state" name="state" [(ngModel)]="filter.state">
	            <option [ngValue]="null || undefined">Choisissez un état</option>
	            <option *ngFor="let state of states" [ngValue]="state.ident">{{state.name}}</option>
	          </select>
	          <label for="order-filter-state">Etat de la commande</label>
	        </div>
	    </div>
    </div>

	<div class="row g-3">
		<!-- start -->
	  	<div class="col-sm">
			<div class="form-floating mb-3 nsb">
			  <input type="date" class="form-control" id="order-filter-start" placeholder="Depuis" name="start" [(ngModel)]="filter.dateStart"/>
			  <label for="order-filter-start">Depuis</label>
			</div>
		</div>

		<!-- end -->
	  	<div class="col-sm">
			<div class="form-floating mb-3 nsb">
			  <input type="date" class="form-control" id="order-filter-end" placeholder="Jusqu'à" name="end" [(ngModel)]="filter.dateEnd"/>
			  <label for="order-filter-end">Jusqu'à</label>
			</div>
		</div>

	  	<div class="col-sm-1">
	  		<button type="submit" class="btn btn-primary search">
	  			<i class="fa-light fa-magnifying-glass"></i>
	  		</button>
	  	</div>
	</div>
</form>