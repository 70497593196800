import { Component, OnInit, Input } from '@angular/core';
import { forkJoin } from 'rxjs';
import { UserService } from '@services/user.service';
import { RouteService } from '@services/route.service';
import { User, Role, Right, Group } from '@models/user';

@Component({
  selector: 'gob-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {
  user: User;
  error: string;
  msg: string;
  roles: Role[]=[];
  allRoles: Role[]=[];
  allRights: Right[]=[];
  groups: Group[];
  rights: Right[]=[];
  group: Group;
  loading: boolean=false;
  _admin: User;

  @Input() 
  set admin(value: User){
    this._admin=value;
    if(value)
      this.user=value;
    this.init();
  }

  get admin(){
    return this._admin;
  }

  constructor(private userServ: UserService, public route: RouteService) {
    this.loading=true;
    this.user=new User();
    forkJoin({
      roles: this.userServ.getRoles(),
      rights: this.userServ.getRights(),
      groups: this.userServ.getGroups()
    }).subscribe(values => {
      this.allRoles=values.roles;
      this.allRights=values.rights;
      this.groups=values.groups;
      this.init();
    })
  }

  ngOnInit(): void {
  }

  init(){
    this.loading=true;
    let id: string=null;

    if(this.admin){
      id=this.admin.ident;
    }
    else
      this.user=this.userServ.myAccount();

    this.userServ.userGlobals(id).subscribe(data => {
      this.roles=data.roles;
      this.rights=data.rights;
      this.group=data.group;
      this.loading=false;
    });
  }

  rmRole(id: string){
    this.user.roles.splice(this.user.roles.findIndex(r => r==id), 1);
  }

  findRole(id: string){
    return this.allRoles.find(role => role.ident==id);
  }

  getRoleList(){
    let result: Role[]=[];
    this.allRoles.forEach(role => {
      if(!this.user.roles.find(r => r==role.ident))
        result.push(role);
    });
    return result;
  }

  getRightList(){
    let result: Right[]=[];
    this.user.roles.forEach(ro => {  
      let role: Role=this.allRoles.find(e => e.ident==ro);
      if(role){
        role.rights.forEach(right => {
          if(!result.find(e => e.name==right))
            result.push(this.allRights.find(foo => foo.name==right));
        }); 
      }
    });
    return result;
  }

  findRight(name: string){
    return this.rights.find(right => right.name==name);
  }

  update(){
    this.loading=true;
    this.userServ.setUserInfo(this.user)
      .subscribe({
        next: user => {
          this.msg="Modifications enregistrées";
          this.user=user;
          this.loading=false;
        },
        error: error => {
          this.error=error;
          this.loading=false;
        }
      });
  }
}
