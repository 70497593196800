import { Component, OnInit } from '@angular/core';
import { RouteService } from '@services/route.service';

@Component({
  selector: 'gob-ko-payment',
  templateUrl: './ko-payment.component.html',
  styleUrls: ['./ko-payment.component.css']
})
export class KoPaymentComponent implements OnInit {

  constructor(public route: RouteService) { }

  ngOnInit(): void {
  }

}
