import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder } from '@angular/forms';
import { filter, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { SearchService } from '@services/search.service';
import { ProductService } from '@services/product.service';
import { ProductFilter } from '@models/filter';
import { Product } from '@models/product';
import { environment } from '@environments/environment';
import { RouteService } from '@services/route.service';

@Component({
  selector: 'gob-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  loading: boolean=false;
  text: FormControl;
  products: Product[]=[];

  constructor(
    public search: SearchService,
    private fb: FormBuilder,
    private pServ: ProductService,
    public route: RouteService
  ) {
    this.text=fb.control('');
    this.text.valueChanges
      .pipe(
        filter(query => query.length>=3),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(value => {
          this.loading=true;
          let filter: ProductFilter=new ProductFilter();
          filter.fullname=value;
          return this.pServ.searchProducts(filter);
        })
      )
      .subscribe((products: Product[]) => {
        this.loading=false;
        this.products=products;
      });
  }

  ngOnInit(): void {
  }

  open(){
    this.search.search(true);
  }

  close(){
    this.search.search(false);
  }

  getImage(product: Product){
    return `${environment.imgUrl}/product/${product.images[0]?.name}`;
  }
}
