import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Discount } from '@models/order';
import { OrderService } from '@services/order.service';
import { RouteService } from '@services/route.service';

@Component({
  selector: 'gob-promo-form',
  templateUrl: './promo-form.component.html',
  styleUrls: ['./promo-form.component.css']
})
export class PromoFormComponent implements OnInit {
  loading: boolean=false;
  error: string;
  msg: string;
  discount: Discount;

  constructor(private router: ActivatedRoute, private orderServ: OrderService, public route: RouteService) {
    this.loading=true;
    this.discount=new Discount();
    this.router.paramMap
      .pipe(
        switchMap(param => {
          if(param.get('id'))
            return this.orderServ.getDiscount(param.get('id'))
              .pipe(
                map(discount => JSON.parse(JSON.stringify(discount)))
              );
          else
            return of(new Discount());
        })
      ).subscribe({
        next: (discount: Discount) => {
          this.discount=discount;
          this.loading=false;
        },
        error: e => {
          this.error=e;
          this.loading=false;
        }
      })
  }

  ngOnInit(): void {
  }

  getClass(){
    return {
      'text-bg-success': (this.discount.state=='actif'),
      'text-bg-danger': (this.discount.state=='désactivé')
    };
  }

  update(){
    this.loading=true;
    this.orderServ.setDiscount(this.discount).subscribe({
      next: discount => {
        this.discount=discount;
        this.msg="Modifications enregistrées";
        this.loading=false;
      },
      error: e => {
        this.error=e;
        this.loading=false;
      }
    });
  }

  disable(){
    if(confirm('Êtes-vous sûr de vouloir supprimer ?')){
      this.loading=true;
      this.orderServ.disableDiscount(this.discount.ident).subscribe({
        next: discount => {
          this.discount=discount;
          this.msg="Modifications enregistrées";
          this.loading=false;
        },
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
    }
  }

}
