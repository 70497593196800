<div [loading]="loading">
	<gob-header></gob-header>
	<div class="block form-admin">
		<gob-step-order [ind]="1"></gob-step-order>
		<div class="back-container">
			<gob-back [url]="route.get('cart')"></gob-back>
		</div>
		<h2 class="admin">Mode de livraison</h2>
		<div>
			<ul class="list-group">
			  <!-- transporteur -->
			  <li *ngFor="let shipper of shippers" class="list-group-item">
			    <input (change)="setRelay()" class="form-check-input me-1" type="radio" name="shipper" id="shipper-{{shipper.ident}}" value="{{shipper.ident}}" [(ngModel)]="order.shipper">
			    <label class="form-check-label" for="shipper-{{shipper.ident}}">
			    	{{shipper.name}} - {{getShipping(shipper).amount}}€
			    </label>
			  </li>
			  
			  <!-- click and collect -->
			  <li *ngIf="depots.length" class="list-group-item">
			    <input (change)="setClickCollect(cc.checked)" class="form-check-input me-1" type="radio" name="shipper" id="shipper-cc" #cc>
			    <label class="form-check-label" for="shipper-cc">
			    	Retrait en magasin
			    </label>
			  </li>
			</ul>
		</div>

		<!-- map -->
		<div id="relay-map-mr" [ngClass]="{hidden: order.shipper!='mondial-relay'}"></div>
		<div id="relay-map-colissimo" [ngClass]="{hidden: order.shipper!='colissimo-relay'}"></div>
		<input id="relay-point" type="hidden" (change)="setRelayID()" value="" name="relaypoint" #relaypoint/>

		<!-- liste click & collect -->
		<div *ngIf="depotSelected" class="cc-container">
			<div class="row">
  			  	<div class="col-5">
					<!-- List group -->
					<div class="list-group">
						<a (click)="setDepot(i)" *ngFor="let depot of depots; first as isFirst; index as i" class="list-group-item list-group-item-action" [ngClass]="{active: (depotSelected.ident==depot.ident)}">
							<b>{{depot.name}}</b><br/>
						    <span *ngIf="address && depotSelected.ident==depot.ident" class="mb-1">
								{{address?.address}}<br/>
								{{address?.cp}}, {{address?.city}}
						   	</span>
						</a>
					</div>
			  	</div>

			  <!-- liste des horaires -->
  			  	<div class="col-7">
					<div class="form-floating mb-3">
			          <select (change)="setDate(dateSelect.value)" class="form-select" name="hour" id="d-cc" #dateSelect>
			            <option>Jour du retrait</option>
			            <option *ngFor="let date of datesSelected; index as i" value="{{i}}">{{ date.date | date:'d, MMMM yyyy':'':'fr' }} de {{date.start | slice:0:2}}h à {{date.end | slice:0:2}}h</option>
			          </select>
			          <label for="d-cc">Jour</label>
			        </div>

				  	<!-- liste des horaires -->
			        <div class="form-floating mb-3">
			          <select class="form-select" id="h-cc" name="hour" [(ngModel)]="order.hCollect">
			            <option [ngValue]="null">Heure du retrait</option>
			            <option *ngFor="let hour of hoursSelected" value="{{hour}}">{{hour | slice:0:2}}h{{hour | slice:3:5}}</option>
			          </select>
			          <label for="h-cc">Heure</label>
			        </div>
				</div>
			</div>
		</div>

		<div class="next">
			<button scrollTo type="button" (click)="next()" class="next btn green" [disabled]="!canNext()">Valider</button>
		</div>
	</div>
</div>