<gob-header title="Jeux de société"></gob-header>
<div class="block products-contrainer" [loading]="loading">
	<div class="filter">
		<gob-filter-product (filter)="filterProduct($event)"></gob-filter-product>
	</div>
	<div class="list-container">
		<div class="list-choice">
			<button (click)="isGrid=true" type="button" [ngClass]="{selected: isGrid}" class="btn btn-secondary"><i class="fad fa-grid"></i></button>
			<button (click)="isGrid=false" type="button" [ngClass]="{selected: !isGrid}" class="btn btn-secondary"><i class="fad fa-line-height"></i></button>
		</div>

	    <div *ngIf="!products.length" class="no-content">
	    	Aucun produit trouvé
	    </div>
	    <div *ngIf="products.length">
			<gob-list-card *ngIf="isGrid" [products]="products"></gob-list-card>
			<gob-list-line *ngIf="!isGrid" [products]="products"></gob-list-line>
			<div class="page-container">
	  			<gob-pagination [max]="max" (change)="next($event, filter)" [activePage]="page"></gob-pagination>
			</div>
		</div>
	</div>
</div>