import { Component, OnInit, Input } from '@angular/core';
import { StockService } from '@services/stock.service';
import { Product } from '@models/product';
import { Depot, Stock } from '@models/stock';

@Component({
  selector: 'gob-product-stock',
  templateUrl: './product-stock.component.html',
  styleUrls: ['./product-stock.component.css']
})
export class ProductStockComponent implements OnInit {
  @Input() product: Product;
  loading: boolean=false;
  depots: Depot[]=[];

  constructor(private stockServ: StockService) {
    this.loading=true;
    this.stockServ.getDepots().subscribe(depots => {
      this.depots=depots;
      this.loading=false;
    });
  }

  ngOnInit(): void {
  }

  getDepot(ident: string){
    return this.depots.find(depot => depot.ident==ident);
  }

  add(){
    let stock: Stock=new Stock();
    if(this.getDepotList().length)
      stock.depot=this.getDepotList()[0].ident;
    this.product.stocks.push(stock);
  }

  getDepotList(){
    let results: Depot[]=[];
    this.depots.forEach(depot => {
      if(!this.product.stocks.find(stock => stock.depot==depot.ident))
        results.push(depot);
    });
    return results;
  }

}
