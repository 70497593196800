import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { UiModule } from '../ui/ui.module';
import { PromoCodeModule } from './promo-code/promo-code.module';
import { OrderModule } from './order/order.module';
import { ShippingModule } from './shipping/shipping.module';
import { MenuModule } from './menu/menu.module';
import { ClassifyModule } from './classify/classify.module';
import { UserModule } from './user/user.module';
import { StockModule } from './stock/stock.module';
import { ProductModule } from './product/product.module';
import { SettingModule } from './setting/setting.module';
import { ReviewModule } from './review/review.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { GroupModule } from './group/group.module';
import { PartialModule } from '@components/page/partial/partial.module';

@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UiModule,
    PromoCodeModule,
    OrderModule,
    ShippingModule,
    MenuModule,
    ClassifyModule,
    UserModule,
    StockModule,
    ProductModule,
    GroupModule,
    PartialModule
  ],
  exports: [
    HomeComponent,
    PromoCodeModule,
    OrderModule,
    ShippingModule,
    MenuModule,
    ClassifyModule,
    UserModule,
    StockModule,
    ProductModule,
    SettingModule,
    ReviewModule,
    DashboardModule,
    GroupModule
  ]
})
export class AdminModule { }
