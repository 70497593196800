import { Component, OnInit, Input} from '@angular/core';
import { GlobalService } from '@services/global.service';
import { Product, Categ, SCateg, Gamme, Marque, Tag, Banner } from '@models/product';

@Component({
  selector: 'gob-product-class',
  templateUrl: './product-class.component.html',
  styleUrls: ['./product-class.component.css']
})
export class ProductClassComponent implements OnInit {
  @Input() product: Product;
  loading: boolean=false;
  categs: Categ[]=[];
  scategs: SCateg[]=[];
  gammes: Gamme[]=[];
  marques: Marque[]=[];
  banners: Banner[]=[];
  tags: Tag[]=[];

  constructor(private gServ: GlobalService) {
    this.loading=true;
    this.gServ.getClassification()
      .subscribe(classes => {
        this.categs=classes.categs;
        this.scategs=classes.scategs;
        this.gammes=classes.gammes;
        this.marques=classes.marques;
        this.tags=classes.tags;
        this.banners=classes.banners;
        this.loading=false;
      });
  }

  ngOnInit(): void {
  }

  rm(i: number){
    this.product.banners.splice(i, 1);
  }

  add(){
    let banner: Banner=new Banner();
    if(this.banners.length)
      banner.ident=this.banners[0].ident;
    this.product.banners.push(banner);
  }

  rmTag(i: number){
    this.product.tags.splice(i, 1);
  }

  getTag(ident: string){
    return this.tags.find(tag => tag.ident==ident);
  }

  getTagList(){
    let result: Tag[]=[];
    this.tags.forEach(tag => {
      if(!this.product.tags.find(t => tag.ident==t.ident))
        result.push(tag);
    });
    return result;
  }

  getBanner(ident: string){
    return this.banners.find(banner => banner.ident==ident);
  }
}
