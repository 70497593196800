<div [loading]="loading">
	<h1 class="force-title">Connexion</h1>
	<form (ngSubmit)="login(mail.value, pass.value)" class="form-log" #logForm="ngForm">
		<gob-message [error]="error"></gob-message>
		<div class="form-floating mb-3">
		  <input type="email" class="form-control" id="log-log" placeholder="E-mail" name="mail" #mail ngModel required email/>
		  <label for="log-log">E-mail</label>
		</div>
		<div class="form-floating mb-3">
		  <input type="password" class="form-control" id="log-pass" placeholder="Mot de passe" name="pass" #pass ngModel required/>
		  <label for="log-pass">Mot de passe</label>
		</div>
		<button scrollTo type="submit" class="btn green" [disabled]="logForm.invalid">Valider</button>
	</form>
	<div class="form-footer">
		<a (click)="signup.emit(true)">Créer un compte</a>
		<span class="separator">-</span>
		<a (click)="forgot.emit(true)">Mot de passe oublié ?</a>
	</div>
</div>