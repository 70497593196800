<div class="block form-admin">
	<header class="header-admin">
		<h1 class="admin">Dashboard</h1>
	</header>
	<div class="list-container" [loading]="loading">
	  <div class="content-form">

	  	<!-- filter -->
	  	<gob-dashboard-filter (search)="initData($event)"></gob-dashboard-filter>

	  	<!-- contenu -->
	  	<div class="kpi-container">
	  		<div class="kpi">
	  			<i class="fa-light fa-box-dollar"></i>
	  			<div>
	  				<b>Nombre de commandes</b><br/>
		  			{{datas?.orders}}
		  		</div>
	  		</div>
	  		<div class="kpi">
	  			<i class="fa-light fa-money-bill-transfer"></i>
	  			<div>
	  				<b>HT</b><br/>
		  			{{datas?.CAHT | formatPrice}}€
		  		</div>
	  		</div>
	  		<div class="kpi">
	  			<i class="fa-light fa-landmark"></i> 
	  			<div>
	  				<b>TTC</b><br/>
		  			{{datas?.CATTC | formatPrice}}€
		  		</div>
	  		</div>
	  		<div class="kpi">
	  			<i class="fa-light fa-piggy-bank"></i>
	  			<div>
	  				<b>Marge</b><br/>
		  			{{datas?.marge | formatPrice}}€
		  		</div>
	  		</div>
	  	</div>
	  	<div class="chart-fullpage">
	  		<highcharts-chart 
	  			*ngIf="datas"
			    [Highcharts]="Highcharts"
			    [options]="chartOptions"
			    style="width: 100%; height: 400px; display: block;">
			</highcharts-chart>
	  	</div>
	  	<div *ngIf="datas" class="table-container">
	  		<!-- Top 10 produits -->
	  		<table class="table">
		      <thead>
		        <tr>
		          <th scope="col">Top 10 Produits</th>
		          <th class="price" scope="col"><i class="green fa-solid fa-euro-sign"></i></th>
		        </tr>
		      </thead>
		      <tbody class="table-group-divider">
		        <tr *ngFor="let product of datas.top10Products">
		          <td>{{product.name}}</td>
		          <td>
		            {{product.ttc | formatPrice}}€
		          </td>
		        </tr>
		      </tbody>
		    </table>

	  		<!-- Top 10 categ -->
	  		<table class="table">
		      <thead>
		        <tr>
		          <th scope="col">Top 10 catégories</th>
		          <th class="price" scope="col"><i class="green fa-solid fa-euro-sign"></i></th>
		        </tr>
		      </thead>
		      <tbody class="table-group-divider">
		        <tr *ngFor="let categ of datas.top10Categs">
		          <td>{{categ.name}}</td>
		          <td>
		            {{categ.ttc | formatPrice}}€
		          </td>
		        </tr>
		      </tbody>
		    </table>
	  	</div>

	  	<div *ngIf="datas" class="table-container">
	  		<!-- Top 10 scateg -->
	  		<table class="table">
		      <thead>
		        <tr>
		          <th scope="col">Top 10 Sous catég.</th>
		          <th class="price" scope="col"><i class="green fa-solid fa-euro-sign"></i></th>
		        </tr>
		      </thead>
		      <tbody class="table-group-divider">
		        <tr *ngFor="let scateg of datas.top10Scategs">
		          <td>{{scateg.name}}</td>
		          <td>
		            {{scateg.ttc | formatPrice}}€
		          </td>
		        </tr>
		      </tbody>
		    </table>

	  		<!-- Top 10 gamme -->
	  		<table class="table">
		      <thead>
		        <tr>
		          <th scope="col">Top 10 gammes</th>
		          <th class="price" scope="col"><i class="green fa-solid fa-euro-sign"></i></th>
		        </tr>
		      </thead>
		      <tbody class="table-group-divider">
		        <tr *ngFor="let gamme of datas.top10Gammes">
		          <td>{{gamme.name}}</td>
		          <td>
		            {{gamme.ttc | formatPrice}}€
		          </td>
		        </tr>
		      </tbody>
		    </table>
	  	</div>

	  	<div *ngIf="datas" class="table-container">
	  		<!-- Top 10 marque -->
	  		<table class="table">
		      <thead>
		        <tr>
		          <th scope="col">Top 10 marques</th>
		          <th class="price" scope="col"><i class="green fa-solid fa-euro-sign"></i></th>
		        </tr>
		      </thead>
		      <tbody class="table-group-divider">
		        <tr *ngFor="let marque of datas.top10Marques">
		          <td>{{marque.name}}</td>
		          <td>
		            {{marque.ttc | formatPrice}}€
		          </td>
		        </tr>
		      </tbody>
		    </table>
	  	</div>
	  </div>
  	</div>
</div>