<form #formStep="ngForm">
	<!-- Poids min -->
	<div class="form-floating mb-3">
	  <input type="text" class="form-control" id="shipping-min" placeholder="0,5" name="shipmin" [(ngModel)]="shipping.weightMin" required />
	  <label for="shipping-min">Poids minimum (kg)</label>
	</div>

	<!-- Poids max -->
	<div class="form-floating mb-3">
	  <input type="text" class="form-control" id="shipping-max" placeholder="2,5" name="shipmax" [(ngModel)]="shipping.weightMax" required />
	  <label for="shipping-max">Poids max (kg)</label>
	</div>

	<!-- Poids montant -->
	<div class="form-floating mb-3">
	  <input type="text" class="form-control" id="shipping-amount" placeholder="7,8" name="shipamount" [(ngModel)]="shipping.amount" required />
	  <label for="shipping-amount">Montant</label>
	</div>

	<!-- Poids tva -->
	<div class="form-floating mb-3">
	  <input type="text" class="form-control" id="shipping-tva" placeholder="20" name="shiptva" [(ngModel)]="shipping.tva" required />
	  <label for="shipping-tva">TVA</label>
	</div>
	<button (click)="valid(); formStep.reset()" class="btn green" [disabled]="formStep.invalid">Ajouter</button>
</form>