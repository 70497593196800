import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { PaymentService } from '@services/order.service';
import { MoneticoForm } from '@models/paiement';

@Component({
  selector: 'gob-monetico',
  templateUrl: './monetico.component.html',
  styleUrls: ['./monetico.component.css']
})
export class MoneticoComponent implements OnInit {
  @Input() order: string;
  @Input() submit: boolean=false;
  @ViewChild('formMonetico') formMonetico: ElementRef<HTMLFormElement>;
  form: MoneticoForm;

  constructor(private pServ: PaymentService, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    this.pServ.getMoneticoForm(this.order).subscribe(form => {
      this.form=form;
      if(this.submit){
        this.cdRef.detectChanges();
        this.formMonetico.nativeElement.submit();
      }
    });
  }

}
