import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '@components/ui/ui.module';
import { MyAddressesComponent } from './my-addresses/my-addresses.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { UserRoleModule } from './user-role/user-role.module';
import { UserListComponent } from './user-list/user-list.component';
import { UserAdminComponent } from './user-admin/user-admin.component';
import { UserFilterComponent } from './user-filter/user-filter.component';
import { NewsletterListComponent } from './newsletter-list/newsletter-list.component';


@NgModule({
  declarations: [
    MyAccountComponent,
    MyAddressesComponent,
    UserListComponent,
    UserAdminComponent,
    UserFilterComponent,
    NewsletterListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UiModule,
    UserRoleModule
  ],
  exports: [
    MyAccountComponent,
    MyAddressesComponent,
    UserRoleModule,
    UserListComponent,
    UserAdminComponent,
    NewsletterListComponent
  ]
})
export class UserModule { }
