import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class NotifyService{
	readonly notify: EventEmitter<Notification>=new EventEmitter<Notification>(); 

	set(title: string, message: string){
		this.notify.emit(new Notification(title, message));
	}
}

export class Notification{
	constructor(
		public title: string,
		public message: string
	){ }
}

