import { Injectable, ErrorHandler } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { RouteService } from '@services/route.service';
import { UserService } from '@services/user.service';

@Injectable()
export class NotConnectedInterceptor implements HttpInterceptor {
  constructor(private errorHandler: ErrorHandler, private routes: RouteService, private userServ: UserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        if(errorResponse.status===401){
          this.userServ.clear();
          this.routes.navigate('login');
        }

        return throwError(errorResponse);
      })
    );
  }
}