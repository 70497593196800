<div [loading]="loading">
	<gob-back [url]="route.get('roles-list')"></gob-back>
	<div class="form-content">
		<div class="form-admin-left">
			<gob-message [error]="error" [msg]="msg"></gob-message>
			<form #form="ngForm">
				<!-- nom -->
				<div class="form-floating mb-3">
				  <input type="text" class="form-control" id="role-name" placeholder="Nom" name="name" [(ngModel)]="role.name" required />
				  <label for="role-name">Nom</label>
				</div>
				<hr/>
				<div class="right-container">
					<div class="title-content">Droits utilisateur</div>
					<div *ngIf="role.rights.length">
						<a *ngFor="let right of role.rights" (click)="rmRight(right)" class="text-box close">{{right}}</a>					
					</div>
					<div *ngIf="!role.rights.length">
						Aucun droit affecté à ce rôle.
					</div>
				</div>
				<button scrollTo (click)="update(form)" class="btn green">{{((!role.ident)? 'Créer' : 'Modifier')}}</button>
			</form>
		</div>
		<div class="form-admin-right">
			<!-- Group et localisation -->
			<div class="card">
			  <div class="card-header">
			    <i class="fa-light fa-users-between-lines"></i> Droit
			  </div>
			  <div class="card-body">
				<div class="module-container">
					<div *ngIf="getRightList().length" class="input-group mb-3">
						<div class="form-floating">
						  <select class="form-select" id="role-right" #module>
						    <option *ngFor="let right of getRightList()" value="{{right.name}}">{{right.libelle}}</option>
						  </select>
						  <label for="role-right">Ajouter un droit</label>
						</div>
						<button (click)="addRight(module.value)" class="input-group-text brun">
							<i class="fa-solid fa-plus"></i>
					  	</button>
					</div>
					<div *ngIf="!getRightList().length" class="input-group mb-3">
						Tous les droits sont ajoutés
					</div>
				</div>
			  </div>
			</div>
		</div>
	</div>
</div>