<div [loading]="loading">
	<h2 class="admin black">Tag</h2>
	<gob-back [url]="route.get('class-list')"></gob-back>
	<div class="form-content">
		<form class="form-admin-left" #form="ngForm">
			<gob-message [error]="error" [msg]="msg"></gob-message>
			<!-- nom du code -->
			<div class="form-floating mb-3">
			  <input type="text" class="form-control" id="tag-name" placeholder="Nom tag" name="name" [(ngModel)]="tag.name" required />
			  <label for="tag-name">Nom du tag</label>
			</div>
			
			<!-- filters -->
			<div class="title-content">
				Filtres :
			</div>
			<div class="filter-container">
				<div *ngFor="let filter of filters; index as i" class="form-check form-switch mb-3">
				  <input (change)="setFilter(filter.ident, $event)" class="form-check-input" type="checkbox" id="filter-{{i}}" name="filter{{i}}" [checked]="isActive(filter.ident)" [disabled]="isDefault(filter.ident) || filter.ident=='filter-tag'"/>
				  <label class="form-check-label" for="filter-{{i}}">{{filter.name}}</label>
				</div>
			</div>

			<button scrollTo (click)="update(form)" class="btn green" [disabled]="form.invalid">{{((tag.ident)? "Modifier" : "Créer")}}</button>
			<button (click)="rm()" *ngIf="tag.ident" class="btn btn-danger">Supprimer</button>
		</form>
	</div>
</div>