import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';
import { RouteService } from '@services/route.service';
import { GlobalService } from '@services/global.service';
import { SCateg, Filter } from '@models/product';

@Component({
  selector: 'gob-scateg-form',
  templateUrl: './scateg-form.component.html',
  styleUrls: ['./scateg-form.component.css']
})
export class ScategFormComponent implements OnInit {
  loading: boolean=false;
  error: string;
  msg: string;
  scateg: SCateg;
  filters: Filter[]=[];

  constructor(public route: RouteService, private router: ActivatedRoute, private gServ: GlobalService) {
    this.loading=true;
    this.scateg=new SCateg();
    this.router.paramMap
      .pipe(
        switchMap(params => {
          if(params.get('id')){
            return this.gServ.getSCategs()
                      .pipe(
                        map(scategs => scategs.find(c => c.ident==params.get('id'))),
                        tap(scateg => this.scateg=JSON.parse(JSON.stringify(scateg)))
                      );
          }
          else
            return of(new SCateg());
        }),
        switchMap(() => this.gServ.getFilters()),
        tap(filters => this.filters=filters)
      )
      .subscribe({
        next: () => this.loading=false,
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
  }

  ngOnInit(): void {
  }

  isActive(ident: string){
    return (this.scateg.filters.findIndex(filter => filter==ident)!=-1 || this.isDefault(ident));
  }

  isDefault(ident: string){
    return (ident=="filter-price" || ident=="filter-age" || ident=="filter-players");
  }
  
  setFilter(ident: string, event: any){
    if(event.target.checked)
      this.scateg.filters.push(ident);
    else
      this.scateg.filters.splice(this.scateg.filters.findIndex(filter => filter==ident), 1);
  }

  update(form: any){
    this.loading=true;
    let update: boolean=(this.scateg.ident!=null);
    this.gServ.setSCateg(this.scateg).subscribe({
      next: () => {
        this.msg='Modification enregistrées';
        if(!update)
          form.reset();
        this.loading=false;
      },
      error: e => {
        this.error=e;
        this.loading=false;
      }
    });
  }

  rm(){
    if(confirm("Etes-vous sur de vouloir supprimer ?")){
      this.loading=true;
      this.gServ.rmSCateg(this.scateg.ident).subscribe({
        next: () => this.route.navigate('class-list'),
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
    }
  }
}
