import { Component, OnInit } from '@angular/core';
import { SearchService } from '@services/search.service';

@Component({
  selector: 'gob-search-home',
  templateUrl: './search-home.component.html',
  styleUrls: ['./search-home.component.css']
})
export class SearchHomeComponent implements OnInit {

  constructor(public search: SearchService) { }

  ngOnInit(): void {
  }

}
