import { Component, OnInit } from '@angular/core';
import { formatNumber } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, EMPTY, Observable } from 'rxjs';
import { switchMap, tap, catchError } from 'rxjs/operators';
import { RouteService } from '@services/route.service';
import { OrderService } from '@services/order.service';
import { StockService } from '@services/stock.service';
import { PaymentService } from '@services/order.service';
import { ShippingService } from '@services/shipping.service';
import { UserService } from '@services/user.service';
import { Order, StateOrder, RefundRequest } from '@models/order';
import { Shipper, Ship } from '@models/shipping';
import { Depot } from '@models/stock';
import { Pays } from '@models/user';
import { environment } from '@environments/environment';

@Component({
  selector: 'gob-order-page',
  templateUrl: './order-page.component.html',
  styleUrls: ['./order-page.component.css']
})
export class OrderPageComponent implements OnInit {
  loading: boolean=false;
  error: string;
  msg: string;
  admin: boolean=false;
  order: Order;
  state: StateOrder;
  shipper: Shipper;
  depot: Depot;
  pays: Pays[]=[];
  states: StateOrder[]=[];
  stateInit: string;
  submitMonetico: boolean=false;
  openRefund: boolean=false;

  constructor(
    private orderServ: OrderService,
    private router: ActivatedRoute,
    public route: RouteService,
    private shipServ: ShippingService,
    private stockServ: StockService,
    private userServ: UserService,
    private pServ: PaymentService
  ) {
    this.loading=true;
    this.order=new Order();
    this.admin=this.userServ.hasRight("orders");
    this.router.paramMap
      .pipe(
        switchMap(params => forkJoin({
            order: this.orderServ.getOrder(params.get('id')),
            shippers: this.shipServ.getShippers(),
            states: this.orderServ.getStates(),
            depots: this.stockServ.getDepotsClickCollect(),
            pays: this.userServ.getPays()
          }))
      )
      .subscribe(values => {
        this.order=values.order;
        this.pays=values.pays;
        this.states=values.states;
        this.stateInit=this.order.state;
        this.state=values.states.find(state => state.ident==this.order.state);
        if(this.order.ship)
          this.shipper=values.shippers.find((shipper: Shipper) => shipper.ident==this.order.shipObj.shipper);
        this.depot=values.depots.find(depot => depot.address==this.order.address);
        this.loading=false;
      });
  }

  ngOnInit(): void {
  }

  getTransactions(refund: boolean=false){
    return this.order?.transactions.filter(t => (t.refund==null && !refund) || (t.refund!=null && refund));
  }

  getDiscount(){
    return this.order?.transactions.reduce((acc, current) => acc+=current.amountTTCRemise-current.amountTTC, 0);
  }

  getPays(id: number){
    return this.pays.find(p => p.id==id);
  }

  execMonetico(){
    this.loading=true;
    this.submitMonetico=true;
  }

  execPaypal(){
    this.loading=true;
    this.pServ.execPaypal(this.order.ident).subscribe({
      next: (data: any) => window.location.href=data.url,
      error: e => {
        this.error=e;
        this.loading=false;
      }
    });
  }

  getInvoice(){
    this.loading=true;
    this.orderServ.getInvoice(this.order.ident, this.admin).subscribe(() => this.loading=false);
  }

  canPrint(){
    //vérification du mode de livraison
    let canPrint: boolean=(this.order.ident && this.admin && this.stateInit!='state-complete' && !this.order.dCollect);

    //vérification si le transporteur 
    canPrint=(canPrint && (this.order.shipObj.shipper=='colissimo-sign' || this.order.shipObj.shipper=='colissimo-relay' || this.order.shipObj.shipper=='colissimo-domicile' || this.order.shipObj.shipper=='mondial-relay'));

    return canPrint;
  }

  isColissimo(){
    return (this.order.shipObj.shipper=='colissimo-sign' || this.order.shipObj.shipper=='colissimo-relay' || this.order.shipObj.shipper=='colissimo-domicile');
  }

  isMR(){
    return (this.order.shipObj.shipper=='mondial-relay');
  }

  doExp(){
    this.loading=true;
    let obs: Observable<Ship>;
    if(this.order.shipObj.shipper=='mondial-relay')
      obs=this.shipServ.doExpMR(this.order.ident);
    else
      obs=this.shipServ.doExpColissimo(this.order.ident);

    obs.subscribe({
      next: ship => {
        this.order.shipObj=ship;
        this.msg="Expédition créée";
        this.loading=false;
      },
      error: e => {
        this.error=e;
        this.loading=false;
      }
    });
  }

  printColissimo(){
    this.loading=true;
    this.shipServ.tagColissimo(this.order.ident).subscribe(() => this.loading=false);
  }

  setOrder(){
    this.loading=true;
    this.orderServ.updateOrder(this.order).subscribe({
      next: order => {
        this.stateInit=order.state;
        this.msg='Modifications enregistrées';
        this.loading=false;
      },
      error: e => {
        this.error=e;
        this.loading=false;
      }
    });
  }

  refund(request: RefundRequest){
    this.loading=true;
    this.orderServ.refund(this.order.ident, request)
      .pipe(
        catchError(e => {
          this.error=e;
          this.loading=false;
          return EMPTY;
        }),
        tap(() => this.msg="Retour créé"),
        switchMap(() => this.orderServ.getOrder(this.order.ident))
      )
      .subscribe(order => {
        this.order=order;
        this.loading=false;
      });
  }
}
