import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'gob-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {
  _error: string;
  _msg: string;

  @Input()
  set error(value: string){
    this._error=value;
    this._msg=null;
  }

  get error():string{
    return this._error;
  }

  @Input()
  set msg(value: string){
    this._msg=value;
    this._error=null;
  }

  get msg():string{
    return this._msg;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
