import { Component, OnInit } from '@angular/core';
import { UserService } from '@services/user.service';
import { NotifyService } from '@services/notify.service';
import { Newsletter } from '@models/user';

@Component({
  selector: 'gob-form-news',
  templateUrl: './form-news.component.html',
  styleUrls: ['./form-news.component.css']
})
export class FormNewsComponent implements OnInit {
  news: Newsletter=new Newsletter();

  constructor(private userServ: UserService, private notify: NotifyService) { }

  ngOnInit(): void {
  }

  subscribe(){
    this.userServ.subscribe(this.news).subscribe({
      next: () => this.notify.set('Newsletter', 'Inscription validée'),
      error: e => this.notify.set('Newsletter', e)
    });
  }

}
