<div class="step-container">
	<!-- panier -->
	<div class="step" [ngClass]="{selected: ind>=0, 'hidden-phone': ind>0}">
		<div class="step-number">
			1
		</div>
		<div class="step-text">
			Panier
		</div>
	</div>

	<!-- Address -->
	<div class="step" [ngClass]="{selected: ind>=1, 'hidden-phone': ind>1}">
		<div class="step-number">
			2
		</div>
		<div class="step-text">
			Adresse
		</div>
	</div>

	<!-- paiement -->
	<div class="step" [ngClass]="{selected: ind>=2, 'hidden-phone': ind<1 || ind>3}">
		<div class="step-number">
			3
		</div>
		<div class="step-text">
			Paiement
		</div>
	</div>

	<!-- Validation -->
	<div class="step" [ngClass]="{selected: ind>=3, 'hidden-phone': ind<2}">
		<div class="step-number">
			4
		</div>
		<div class="step-text">
			Validation
		</div>
	</div>
</div>

