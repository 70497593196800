import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';
import { RouteService } from '@services/route.service';
import { GlobalService } from '@services/global.service';
import { Categ, Filter } from '@models/product';


@Component({
  selector: 'gob-categ-form',
  templateUrl: './categ-form.component.html',
  styleUrls: ['./categ-form.component.css']
})
export class CategFormComponent implements OnInit {
  loading: boolean=false;
  error: string;
  msg: string;
  categ: Categ;
  filters: Filter[]=[];

  constructor(public route: RouteService, private router: ActivatedRoute, private gServ: GlobalService) {
    this.loading=true;
    this.categ=new Categ();
    this.router.paramMap
      .pipe(
        switchMap(params => {
          if(params.get('id')){
            return this.gServ.getCategs()
                      .pipe(
                        map(categs => categs.find(c => c.ident==params.get('id'))),
                        tap((categ) => this.categ=JSON.parse(JSON.stringify(categ)))
                      );
          }
          else
            return of(new Categ());
        }),
        switchMap(() => this.gServ.getFilters()),
        tap(filters => this.filters=filters)
      )
      .subscribe({
        next: () => {
          this.loading=false;
        },
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
  }

  ngOnInit(): void {
  }

  isActive(ident: string){
    return (this.categ.filters.findIndex(filter => filter==ident)!=-1 || this.isDefault(ident));
  }

  isDefault(ident: string){
    return (ident=="filter-price" || ident=="filter-age" || ident=="filter-players");
  }

  setFilter(ident: string, event: any){
    if(event.target.checked)
      this.categ.filters.push(ident);
    else
      this.categ.filters.splice(this.categ.filters.findIndex(filter => filter==ident), 1);
  }

  update(form: any){
    this.loading=true;
    let update: boolean=(this.categ.ident!=null);
    this.gServ.setCateg(this.categ).subscribe({
      next: () => {
        this.msg='Modification enregistrées';
        if(!update)
          form.reset();
        this.loading=false;
      },
      error: e => {
        this.error=e;
        this.loading=false;
      }
    });
  }

  rm(){
    if(confirm("Etes-vous sur de vouloir supprimer ?")){
      this.loading=true;
      this.gServ.rmCateg(this.categ.ident).subscribe({
        next: () => this.route.navigate('class-list'),
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
    }
  }

}
