<article class="block green">
	<header>
		<h2>Restez à l'affut</h2>
	</header>
	<div class="new-form">
		<div class="content">
			Inscrivez-vous pour des nouvelles des offre et des aventures exclusives.<br/>
			Ne manquez rien de l'univers de La Caverne Du Gobelin !
		</div>
		<div class="form-container">
			<div class="input-group mb-3">
			  <div class="form-floating">
			    <input type="email" class="border-gradient-left form-control" id="email-newsletter" placeholder="E-mail" [(ngModel)]="news.email"/>
			    <label for="email-newsletter">E-mail</label>
			  </div>
			  <button (click)="subscribe()" class="input-group-text brun">
				<i class="fa-regular fa-envelope"></i>
			  </button>
			</div>
		</div>
	</div>
</article>
