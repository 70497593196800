<div [loading]="loading">
	<gob-header></gob-header>
	<div class="block form-admin">
		<gob-step-order [ind]="2"></gob-step-order>
		<div class="back-container">
			<gob-back [url]="route.get('cart-info')"></gob-back>
		</div>
		<gob-message [error]="error"></gob-message>
		<h2 class="admin">Paiement</h2>
		<div>
			<ul class="list-group">
			  <li class="list-group-item">
			    <input (change)="setMonetico($event)" class="form-check-input me-1" type="radio" name="paiement" id="paiement-cb" value="cb">
			    <label class="form-check-label" for="paiement-cb">
			    	<i class="fa-regular fa-credit-card"></i> CB
			    </label>
			  </li>
			  <li class="list-group-item">
			    <input (change)="setPaypal($event)" class="form-check-input me-1" type="radio" name="paiement" id="paiement-paypal" value="cb">
			    <label class="form-check-label" for="paiement-paypal">
			    	<i class="fa-brands fa-paypal"></i> Paypal
			    </label>
			  </li>
			</ul>
		</div>
		<div *ngIf="type" class="next">
			<a (click)="next()" class="btn green">Valider</a>
		</div>

		<div *ngIf="submitMonetico" class="monetico-container">
			<gob-monetico [order]="order.ident" [submit]="submitMonetico"></gob-monetico>
		</div>
	</div>
</div>