<div [loading]="loading">
	<gob-back [url]="route.get('group-list')"></gob-back>
	<div class="form-content">
		<form class="form-admin-left" #form="ngForm">
			<gob-message [error]="error" [msg]="msg"></gob-message>
			<!-- nom du code -->
			<div class="form-floating mb-3">
			  <input type="text" class="form-control" id="group-name" placeholder="Nom du groupe" name="name" [(ngModel)]="group.name" required />
			  <label for="group-name">Nom du groupe</label>
			</div>

			<button scrollTo (click)="update(form)" class="btn green" [disabled]="form.invalid">{{((group.ident)? "Modifier" : "Créer")}}</button>
			<button *ngIf="group.ident" (click)="rm()" class="btn btn-danger">Supprimer</button>
		</form>
	</div>
</div>