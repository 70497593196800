<div [loading]="loading">
  <gob-search></gob-search>
  <header>
    <gob-menu (loading)="loading=$event"></gob-menu>
  </header>
  <router-outlet></router-outlet>
  <footer>
    <gob-footer></gob-footer>
  </footer>
  <gob-notification></gob-notification>
</div>