import { Component, OnInit } from '@angular/core';
import { RouteService } from '@services/route.service';
import { UserService } from '@services/user.service';
import { Group } from '@models/user';

@Component({
  selector: 'gob-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.css']
})
export class GroupListComponent implements OnInit {
  loading: boolean=false;
  groups: Group[]=[];

  constructor(public route: RouteService, private userServ: UserService) { 
    this.loading=true;
    this.userServ.getGroups().subscribe(groups => {
      this.groups=groups;
      this.loading=false;
    });
  }

  ngOnInit(): void {
  }

  rm(ident: string){
    if(confirm('Êtes-vous sûr de vouloir supprimer ?')){
      this.loading=true;
      this.userServ.rmGroup(ident).subscribe(() => this.loading=false);
    }
  }
}
