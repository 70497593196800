import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Order, Transaction, RefundRequest } from '@models/order';

@Component({
  selector: 'gob-refund-form',
  templateUrl: './refund-form.component.html',
  styleUrls: ['./refund-form.component.css']
})
export class RefundFormComponent implements OnInit {
  @Output() readonly back: EventEmitter<boolean>=new EventEmitter<boolean>();
  @Output() readonly refund: EventEmitter<RefundRequest>=new EventEmitter<RefundRequest>();
  @Input() order: Order;
  request: RefundRequest;

  constructor() { }

  ngOnInit(): void {
    this.initRequest();
  }

  initRequest(){
    this.request=new RefundRequest();
    this.request.transactions=[];
    this.order.transactions.forEach(t => {
      if(!t.refund)
        this.request.transactions.push({transaction: t.ident, qte: 0});
    });
  }

  getTransaction(ident: string){
    return this.order.transactions.filter(t => t.ident==ident)[0];
  }

  getTransactions(){
    //récup des transactions
    let ts: Transaction[]=this.order.transactions.filter(t => t.refund==null);
    ts=JSON.parse(JSON.stringify(ts));

    //diminution des qte
    ts.forEach(t => {
      let refunded: number=this.order.transactions.reduce((acc, val) => {
        if(val.refund)
          acc+=val.qte;
        return acc;
      }, 0);
      t.qte-=refunded;
    });

    return ts;
  }

  doRefund(form: any){
    this.refund.emit(this.request);
    form.reset();
    this.initRequest();
  }

}
