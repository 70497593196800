<div [ngClass]="{open: open}" id="menu-contrainer">
	<div class="display-phone drop-menu">
		<img class="logo" src="assets/img/logo/logo.png" alt="Caverne du Gobelin Logo"/>
		<i (click)="open=!open" class="burger fa-solid fa-bars"></i>
	</div>
	<div class="nav-container">
		<img class="logo hidden-phone" src="assets/img/logo/logo.png" alt="Caverne du Gobelin Logo"/>
		<nav *ngIf="!isAdmin" class="navbar navbar-expand-lg">
			<div class="collapse navbar-collapse show">
				<ul class="navbar-nav me-auto mb-2 mb-lg-0">

					<!-- jeux de société -->
					<li *ngFor="let n1 of menu" class="nav-item" [ngClass]="{dropdown: n1.children.length}">
			          <a *ngIf="!n1.children.length" (click)="open=!open" [routerLink]="link(n1)" class="nav-link">
			            {{n1.name}}
			          </a>
			          <a *ngIf="n1.children.length" class="nav-link" [ngClass]="{'dropdown-toggle': n1.children.length}" data-bs-toggle="dropdown">
			            {{n1.name}}
			          </a>
			          <ul *ngIf="n1.children.length" class="sub-menu-n2 dropdown-menu">
			            <li *ngFor="let n2 of n1.children" class="item-n2">
			            	<a (click)="open=!open" [routerLink]="link(n2)" class="title-n2 dropdown-item">{{n2.name}}</a>
			            	<ul *ngIf="n2.children.length" class="n3-container">
			            		<li *ngFor="let n3 of n2.children" class="item-n3 dropdown-item">
			            			<a (click)="open=!open" [routerLink]="link(n3)">{{n3.name}}</a>
			            		</li>
			            	</ul>
			            </li>
			          </ul>
			        </li>

			        <!-- nos animations -->
			        <li class="nav-item">
			          <a class="nav-link" >Nos animations</a>
			        </li>

			        <!-- nos cafés jeux -->
			        <li class="nav-item">
			          <a href="#" (click)="open=!open" [routerLink]="route.get('bar')" class="nav-link">Café jeux</a>
			        </li>
			    </ul>
			</div>
		</nav>
		<nav *ngIf="isAdmin" class="navbar navbar-expand-lg">
			<div class="collapse navbar-collapse show">
				<ul class="navbar-nav me-auto mb-2 mb-lg-0">
			        <!-- Commandes clients -->
					<li *ngIf="userServ.hasRight('orders') || userServ.hasRight('dashboard')" class="nav-item dropdown">
			          <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
			            Commandes
			          </a>
			          <ul class="dropdown-menu">
			            <li><a (click)="open=!open" [routerLink]="route.get('my-order')" class="dropdown-item"><i class="fa-light fa-box"></i>Mes commandes</a></li>
			            <li><hr class="dropdown-divider"></li>
			            <li><a (click)="open=!open" *ngIf="userServ.hasRight('orders')" [routerLink]="route.get('order-list')" [queryParams]="{admin: true}" class="dropdown-item"><i class="fad fa-file-invoice-dollar"></i>Commandes client</a></li>
			            <li><a (click)="open=!open" *ngIf="userServ.hasRight('dashboard')" [routerLink]="route.get('dashboard')" class="dropdown-item"><i class="fa-light fa-chart-line-up"></i>Dashboard</a></li>
			          </ul>
			        </li>

			        <!-- Gestion des produits -->
					<li *ngIf="userServ.hasRight('products')" class="nav-item dropdown">
			          <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
			            Produits
			          </a>
			          <ul class="dropdown-menu">
			            <li><a (click)="open=!open" [routerLink]="route.get('product-list')" class="dropdown-item"><i class="fa-light fa-game-console-handheld"></i>Produits</a></li>
			            <li><a (click)="open=!open" [routerLink]="route.get('review-list')" class="dropdown-item"><i class="fa-light fa-star-sharp-half-stroke"></i>Avis</a></li>
			            <li><a (click)="open=!open" [routerLink]="route.get('class-list')" class="dropdown-item" href="#"><i class="fa-light fa-diagram-project"></i>Classification</a></li>
			          </ul>
			        </li>

			        <!-- Gestion des stocks -->
					<li *ngIf="userServ.hasRight('products')" class="nav-item dropdown">
			          <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
			            Stocks
			          </a>
			          <ul class="dropdown-menu">
			            <li><a (click)="open=!open" [routerLink]="route.get('depot-list')" class="dropdown-item" href="#"><i class="fa-light fa-warehouse"></i>Dépôts</a></li>
			          </ul>
			        </li>
			        
					<!-- Utilisateurs -->
					<li *ngIf="userServ.hasRight('users')" class="nav-item dropdown">
			          <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
			            Utilisateurs
			          </a>
			          <ul class="dropdown-menu">
			            <li><a class="dropdown-item" (click)="open=!open" [routerLink]="route.get('user-list')"><i class="fa-light fa-user"></i>Utilisateurs</a></li>
			            <li><a class="dropdown-item" (click)="open=!open" [routerLink]="route.get('roles-list')"><i class="fa-light fa-handshake"></i>Rôles</a> </li>
			            <li><a class="dropdown-item" (click)="open=!open" [routerLink]="route.get('group-list')"><i class="fa-light fa-user-group"></i>Groupes client</a> </li>
			            <li><a class="dropdown-item" (click)="open=!open" [routerLink]="route.get('newsletters')"><i class="fa-light fa-envelopes-bulk"></i>Newletter</a></li>
			          </ul>
			        </li>

			        <!-- Paramètres du site -->
					<li *ngIf="userServ.hasRight('discounts') || userServ.hasRight('shippings') || userServ.hasRight('settings')" class="nav-item dropdown">
			          <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
			            Paramètres
			          </a>
			          <ul class="dropdown-menu">
			            <li><a *ngIf="userServ.hasRight('discounts')" (click)="open=!open" [routerLink]="route.get('promo-list')" class="dropdown-item"><i class="fa-light fa-gift"></i>Codes promo</a></li>
			            <li><a (click)="open=!open" [routerLink]="route.get('shipper-list')" *ngIf="userServ.hasRight('shippings')" class="dropdown-item"><i class="fa-sharp fa-light fa-rocket-launch"></i>Livraisons</a></li>
			            <li><a (click)="open=!open" [routerLink]="route.get('settings')" *ngIf="userServ.hasRight('settings')" class="dropdown-item"><i class="fa-light fa-display-code"></i>Affichage</a></li>
			            <li><a (click)="open=!open" [routerLink]="route.get('menu')" *ngIf="userServ.hasRight('settings')" class="dropdown-item"><i class="fa-light fa-square-list"></i>Menu</a></li>
			            <li><a (click)="open=!open" [routerLink]="route.get('order-state-list')" *ngIf="userServ.hasRight('settings')" class="dropdown-item"><i class="fa-sharp fa-light fa-satellite-dish"></i>Etat des commandes</a></li>
			          </ul>
			        </li>
			        
			        <!-- Mes Commandes -->
			        <li *ngIf="!userServ.hasRight('orders')" class="nav-item">
			          <a (click)="open=!open" [routerLink]="route.get('my-order')" class="nav-link">Mes commandes</a>
			        </li>

			        <!-- Mon compte -->
			        <li class="nav-item">
			          <a (click)="open=!open" [routerLink]="route.get('my-account')" class="nav-link">Mon compte</a>
			        </li>

			        <!-- Déconnexion -->
			        <li class="nav-item">
			          <a (click)="unlog()" class="nav-link">Déconnexion</a>
			        </li>
			    </ul>
			</div>
		</nav>
	</div>
	<aside>
		<div class="user-container">
			<a href="" (click)="open=!open" [routerLink]="route.get('admin-home')" class="accompte" title="Mon compte">
				Mon compte 
				<i class="fa-light fa-user"></i>
			</a>
			<a (click)="open=!open" [routerLink]="route.get('cart')" title="Panier">
				Panier
				<span class="counter-cart">{{cart.count()}}</span>
			</a>
		</div>
		<a (click)="search.search(true)" title="Recherchez" class="search-container">
			Recherche
			<i class="fa-light fa-magnifying-glass"></i>
		</a>
	</aside>
</div>
