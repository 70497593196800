import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimerComponent } from './timer/timer.component';
import { PaginationComponent } from './pagination/pagination.component';
import { StarsComponent } from './stars/stars.component';
import { YoutubeComponent } from './youtube/youtube.component';
import { BackComponent } from './back/back.component';
import { LoadingDirective } from './directives/loading.directive';
import { ScrollDirective } from './directives/scroll.directive';
import { MessageComponent } from './message/message.component';
import { TinymceComponent } from './tinymce/tinymce.component';
import { BannerComponent } from './banner/banner.component';
import { DiapoComponent } from './diapo/diapo.component';
import { RangeComponent } from './range/range.component';
import { NotificationComponent } from './notification/notification.component';
import { MoneticoComponent } from './monetico/monetico.component';

@NgModule({
  declarations: [
    TimerComponent,
    PaginationComponent,
    StarsComponent,
    YoutubeComponent,
    BackComponent,
    LoadingDirective,
    MessageComponent,
    TinymceComponent,
    ScrollDirective,
    BannerComponent,
    DiapoComponent,
    RangeComponent,
    NotificationComponent,
    MoneticoComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    TimerComponent,
    PaginationComponent,
    StarsComponent,
    YoutubeComponent,
    BackComponent,
    LoadingDirective,
    ScrollDirective,
    MessageComponent,
    TinymceComponent,
    BannerComponent,
    DiapoComponent,
    RangeComponent,
    NotificationComponent,
    MoneticoComponent
  ]
})
export class UiModule { }
