import { Component, OnInit } from '@angular/core';
import { RouteService } from '@services/route.service';
import { CartService } from '@services/cart.service';
import { OrderService, PaymentService } from '@services/order.service';
import { Order } from '@models/order';
import { Product } from '@models/product';

@Component({
  selector: 'gob-paiement',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  loading: boolean=false;
  error: string;
  order: Order;
  type: string;
  submitMonetico: boolean=false;

  constructor(
    private cart: CartService,
    private orderServ: OrderService,
    private pServ: PaymentService,
    public route: RouteService
  ) {
    this.order=this.cart.getOrder();
  }

  ngOnInit(): void {
  }

  setMonetico(input: any){
    if(input.target.checked)
      this.type='monetico';
  }

  setPaypal(input: any){
    if(input.target.checked)
      this.type='paypal';
  }

  next(){
    this.loading=true;
    this.orderServ.setOrder(this.order)
      .subscribe({
        next: order => {
          this.order.ident=order.ident;
          this.cart.setOrder(this.order);
          this.execPayment();
        },
        error: e => this.setError(e)
      });
  }

  execPayment(){
    switch(this.type){
      case 'paypal':
        this.execPaypal();      
        break;
      case 'monetico':
        this.execMonetico();      
        break;
      default:
        this.error='Choisissez un mode de paiement'; 
        break;
    }
  }

  execPaypal(){
    this.loading=true;
    this.pServ.execPaypal(this.order.ident).subscribe({
      next: (data: any) => window.location.href=data.url,
      error: e => {
        this.error=e;
        this.loading=false;
      }
    });
  }

  execMonetico(){
    this.loading=true;
    this.submitMonetico=true;
  }

  setError(e: any){
      if(!e.data)
        this.error=e;
      else{
        let product: Product=this.cart.getLines().find(line => line.product.ident==e.data.product)?.product;
        if(product)
          this.error=`${product.name} : ${e.data.msg}`;
        else
          this.error=e.data.msg;
      }
      this.loading=false;
  }

}
