<div [loading]="loading">
	<gob-back (back)="login.emit(true)"></gob-back>	
	<h1 class="force-title">Créez votre compte</h1>
	<form (ngSubmit)="signup(userForm.value)" class="form-log" #userForm="ngForm">
		<gob-message [error]="error"></gob-message>

		<!-- nom -->
		<div class="form-floating mb-3">
		  <input type="text" class="form-control" id="log-name" placeholder="Nom" name="lastname" ngModel required />
		  <label for="log-name">Nom</label>
		</div>

		<!-- prénom -->
		<div class="form-floating mb-3">
		  <input type="text" class="form-control" id="log-lastname" placeholder="Prénom" name="name" ngModel required />
		  <label for="log-lastname">Prénom</label>
		</div>

		<!-- date de naissance -->
		<div class="form-floating mb-3">
		  <input type="date" class="form-control" id="log-birth" placeholder="Date de naissance" name="birth" ngModel required />
		  <label for="log-birth">Date de naissance</label>
		</div>

		<!-- email -->
		<div class="form-floating mb-3">
		  <input type="email" class="form-control" id="log-mail" placeholder="E-mail" name="email" ngModel required email />
		  <label for="log-mail">E-mail</label>
		</div>

		<!-- pass -->
		<div class="form-floating mb-3">
		  <input type="password" class="form-control" id="log-pass" placeholder="Mot de passe" name="pass" ngModel required />
		  <label for="log-pass">Mot de passe</label>
		</div>

		<!-- tel -->
		<div class="form-floating mb-3">
		  <input type="tel" class="form-control" id="log-tel" placeholder="Téléphone" name="phone" ngModel required />
		  <label for="log-tel">Téléphone</label>
		</div>
		<re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" required [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
		<button scrollTo type="submit" class="btn green" [disabled]="userForm.invalid">Valider</button>
	</form>
</div>