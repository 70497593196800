<div class="block form-admin">
	<header class="header-admin">
		<h1 class="admin">Utilisateurs</h1>
	</header>
	<div class="list-container" [loading]="loading">
	  <gob-user-filter (search)="setUsers($event)"></gob-user-filter>
  	  <div class="table-responsive">
		  <table class="table">
		    <thead>
		      <tr>
		        <th scope="col">Nom</th>
		        <th scope="col">Prénom</th>
		        <th scope="col">E-mail</th>
		        <th scope="col">Groupe client</th>
		        <th scope="col">Rôle</th>
	        	<th scope="col"><i class="green fa-solid fa-pen-to-square"></i></th>
		      </tr>
		    </thead>
		    <tbody class="table-group-divider">
		      <tr *ngFor="let user of users">
		        <td>{{user.lastname}}</td>
		        <td>{{user.lastname}}</td>
		        <td>{{user.email}}</td>
		        <td>{{getGroup(user.groupe)?.name}}</td>
		        <td>{{getRoles(user.roles)}}</td>
		        <td>
		        	<a href="" [routerLink]="route.parse('user-set', [user.ident])"><i class="green fa-solid fa-pen-to-square"></i></a>
		        </td>
		      </tr>
		    </tbody>
		  </table>
		</div>
      <gob-pagination (change)="list($event)" [max]="maxPage"></gob-pagination>
	</div>
</div>