import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserService } from '@services/user.service';
import { Address, Pays } from '@models/user';

@Component({
  selector: 'gob-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.css']
})
export class AddressFormComponent implements OnInit {
  loading: boolean=false;
  error: string;
  @Output() readonly add: EventEmitter<Address>=new EventEmitter<Address>();
  pays: Pays[]=[];
  address: Address=new Address();

  constructor(private userServ: UserService) {
    this.loading=true;
    this.userServ.getPays().subscribe(pays => {
      this.pays=pays;
      this.loading=false;
    });
  }

  ngOnInit(): void {
  }

  setAddress(form: any){
    this.loading=true;
    this.userServ.addAddress(this.address).subscribe({
      next: address => {
        this.add.emit(address);
        form.reset();
        this.address=new Address();
        this.loading=false;
      },
      error: e => {
        this.error=e;
        this.loading=false;
      }
    });
  }

}
