import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { of, EMPTY } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { FormatPricePipe } from '@pipes/price.pipe';
import { CartService, LineCart } from '@services/cart.service';
import { RouteService } from '@services/route.service';
import { OrderService } from '@services/order.service';
import { Product } from '@models/product';
import { Order, Discount } from '@models/order';
import { environment } from '@environments/environment';

@Component({
  selector: 'gob-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  loading: boolean=false;
  error: string;
  discount: Discount;
  discountControl: FormControl;

  constructor(
    public cart: CartService, 
    public route: RouteService,
    private fb: FormBuilder,
    private orderServ: OrderService
  ) {
    this.discountControl=this.fb.control('');
    this.discountControl.valueChanges
      .pipe(
        filter(query => query.length>=3),
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => this.loading=true),
        switchMap(value => this.orderServ.getDiscountCode(value)
          .pipe(
            catchError(() => {
              this.error="Code non valide";
              this.discount=null;
              this.loading=false;
              return EMPTY;
            })
          ))
      )
      .subscribe({
        next: discount => {
          if(discount){
            this.discount=discount;
            this.error=null;
            this.loading=false; 
          }
        }
      });
  }

  ngOnInit(): void {
  }

  getImage(product: Product){
    return `${environment.imgUrl}/product/${product.images[0].name}`;
  }

  getTotal(){
    let sum: number=this.cart.getLines().reduce((acc, current) => acc+=current.qte*current.price.prix, 0);
    let formatPrice: FormatPricePipe=new FormatPricePipe();
    return formatPrice.transform(sum-this.getDiscount());
  }

  getDiscount(){
    let discount: number=0;
    if(this.discount){
      let sum: number=this.cart.getLines().reduce((acc, current) => {
        if(!current.price.isRemise)
          return acc+=current.qte*current.price.prix;
        else
          return acc;
      }, 0);

      if(this.discount.type=='%')
        discount=sum*this.discount.amount/100;
      else{
        discount=this.discount.amount-this.discount.amountUsed;
        if(discount>sum)
          discount=sum;
      }
    }
    return discount;
  }

  next(){
    this.cart.setOrder();
    let order: Order=this.cart.getOrder();
    if(this.discount)
      order.discount=this.discount.codeNumber;
    this.cart.setOrder(order);
    this.route.navigate('cart-shipper');
  }
}
