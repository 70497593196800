<div class="block form-admin">
	<header class="header-admin">
		<h1 class="admin">Newsletter</h1>
		<div>
			<a (click)="export()"><i class="fa-solid fa-cloud-arrow-down"></i> Exporter</a>
		</div>
	</header>
	<div class="list-container" [loading]="loading">
	  <div class="content-form">
	  	<table class="table">
		    <thead>
		      <tr>
		        <th scope="col">E-mail</th>
		        <th scope="col">Actif</th>
		      </tr>
		    </thead>
		    <tbody class="table-group-divider">
		      <tr *ngFor="let news of newsletters; index as i">
		        <td>{{news.email}}</td>
		        <td>
					<div class="form-check form-switch mb-3">
					  <input (change)="setNewsletter(news)" class="form-check-input" type="checkbox" id="news-{{i}}" name="news{{i}}" [(ngModel)]="news.actif"/>
					  <label class="form-check-label" for="news-{{i}}"></label>
					</div>
		        </td>
		      </tr>
		    </tbody>
		  </table>
		  <gob-pagination (change)="list($event)" [max]="max"></gob-pagination>
	  </div>
  	</div>
</div>