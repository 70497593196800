import { Component, OnInit } from '@angular/core';
import { RouteService } from '@services/route.service';
import { UserService } from '@services/user.service';
import { User } from '@models/user';

@Component({
  selector: 'gob-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  user: User;

  constructor(public userServ: UserService, public route: RouteService) {
    this.user=this.userServ.user;
  }

  ngOnInit(): void {
  }

}
