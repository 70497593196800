<div class="rating-container">
	<div class="rating-user">
		<div class="stars-container">
			<gob-stars [stars]="rating.rate"></gob-stars>
		</div>
		<div class="title-content">{{rating.date | date:'dd/MM/yyyy'}}</div>
		<div class="title-content">{{rating.customer}}</div>
	</div>
	<div class="rating-review">
		{{rating.review}}
	</div>
</div>
