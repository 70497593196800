<div [loading]="loading">
	<gob-back [url]="route.get('shipper-list')"></gob-back>
	<div class="form-content">
		<form class="form-admin-left" #form="ngForm">
			<gob-message [error]="error" [msg]="msg"></gob-message>
			<!-- nom du code -->
			<div class="form-floating mb-3">
			  <input type="text" class="form-control" id="shipper-name" placeholder="Nom mode de livraison" name="name" [(ngModel)]="shipper.name" required />
			  <label for="shipper-name">Nom mode de livraison</label>
			</div>

			<!-- reusable -->
			<div class="form-check form-switch mb-3">
			  <input class="form-check-input" type="checkbox" id="shipper-disabled" name="disabled" [(ngModel)]="shipper.disabled"/>
			  <label class="form-check-label" for="shipper-disabled">Mode de livraison désactivé</label>
			</div>

			<div class="shippings-container">
				<!-- shipping list -->
				<div class="accordion" id="shipping-list">
				  <div *ngFor="let shipping of shipper.shippings; index as i;" class="accordion-item">
				    <h2 class="accordion-header">
				      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#shipping-'+i" aria-expanded="false" [attr.aria-controls]="'shipping-'+i">
				        De {{shipping.weightMin}}kg à {{((shipping.weightMax)? shipping.weightMax+'kg' : 'illimité')}} - {{shipping.amount}}€
				      </button>
				    </h2>
				    <div id="{{'shipping-'+i}}" class="accordion-collapse collapse" data-bs-parent="#shipping-list">
				      <div class="accordion-body">

						<!-- Poids min -->
						<div class="form-floating mb-3">
						  <input type="text" class="form-control" id="shipping-min-{{i}}" placeholder="0,5" name="shipmin-{{i}}" [(ngModel)]="shipping.weightMin" required />
						  <label for="shipping-min-{{i}}">Poids minimum (kg)</label>
						</div>

						<!-- Poids max -->
						<div class="form-floating mb-3">
						  <input type="text" class="form-control" id="shipping-max-{{i}}" placeholder="2,5" name="shipmax-{{i}}" [(ngModel)]="shipping.weightMax" required />
						  <label for="shipping-max-{{i}}">Poids max (kg)</label>
						</div>

						<!-- Poids montant -->
						<div class="form-floating mb-3">
						  <input type="text" class="form-control" id="shipping-amount-{{i}}" placeholder="7,8" name="shipamount-{{i}}" [(ngModel)]="shipping.amount" required />
						  <label for="shipping-amount-{{i}}">Montant</label>
						</div>

						<!-- Poids tva -->
						<div class="form-floating mb-3">
						  <input type="text" class="form-control" id="shipping-tva-{{i}}" placeholder="20" name="shiptva-{{i}}" [(ngModel)]="shipping.tva" required />
						  <label for="shipping-tva-{{i}}">TVA</label>
						</div>

				       	<a (click)="shipper.shippings.splice(i, 1)" class="btn btn-danger">Supprimer</a>
				      </div>
				    </div>
				  </div>
				</div>
			</div>

			<button scrollTo (click)="update(form)" class="btn green" [disabled]="form.invalid">{{((shipper.ident)? "Modifier" : "Créer")}}</button>
			<button (click)="rm(shipper.ident)" *ngIf="shipper.ident && shipper.editable" class="btn btn-danger">Supprimer</button>
		</form>
		<div class="form-admin-right">
			<!-- Ajout tranche -->
			<div class="card">
			  <div class="card-header">
			    <i class="fa-regular fa-envelopes-bulk"></i> Paliers
			  </div>
			  <div class="card-body">
				<div class="title-content">
					Ajouter un palier
				</div>
				<gob-shipping-form (add)="shipper.shippings.push($event)"></gob-shipping-form>
			  </div>
			</div>
		</div>
	</div>
</div>