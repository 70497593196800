<gob-header></gob-header>
<article class="block explain-1">
	<div class="text">
		<header>
			<h1>Détente, jeux et gourmandise</h1>
		</header>
		<div class="subtitle">
			<div>
				<h2>Bien venu dans l'univers des cafés jeux !</h2>
			</div>
		</div>
		<div class="content">
			Bienvenue dans nos espace café jeux, ou le plaisir de jouer se marie parfaitement avec de délicieuses collations.
			Découvrez une endroit où se retrouver entre amis, en famille ou avec d'autres passionnés de jeux pour des moments innoubliables.<br/><br/>
			Notre café jeux offre bien plus que des boissons et des en-cas. C'est un lieu où vous pouvez explorer de nouveaux jeux, défier vos amis
			et vous plonger dans des univers fantastiques, le tout dans une ambiance chaleureuse et accueillante.
		</div>
	</div>
	<div class="img">
		<img src="/assets/img/skin/trophy.jpg" alt="Café jeux"/>
	</div>
</article>
<article class="block green explain-2">
	<header>
		<h2>Ce que nous proposons</h2>
	</header>
	<div class="text">
		<div class="content">
			<div>
				<h3>Découverte de jeux</h3>
				<div class="detail">
					Explorez notre vaste collection de jeux de société, jeux de rôle et de jeux de cartes.
					Des classiques intemporels aux dernières nouveautés, il y en a pour tous les goûts et tous les niveaux.
				</div>
			</div>
		</div>
		<div class="content">
			<div>
				<h3>Espace de jeu convivial</h3>
				<div class="detail">
					Que vous soyez un joueur occasionnel ou un strtège chevronné, nos espaces sont conçus pour accueillir 
					toutes les aventures ludiques.
				</div>
			</div>
		</div>
		<div class="content">
			<div>
				<h3>Collations et boissons</h3>
				<div class="detail">
					Dégustez une seleciton de boissons chaudes et froides, ainsi que des collations délicieuses pour recharger vos energies entre les parties.
				</div>
			</div>
		</div>
	</div>
</article>
<article class="block explain-3">
	<header>
		<h2>Nos gobelins</h2>
	</header>
	<div class="team-container">
		<div class="team">
			<img src="/assets/img/teams/t1.jpg" title="Thibault"/>
			<h3>Thibault</h3>
			<div class="text">
				Founder, senior instructor
			</div>
		</div>
		<div class="team">
			<img src="/assets/img/teams/t2.jpg" title="Elvire"/>
			<h3>Elvire</h3>
			<div class="text">
				Dance instructor, Latin
			</div>
		</div>
		<div class="team">
			<img src="/assets/img/teams/t3.jpg" title="Zinzin 1"/>
			<h3>Zinzin 1</h3>
			<div class="text">
				 Dance instructor, House
			</div>
		</div>
		<div class="team">
			<img src="/assets/img/teams/t4.jpg" title="Zinzin 2"/>
			<h3>Zinzin 2</h3>
			<div class="text">
				 Dance instructor, Hip Hop
			</div>
		</div>
		<div class="team">
			<img src="/assets/img/teams/t5.jpg" title="Zinzin 3"/>
			<h3>Zinzin 3</h3>
			<div class="text">
				 Dance instructor, Urban
			</div>
		</div>
		<div class="team">
			<img src="/assets/img/teams/t6.jpg" title="Zinzin 4"/>
			<h3>Zinzin 4</h3>
			<div class="text">
				 Dance instructor, Breakdance
			</div>
		</div>
	</div>
</article>
