import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { forkJoin } from 'rxjs';
import { Address, Pays } from '@models/user';
import { RouteService } from '@services/route.service';
import { UserService } from '@services/user.service';

@Component({
  selector: 'gob-my-addresses',
  templateUrl: './my-addresses.component.html',
  styleUrls: ['./my-addresses.component.css']
})
export class MyAddressesComponent implements OnInit {
  addresses: Address[]=[];
  pays: Pays[]=[];
  error: string;
  msg: string;
  loading: boolean=false;
  admin: string;


  constructor(private userServ: UserService, public route: RouteService, private router: ActivatedRoute) {
    this.router.paramMap.subscribe(params => {
    this.loading=true;
      this.admin=params.get('user');
      forkJoin({
        addresses: this.userServ.getUserAddress(params.get('user')),
        pays: this.userServ.getPays()
      }).subscribe(values => {
        this.addresses=values.addresses;
        this.pays=values.pays;
        this.loading=false;
      });
    });
  }

  ngOnInit(): void {
  }

  getPays(id: number){
    return this.pays.find(p => p.id==id);
  }

  rm(ident: string){
    if(confirm('Êtes-vous sûr de vouloir supprimer ?')){
      this.loading=true;
      this.userServ.rmAddress(ident).subscribe({
        next: () => {
          this.msg='Adresse supprimée';
          this.remove(ident);
          this.loading=false;
        },
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
    }
  }

  remove(ident: string){
    this.addresses.splice(this.addresses.findIndex(address => address.ident==ident), 1);
  }
}
