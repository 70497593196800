import { Component, OnInit } from '@angular/core';
import { GlobalService } from '@services/global.service';
import { RouteService }  from '@services/route.service';
import { Categ, SCateg, Gamme, Marque, Tag, Banner } from '@models/product';

@Component({
  selector: 'gob-class-list',
  templateUrl: './class-list.component.html',
  styleUrls: ['./class-list.component.css']
})
export class ClassListComponent implements OnInit {
  loading: boolean=false;
  error: string;
  msg: string;
  categs: Categ[]=[];
  scategs: SCateg[]=[];
  gammes: Gamme[]=[];
  marques: Marque[]=[];
  tags: Tag[]=[];
  banners: Banner[]=[];

  constructor(public route: RouteService, private gServ: GlobalService) { 
    this.loading=true;
    this.gServ.getClassification().subscribe(elements => {
      this.categs=elements.categs;
      this.scategs=elements.scategs;
      this.gammes=elements.gammes;
      this.marques=elements.marques;
      this.tags=elements.tags;
      this.banners=elements.banners;
      this.loading=false;
    });
  }

  ngOnInit(): void {
  }

  rmCateg(ident: string){
    if(confirm("Etes-vous sur de vouloir supprimer ?")){
      this.loading=true;
      this.gServ.rmCateg(ident).subscribe({
        next: () => this.loading=false,
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
    }
  }

  rmSCateg(ident: string){
    if(confirm("Etes-vous sur de vouloir supprimer ?")){
      this.loading=true;
      this.gServ.rmSCateg(ident).subscribe({
        next: () => this.loading=false,
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
    }
  }

  rmGamme(ident: string){
    if(confirm("Etes-vous sur de vouloir supprimer ?")){
      this.loading=true;
      this.gServ.rmGamme(ident).subscribe({
        next: () => this.loading=false,
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
    }
  }

  rmMarque(ident: string){
    if(confirm("Etes-vous sur de vouloir supprimer ?")){
      this.loading=true;
      this.gServ.rmMarque(ident).subscribe({
        next: () => this.loading=false,
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
    }
  }

  rmTag(ident: string){
    if(confirm("Etes-vous sur de vouloir supprimer ?")){
      this.loading=true;
      this.gServ.rmTag(ident).subscribe({
        next: () => this.loading=false,
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
    }
  }

  rmBanner(ident: string){
    if(confirm("Etes-vous sur de vouloir supprimer ?")){
      this.loading=true;
      this.gServ.rmBanner(ident).subscribe({
        next: () => this.loading=false,
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
    }
  }

}
