import { Day } from '@models/global';

export class Depot{
	ident: string;
	identChr: string;
	name: string;
	address: string;
	isMain: boolean=false;
	schedules: Schedule[]=[];
}

export class Schedule{
	ident: string;
	depot: string;
	day: Day=null;
	startH: string;
	endH: string;
}

export interface ScheduleDate{
	date: Date;
	start: string;
	end: string;
}

export class Stock{
	ident: string;
	depot: string;
	product: string;
	qte: number=0;
}