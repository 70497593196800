<div class="list-container" [loading]="loading">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Nom</th>
        <th scope="col">E-mail</th>
        <th scope="col">Modifiable</th>
        <th scope="col">Editer</th>
        <th scope="col">Supprimer</th>
      </tr>
    </thead>
    <tbody class="table-group-divider">
      <tr *ngFor="let state of states">
        <td>{{state.name}}</td>
        <td>{{((state.mail)? 'oui' : 'non')}}</td>
        <td>{{((state.editable)? 'oui' : 'non')}}</td>
        <td>
        	<a href="" [routerLink]="route.parse('order-state-set', [state.ident])"><i class="green fa-solid fa-pen-to-square"></i></a>
        </td>
        <td>
        	<a *ngIf="state.editable" (click)="rm(state.ident)"><i class="red fa-solid fa-trash"></i></a>
        </td>
      </tr>
    </tbody>
  </table>
</div>