import { Component, OnInit } from '@angular/core';
import { Role } from '@models/user';
import { UserService } from '@services/user.service';
import { RouteService } from '@services/route.service';

@Component({
  selector: 'gob-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.css']
})
export class RoleListComponent implements OnInit {
  loading: boolean=false;
  error: string;
  roles: Role[]=[];

  constructor(private userServ: UserService, public route: RouteService) {
    this.loading=true;
    this.userServ.getRoles().subscribe(roles => {
        this.roles=roles;
        this.loading=false;
      });
  }

  ngOnInit(): void {
  }

  rm(ident: string){
    if(confirm('Êtes-vous sûr de vouloir supprimer ?')){
      this.loading=true;
      this.userServ.rmRole(ident).subscribe(() => this.loading=false);
    }
  }

}
