<div [loading]="loading" class="form-container">
  <!-- lowstock -->
  <div class="form-floating mb-3">
    <input type="number" class="form-control" id="product-lowstock" placeholder="Seuil stock faible" name="lowstock" [(ngModel)]="product.lowStock" />
    <label for="product-lowstock">Seuil stock faible</label>
  </div>

  <!-- dispoFrns -->
  <div class="form-floating mb-3">
    <input type="number" class="form-control" id="product-dispo" placeholder="Nombre d'heures" name="dispoFrns" [(ngModel)]="product.dispoFrns" required />
    <label for="product-dispo">Disponibilité fournisseur</label>
  </div>

  <!-- stock -->
  <div *ngIf="!product.stocks.length">
    Aucun stock
  </div>
  <div *ngIf="product.stocks.length">
    <div class="title-content green">
      Stocks :
    </div>
    <div class="accordion" id="stock-list">
      <div *ngFor="let stock of product.stocks; index as i;" class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#stock-'+i" aria-expanded="false" [attr.aria-controls]="'stock-'+i">
            Dépôt {{getDepot(stock.depot)?.name}} : {{stock.qte}}
          </button>
        </h2>
        <div id="{{'stock-'+i}}" class="accordion-collapse collapse" data-bs-parent="#stock-list">
          <div class="accordion-body">
            <!-- depot -->
            <div class="form-floating mb-3">
              <select class="form-select" id="depot-list-{{i}}" name="depot{{i}}" [(ngModel)]="stock.depot" required>
                <option [ngValue]="null">Sélectionnez un dépôt</option>
                <option *ngFor="let depot of depots" [ngValue]="depot.ident">{{depot.name}}</option>
              </select>
              <label for="depot-list-{{i}}">Dépôt</label>
            </div>

            <!-- qte -->
            <div class="form-floating mb-3">
              <input type="number" class="form-control" id="stock-qte-{{i}}" placeholder="Qte" name="qte{{i}}" [(ngModel)]="stock.qte" required />
              <label for="stock-qte-{{i}}">Qte</label>
            </div>

            <a *ngIf="!stock.ident" (click)="product.stocks.splice(i, 1)" class="btn btn-danger">Supprimer</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="getDepotList().length" class="add-stock mb-3">
    <a (click)="add()">
      <i class="fa-solid fa-layer-plus"></i> Ajouter un stock
    </a>
  </div>
</div>