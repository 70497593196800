import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gob-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  @Input() max: number=null;
  @Input() text: string="Page";
  @Output() readonly change: EventEmitter<number>=new EventEmitter<number>();

  _activePage: number=0;

  @Input()
  set activePage(value: number){
    this._activePage=value;
    this.change.emit(this.activePage);
  }

  get activePage(): number{
    return this._activePage;
  }

  constructor() { 
    this.activePage=0;
  }

  ngOnInit(): void {
  }

  prev(){
    if(this.activePage>0)
      this.activePage--;
  }

  next(){
    if(this.max===null || this.activePage+1<this.max)
      this.activePage++;
  }

}
