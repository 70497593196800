<div class="timer-container">
	<div class="border-gradient-circle time">
		<div class="time-value">{{getDays()}}</div>
		<div class="time-name">jours</div>
	</div>
	<div class="separator"></div>
	<div class="border-gradient-circle time">
		<div class="time-value">{{getHours()}}</div>
		<div class="time-name">heures</div>
	</div>
	<div class="separator"></div>
	<div class="border-gradient-circle time">
		<div class="time-value">{{getMin()}}</div>
		<div class="time-name">min</div>
	</div>
	<div class="separator"></div>
	<div class="border-gradient-circle time">
		<div class="time-value">{{getSec()}}</div>
		<div class="time-name">sec</div>
	</div>
</div>