<div class="block form-admin" [loading]="loading">
	<header class="header-admin">
		<h1 class="admin">Les commandes</h1>
	</header>

	<!-- Filtres -->
	<gob-order-filter (search)="filter=$event" [admin]="admin"></gob-order-filter>
	
	<!-- list -->
	<div class="list-container">
		<div class="table-responsive">
		  <table class="table">
		    <thead>
		      <tr>
		        <th scope="col">Numéro</th>
		        <th scope="col">Date</th>
		        <th scope="col">Client</th>
		        <th scope="col">Montant (TTC)</th>
		        <th scope="col">Click & Collect</th>
		        <th scope="col">Etat</th>
		        <th scope="col"><i class="green fa-solid fa-pen-to-square"></i></th>
		        <th scope="col"><i class="red fa-solid fa-trash"></i></th>
		      </tr>
		    </thead>
		    <tbody class="table-group-divider">
		      <tr *ngFor="let order of orders">
		        <td>{{order.ident}}</td>
		        <td>{{order.dateCreate | date:'dd/MM/yyyy'}}</td>
		        <td>{{order.customer}}</td>
		        <td>{{order.amountTTC | formatPrice}}€</td>
		        <td>{{getShop(order)}}</td>
		        <td>{{getState(order) | titlecase}}</td>
		        <td>
		        	<a [routerLink]="route.parse('order-set', [order.ident])"><i class="green fa-solid fa-pen-to-square"></i></a>
		        </td>
		        <td>
		        	<a><i class="red fa-solid fa-trash"></i></a>
		        </td>
		      </tr>
		    </tbody>
		  </table>
		</div>
	  <gob-pagination (change)="search($event)" [activePage]="page" [max]="max"></gob-pagination>
	</div>
</div>