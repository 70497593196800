import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gob-bar-page',
  templateUrl: './bar-page.component.html',
  styleUrls: ['./bar-page.component.css']
})
export class BarPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
