import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { UserService } from '@services/user.service';
import { RouteService } from '@services/route.service';
import { GlobalService } from '@services/global.service';
import { SearchService } from '@services/search.service';
import { CartService } from '@services/cart.service';
import { MenuItem } from '@models/global';

@Component({
  selector: 'gob-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  @Output() readonly loading: EventEmitter<boolean>=new EventEmitter<boolean>();
  open: boolean=false;
  isAdmin: boolean=false;
  menu: MenuItem[]=[];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private gServ: GlobalService,
    public route: RouteService,
    public userServ: UserService,
    public search: SearchService,
    public cart: CartService
  ) {
    //gestion menu admin
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let route=activatedRoute.firstChild;
        let child=route;
        while(child){
          if(child.firstChild){
            child=child.firstChild;
            route=child;
          }else
            child=null;
        }
        return route;
      }),
      mergeMap(route => route.data)
    )
    .subscribe(data => {
      if(data.isAdmin)
        this.isAdmin=true;
      else
        this.isAdmin=false;
    });
  }

  ngOnInit(): void {
    //gestion du menu !admin
    this.loading.emit(true);
    this.gServ.getMenu().subscribe(menu => {
      this.menu=menu;
      this.loading.emit(false);
    });
  }

  unlog(){
    this.userServ.unlog()
      .subscribe(() => this.route.navigate('login'));
  }

  link(item: MenuItem){
    let name: string=item.name
      .toLowerCase().normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Supprime les accents
      .trim()
      .replace(/[^a-z0-9]+/g, '-') // Remplace les caractères spéciaux par des tirets
      .replace(/^-+|-+$/g, ''); // Supprime les tirets superflus
    return this.route.parse('products-list', [item.type, item.val, name]);
  }

}
