<div class="block form-admin">
	<header class="header-admin">
		<h1 class="admin">Avis client</h1>
	</header>
	<div [loading]="loading">
		<gob-back [url]="route.get('review-list')"></gob-back>
		<div class="form-content">
			<form class="form-admin-left" #form="ngForm">
				<gob-message [error]="error" [msg]="msg"></gob-message>

				<div class="form-floating mb-3">
					<gob-banner [banner]="getBanner()"></gob-banner>
				</div>

				<!-- date -->
				<div class="form-floating mb-3">
				  <input type="date" class="form-control" id="rating-date" placeholder="Date" name="date" [(ngModel)]="rating.date" disabled />
				  <label for="rating-date">Date</label>
				</div>

				<!-- produit -->
				<div class="form-floating mb-3">
				  <input type="text" class="form-control" id="rating-product" placeholder="Product" name="product" [(ngModel)]="rating.product" disabled />
				  <label for="rating-product">Product</label>
				</div>

				<!-- client -->
				<div class="form-floating mb-3">
				  <input type="text" class="form-control" id="rating-customer" placeholder="Client" name="name" [(ngModel)]="rating.customer" disabled />
				  <label for="rating-customer">Client</label>
				</div>

				<div class="form-floating mb-3">
					<gob-stars [stars]="rating.rate"></gob-stars>
				</div>

				<!-- review -->
				<div class="form-floating mb-3">
				  <textarea class="form-control" placeholder="Avis client" id="review-details" [(ngModel)]="rating.review" disabled required></textarea>
				  <label for="review-details">Avis client</label>
				</div>

				<button scrollTo *ngIf="rating.state=='review'" (click)="valid()" class="btn green">Valider</button>
				<button *ngIf="rating.state!='supprimé'" (click)="rm()" class="btn btn-danger">Supprimer</button>
			</form>
		</div>
	</div>
</div>