import { Component, OnInit } from '@angular/core';
import { RouteService } from '@services/route.service';
import { CartService } from '@services/cart.service';

@Component({
  selector: 'gob-ok-payment',
  templateUrl: './ok-payment.component.html',
  styleUrls: ['./ok-payment.component.css']
})
export class OkPaymentComponent implements OnInit {

  constructor(private cart: CartService, public route: RouteService) {
    this.cart.clear();
  }

  ngOnInit(): void {
  }

}
