<div class="connect-container" *ngIf="!userServ.isConnected()">
	Connectez-vous pour donner votre avis<br/>
	<a [routerLink]="route.get('login')" class="btn green" title="Connexion">Connexion</a>
</div>
<div *ngIf="userServ.isConnected()" [loading]="loading">
	<form class="rating-form" #form="ngForm">
		<gob-message [error]="error" [msg]="msg"></gob-message>
		<div>
			Donnez votre avis.
		</div>
		<div class="stars-container mb-3">
			<div class="title-content">
				Note :
			</div>
			<div *ngFor="let i of [].constructor(5); index as ind;" class="stars">
				<label for="star{{ind}}"><img src="/assets/img/skin/star.png" alt="gobelin"/></label><br/>
				{{ind+1}}
			  	<input class="form-check-input" id="star{{ind}}" type="radio" name="star" value="{{ind+1}}" [(ngModel)]="rating.rate" required />
			</div>
		</div>
		<div class="form-floating mb-3">
		  <textarea class="form-control" placeholder="Votre commentaire" id="review" name="review" style="height: 150px" [(ngModel)]="rating.review" required></textarea>
		  <label for="review">Commentaire</label>
		</div>
		<div class="mb-3">
			<re-captcha name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" required [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
		</div>
		<button scrollTo (click)="rate()" type="button" class="btn green" [disabled]="form.invalid">Valider</button>
	</form>
</div>