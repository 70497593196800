<div class="block form-admin">
	<header class="header-admin">
		<h1 class="admin">Gestion du menu</h1>
	</header>
	<div [loading]="loading">
		<div class="form-content">
			<form class="form-admin-left" #form="ngForm">
				<gob-message [error]="error" [msg]="msg"></gob-message>
				<div class="items-container">
					<gob-menu-item *ngFor="let item of items; index as i" [indChild]="i" [item]="item"></gob-menu-item>
				</div>
				<button scrollTo (click)="update()" class="btn green" [disabled]="form.invalid">Modifier</button>
			</form>
			<div class="form-admin-right">
				<div class="card">
				  <div class="card-header">
				    <i class="fa-regular fa-list-tree"></i> Premier niveau
				  </div>
				  <div class="card-body">
				  	<div class="go-to-container">
					  	<a class="go-to" *ngFor="let item of getLevel1()" scrollTo="go-item-{{item.ident}}">{{item.name}}<i class="fa-solid fa-arrow-up-right-from-square"></i></a>
					</div>
					<div class="title-content">
						Ajouter un niveau 1
					</div>
					<button (click)="addItem()" class="btn green" [disabled]="form.invalid">Ajouter</button>
				  </div>
				</div>
			</div>
		</div>
	</div>
</div>