<div [loading]="loading">
	<gob-back [url]="route.get('product-list')"></gob-back>
	<div class="form-content">
		<form class="form-admin-left" #form="ngForm">
			<gob-message [error]="error" [msg]="msg"></gob-message>

			<nav>
			  <div class="nav nav-tabs" id="product-tab">
			    <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#product-info" type="button">Informations</button>
			    <button class="nav-link" data-bs-toggle="tab" data-bs-target="#product-prices" type="button">Tarifs</button>
			    <button class="nav-link" data-bs-toggle="tab" data-bs-target="#product-class" type="button">Classification</button>
			    <button class="nav-link" data-bs-toggle="tab" data-bs-target="#product-stock" type="button">Stocks</button>
			  </div>
			</nav>
			<div class="tab-content" id="nav-tabContent">
			  <div class="tab-pane fade show active" id="product-info">
			  	<gob-product-info [product]="product" [product]="product"></gob-product-info>
			  </div>
			  <div class="tab-pane fade" id="product-prices">
			  	<gob-product-price [product]="product"></gob-product-price>
			  </div>
			  <div class="tab-pane fade" id="product-class">
			  	<gob-product-class [product]="product"></gob-product-class>
			  </div>
			  <div class="tab-pane fade" id="product-stock">
			  	<gob-product-stock [product]="product"></gob-product-stock>
			  </div>
			</div>

			<button scrollTo (click)="update(form)" class="btn green" [disabled]="form.invalid" [disabled]="form.invalid">{{((product.ident)? "Modifier" : "Créer")}}</button>
			<button *ngIf="product.ident" (click)="rm()" class="btn btn-danger">Supprimer</button>
		</form>
		<div class="form-admin-right">
			<!-- Détails du code promo -->
			<div class="card">
			  <div class="card-header">
			    <i class="fa-regular fa-satellite-dish"></i> Etat
			  </div>
			  <div class="card-body">
			  	<div class="badge-cntainer">		  		
				  	<span class="badge" [ngClass]="getBadgeClass()">{{product.state | uppercase}}</span>
			  	</div>

				<!-- brouillon -->
				<div class="form-check form-switch mb-3">
				  <input class="form-check-input" type="checkbox" (change)="setDraft($event)" id="product-draft" name="isDraft" [checked]="product.state=='draft'"/>
				  <label class="form-check-label" for="product-draft">Mode brouillon</label>
				</div>

				<!-- rupture -->
				<div class="form-check form-switch mb-3">
				  <input class="form-check-input" type="checkbox" (change)="setRupture($event)" id="product-rupture" name="isRupture" [checked]="product.state=='rupture'" [disabled]="product.state=='draft'"/>
				  <label class="form-check-label" for="product-rupture">Produit en rupture</label>
				</div>
			  </div>
			</div>

			<!-- images -->
			<div class="card">
			  <div class="card-header">
			    <i class="fa-regular fa-images"></i> Images
			  </div>
			  <div class="card-body">
			  	<div *ngIf="product.images.length" class="image-carrousel">
				  	<div class="img-container">
				  		<img [src]="getImage()"/>
				  		<a (click)="rmImage()" class="close-image"><i class="fa-sharp fa-solid fa-circle-xmark"></i></a>
				  	</div>
				  	<gob-pagination [activePage]="page" (change)="page=$event" [max]="product.images.length"></gob-pagination>
			  	</div>
				<div class="mb-3">
				  <input type="file" (change)="onFileSelected($event)" multiple required #img />
				</div>
			  </div>
			</div>
		</div>
	</div>
</div>
