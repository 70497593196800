import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarPageComponent } from './bar-page/bar-page.component';
import { PartialModule } from '../partial/partial.module';


@NgModule({
  declarations: [
    BarPageComponent
  ],
  imports: [
    CommonModule,
    PartialModule
  ],
  exports: [
    BarPageComponent
  ] 
})
export class BarModule { }
