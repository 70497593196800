import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { RouteService } from '@services/route.service';
import { GlobalService } from '@services/global.service';
import { Banner } from '@models/product';

@Component({
  selector: 'gob-banner-form',
  templateUrl: './banner-form.component.html',
  styleUrls: ['./banner-form.component.css']
})
export class BannerFormComponent implements OnInit {
  loading: boolean=false;
  error: string;
  msg: string;
  banner: Banner;

  constructor(public route: RouteService, private router: ActivatedRoute, private gServ: GlobalService) {
    this.loading=true;
    this.banner=new Banner();
    this.router.paramMap
      .pipe(
        switchMap(params => {
          if(params.get('id')){
            return this.gServ.getBanners()
                      .pipe(
                        map(banners => banners.find(c => c.ident==params.get('id')))
                      );
          }
          else
            return of(new Banner());
        })
      )
      .subscribe({
        next: banner => {
          this.banner=JSON.parse(JSON.stringify(banner));
          this.loading=false;
        },
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
  }

  ngOnInit(): void {
  }

  update(form: any){
    this.loading=true;
    let update: boolean=(this.banner.ident!=null);
    this.gServ.setBanner(this.banner).subscribe({
      next: () => {
        this.msg='Modification enregistrées';
        if(!update)
          form.reset();
        this.loading=false;
      },
      error: e => {
        this.error=e;
        this.loading=false;
      }
    });
  }

  rm(){
    if(confirm("Etes-vous sur de vouloir supprimer ?")){
      this.loading=true;
      this.gServ.rmBanner(this.banner.ident).subscribe({
        next: () => this.route.navigate('class-list'),
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
    }
  }
}
