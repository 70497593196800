<div class="toast-container position-fixed bottom-0 end-0 p-3">
  <div id="myToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
	  <img src="/assets/img/skin/star.png" alt="gobelin"/>
      <strong class="me-auto">{{notif.title}}</strong>
      <small>Il y a quelques secondes</small>
      <button type="button" class="btn-close" (click)="hideToast()" aria-label="Close"></button>
    </div>
    <div class="toast-body">
      {{notif.message}}
    </div>
  </div>
</div>
