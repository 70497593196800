import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { StateOrder } from '@models/order';
import { OrderService } from '@services/order.service';
import { RouteService } from '@services/route.service';

@Component({
  selector: 'gob-order-state-form',
  templateUrl: './order-state-form.component.html',
  styleUrls: ['./order-state-form.component.css']
})
export class OrderStateFormComponent implements OnInit {
  loading: boolean=false;
  error: string;
  msg: string;
  state: StateOrder;

  constructor(private router: ActivatedRoute, private orderServ: OrderService, public route: RouteService) {
    this.state=new StateOrder();
    this.loading=true;
    this.router.paramMap
      .pipe(
        switchMap(param => {
          if(param.get('id'))
            return this.orderServ.getState(param.get('id'), true)
              .pipe(
                map(state => JSON.parse(JSON.stringify(state)))
              );
          else
            return of(new StateOrder());
        })
      ).subscribe({
        next: state => {
          this.state=state;
          this.loading=false;
        },
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
  }

  ngOnInit(): void {
  }

  update(){
    this.loading=true;
    this.orderServ.setState(this.state).subscribe({
      next: () => {
        this.msg="Modificaitons enregistrées";
        this.loading=false;
      },
      error: e => {
        this.error=e;
        this.loading=false;
      }
    });
  }

  rm(ident: string){
    if(confirm('Etes vous sur de vouloir supprimer ?')){
      this.loading=true;
      this.orderServ.rmState(ident).subscribe({
        next: () => this.route.navigate('order-state-list'),
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
    }
  }

}
