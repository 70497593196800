import { Component, OnInit } from '@angular/core';
import { RouteService } from '@services/route.service';
import { ProductService } from '@services/product.service';
import { Rating } from '@models/product';
import { RatingFilter } from '@models/filter';

@Component({
  selector: 'gob-review-list',
  templateUrl: './review-list.component.html',
  styleUrls: ['./review-list.component.css']
})
export class ReviewListComponent implements OnInit {
  loading: boolean=false;
  filter: RatingFilter=new RatingFilter();
  ratings: Rating[]=[];
  size: number=50;
  page: number=0;
  start: number=0;
  max=0;

  constructor(private pServ: ProductService, public router: RouteService) {
    this.search();
  }

  ngOnInit(): void {
  }

  search(page: number=0, filter?: RatingFilter){
    this.loading=true;
    if(filter)
      this.filter=filter;
    this.pServ.searchRating(this.filter, this.start*this.size, this.size).subscribe(ratings => {
      this.start=page;
      if(ratings.length==this.size)
        this.max=page+2;
      else
        this.max=page-1;
      this.ratings=ratings;
      this.loading=false;
    });
  }

  getState(state: string){
    let name: string;
    switch(state){
      case 'review':
        name='En attente';
        break;
      case 'actif':
        name='En ligne';
        break;
      case 'supprimé':
        name='Supprimé';
        break;
      default:
        break;
    }
    return name;
  }

}
