<div [loading]="loading" class="form-container">
	<!-- categ -->
	<div class="col-sm">
	  <div class="form-floating mb-3">
		<select class="form-select" id="product-categ" name="categ" [(ngModel)]="product.categ" required>
	      <option [ngValue]="null">Sélectionnez une catégorie</option>
	      <option *ngFor="let categ of categs" [ngValue]="categ.ident">{{categ.name}}</option>
	    </select>
	    <label for="product-categ">Catégoie</label>
	  </div>
	</div>

	<!-- scateg -->
	<div class="col-sm">
	  <div class="form-floating mb-3">
		<select class="form-select" id="product-scateg" name="scateg" [(ngModel)]="product.scateg">
	      <option [ngValue]="null">Aucune sous-catégorie</option>
	      <option *ngFor="let scateg of scategs" [ngValue]="scateg.ident">{{scateg.name}}</option>
	    </select>
	    <label for="product-scateg">Sous catégories</label>
	  </div>
	</div>

	<!-- gamme -->
	<div class="col-sm">
	  <div class="form-floating mb-3">
		<select class="form-select" id="product-gamme" name="gamme" [(ngModel)]="product.gamme">
	      <option [ngValue]="null">Aucune gamme</option>
	      <option *ngFor="let gamme of gammes" [ngValue]="gamme.ident">{{gamme.name}}</option>
	    </select>
	    <label for="product-gamme">Gammes</label>
	  </div>
	</div>

	<!-- marque -->
	<div class="col-sm">
	  <div class="form-floating mb-3">
		<select class="form-select" id="product-marque" name="marque" [(ngModel)]="product.marque">
	      <option [ngValue]="null">Aucune marque</option>
	      <option *ngFor="let marque of marques" [ngValue]="marque.ident">{{marque.name}}</option>
	    </select>
	    <label for="product-marque">Marque</label>
	  </div>
	</div>

	<!-- Bonnes affaies -->
	<div>
		<div class="title-content green">
			Bonnes affaires :
		</div>
	  
	  	<!-- nouveauté -->
		<div class="form-check form-switch mb-3">
		  <input class="form-check-input" type="checkbox" id="product-isNew" name="isNew" [(ngModel)]="product.isNew"/>
		  <label class="form-check-label" for="product-isNew">Nouveauté</label>
		</div>

	  	<!-- promo -->
		<div class="form-check form-switch mb-3">
		  <input class="form-check-input" type="checkbox" id="product-isPromo" name="isPromo" [(ngModel)]="product.isPromo"/>
		  <label class="form-check-label" for="product-isPromo">Promo</label>
		</div>
	</div>

	<!-- Tags -->
    <div class="title-content green">
  	  Tags :
    </div>
	<div *ngIf="!product.tags.length">
		Aucun tag
	</div>
	<div *ngIf="product.tags.length">
	  <div class="tag-container">
	  	<span *ngFor="let tag of product.tags; index as i;" (click)="rmTag(i)" class="text-box close">{{tag.name}}</span>
	  </div>
	</div>
	<div *ngIf="getTagList().length" class="add-banner mb-3">
		<div class="input-group mb-3">
			<div class="form-floating">
			  <select class="form-select" id="tag-list" #tagItem>
			    <option *ngFor="let tag of getTagList()" value="{{tag.ident}}">{{tag.name}}</option>
			  </select>
			  <label for="tag-list">Ajouter un tag</label>
			</div>
			<button (click)="product.tags.push(getTag(tagItem.value))" class="input-group-text brun">
				<i class="fa-solid fa-plus"></i>
		  	</button>
		</div>
	</div>

	<!-- Banners -->
	<div *ngIf="!product.banners.length">
		Aucune bannière
	</div>
	<div *ngIf="product.banners.length">
	  <div class="title-content green">
	  	Bannières :
	  </div>
	  <div class="accordion" id="banner-list">
		  <div *ngFor="let banner of product.banners; index as i;" class="accordion-item">
		    <h2 class="accordion-header">
		      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#banner-'+i" aria-expanded="false" [attr.aria-controls]="'banner-'+i">
		        <span class="banner-container">
		        	<gob-banner *ngIf="getBanner(banner.ident)" [banner]="getBanner(banner.ident)"></gob-banner>
	        	</span>
	        	{{((banner.start)? 'du '+banner.start : '')}} {{((banner.end)? 'au '+banner.end : '')}}
		      </button>
		    </h2>
		    <div id="{{'banner-'+i}}" class="accordion-collapse collapse" data-bs-parent="#banner-list">
		      <div class="accordion-body">
				<!-- banner -->
			    <div class="form-floating mb-3">
			      <select class="form-select" id="banner-list-{{i}}" name="banner{{i}}" [(ngModel)]="banner.ident" required>
			        <option [ngValue]="null">Sélectionnez une bannière</option>
			        <option *ngFor="let b of banners" [ngValue]="b.ident">{{b.name}}</option>
			      </select>
			      <label for="banner-list-{{i}}">Bannière</label>
			    </div>

			    <!-- date start -->
				<div class="form-floating mb-3">
				  <input type="date" class="form-control" id="banner-start-{{i}}" placeholder="Début" name="start{{i}}" [(ngModel)]="banner.start"/>
				  <label for="banner-start-{{i}}">Début</label>
				</div>

				<!-- date end- -->
				<div class="form-floating mb-3">
				  <input type="date" class="form-control" id="banner-end-{{i}}" placeholder="Fin" name="end{{i}}" [(ngModel)]="banner.end"/>
				  <label for="banner-end-{{i}}">Fin</label>
				</div>

		       	<a (click)="rm(i)" class="btn btn-danger">Supprimer</a>
		      </div>
		    </div>
		  </div>
		</div>
	</div>

	<div class="add-banner mb-3">
		<a (click)="add()">
			<i class="fa-solid fa-layer-plus"></i> Ajouter une bannière
		</a>
	</div>
</div>