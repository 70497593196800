import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartialModule } from './partial/partial.module';
import { HomeModule } from './home/home.module';
import { ProductsModule } from './products/products.module';
import { BarModule } from './bar/bar.module';
import { ConnectModule } from './connect/connect.module';
import { OrderModule } from './order/order.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  exports: [
    PartialModule,
    HomeModule,
    ProductsModule,
    BarModule,
    ConnectModule,
    OrderModule
  ]
})
export class PageModule { }
