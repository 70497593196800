export class Shipper{
	ident: string;
	name: string;
	editable: boolean=true;
	disabled: boolean=false;
	state: 'actif' | 'supprimé'='actif';
	shippings: Shipping[]=[];
}

export class Shipping{
	ident: string;
	shipper: string;
	amount: number;
	tva: number=20;
	weightMin: number;
	weightMax: number;
}

export class Ship{
	ident: string;
	idOrder: string;
	shipper: string;
	weight: number;
	tracking: string;
	attributes: any;
}