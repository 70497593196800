<div [loading]="loading">
	<gob-header></gob-header>
	<div class="block">
		<div class="product-container">
			<div class="hidden-phone">
				<gob-diapo [product]="product"></gob-diapo>
			</div>
			<gob-product-detail [product]="product"></gob-product-detail>
		</div>
		<div class="details-container">
			<ul class="nav nav-tabs">
			  <li class="nav-item">
			    <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#detail-tab-pane" type="button">Détails</button>
			  </li>
			  <li class="nav-item">
			    <button class="nav-link" data-bs-toggle="tab" data-bs-target="#review-tab-pane" type="button">Avis</button>
			  </li>
			</ul>
			<div class="tab-content" id="detail-product-container">
			  <div class="tab-pane fade show active" id="detail-tab-pane" [innerHTML]="product?.details"></div>
			  <div class="tab-pane fade" id="review-tab-pane">
			  	<div *ngIf="!product.ratings.length" class="rating-container">
			  		Il n'y a pas encore d'avis pour ce produit, soyez le premier.
			  	</div>
			  	<gob-rating *ngFor="let rating of product.ratings" [rating]="rating"></gob-rating>
			  	<gob-rating-form [product]="product"></gob-rating-form>
			  </div>
			</div>
		</div>
		<div class="linked-contrainer">
			<h2>On vous recommande</h2>
			<div class="linked-product">
				<div *ngFor="let p of product.linked" class="product">
					<gob-product-card [product]="p"></gob-product-card>
				</div>
			</div>
		</div>
	</div>
</div>