import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { RouteService } from '@services/route.service';
import { ProductService } from '@services/product.service';
import { GlobalService } from '@services/global.service';
import { Product, Categ, SCateg, Gamme, Marque, Tag, Banner } from '@models/product';
import { ProductFilter } from '@models/filter';
import { environment } from '@environments/environment';

@Component({
  selector: 'gob-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {
  loading: boolean=false;
  error: string;
  msg: string;
  products: Product[]=[];
  categs: Categ[]=[];
  scategs: SCateg[]=[];
  gammes: Gamme[]=[];
  marques: Marque[]=[];
  banners: Banner[]=[];
  tags: Tag[]=[];
  size: number=50;
  max: number=0;
  page: number=0;
  filter: ProductFilter;

  constructor(public route: RouteService, private gServ: GlobalService, private productServ: ProductService) {
    this.loading=true;
    this.filter=new ProductFilter();
    forkJoin({
      classes: this.gServ.getClassification(),
      products: this.productServ.list(this.filter, 0, this.size) 
    }).subscribe(values => {
      this.categs=values.classes.categs;
      this.scategs=values.classes.scategs;
      this.gammes=values.classes.gammes;
      this.marques=values.classes.marques;
      this.tags=values.classes.tags;
      this.banners=values.classes.banners;
      this.products=values.products;
      this.setMax(0);
      this.loading=false;
    });
  }

  ngOnInit(): void {
  }

  search(filter: ProductFilter, page: number){
    this.loading=true;
    this.filter=filter;
    this.productServ.list(this.filter, page*this.size, this.size).subscribe(products => {
      this.products=products;
      this.setMax(page);
      this.page=page;
      this.loading=false;
    });
  }

  next(page: number){
    this.search(this.filter, page);
  }

  setMax(page: number){
    if(this.products.length==this.size)
      this.max=page+2;
  }

  rm(ident: string){
    if(confirm('Etes-vous sur de vouloir supprimer ?')){
      this.loading=true;
      this.productServ.rmProduct(ident).subscribe({
        next: () => {
          this.loading=false;
          this.next(this.page);
        },
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
    }
  }

  getCateg(product: Product){
    return this.categs.find(e => e.ident==product.categ);
  }

  getSCateg(product: Product){
    return this.scategs.find(e => e.ident==product.scateg);
  }

  getGamme(product: Product){
    return this.gammes.find(e => e.ident==product.gamme);
  }

  getMarque(product: Product){
    return this.marques.find(e => e.ident==product.marque);
  }

  getImage(product: Product){
    return `${environment.imgUrl}/product/${product.images[0].name}`;
  }

}
