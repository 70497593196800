import { Component, OnInit, Input } from '@angular/core';
import { formatNumber } from '@angular/common';
import { Product } from '@models/product';

@Component({
  selector: 'gob-product-selection',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.css']
})
export class ProductSelectionComponent implements OnInit {
  @Input() products: Product[]=[];
  @Input() ind: number=0;

  constructor(){ }

  ngOnInit(): void {
  }

  formatPrice(price: number){
    return formatNumber(price, 'fr', '1.2-2');
  }

  getStyleBanner(product: any){
    return {
      backgroundColor: `${product.color}`
    };
  }

  getStyleCaroussel(){
    let style: any={
      width: `${this.products.length*20}%`,
      minWidth: '100%',
      marginLeft: `${-this.ind*20}%`
    };

    let styleMobile: any={
      width: `${this.products.length*100}%`,
      marginLeft: `${-this.ind*100}%`
    };

    if(window.innerWidth<667)
      return styleMobile;
    else
      return style;
  }

  getSizeProduct(){
    let style: any={};

    if(window.innerWidth<667)
      style.width=`${100/this.products.length}%`;

    return style;
  }

}
