import { Directive, Input,  ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[loading]'
})
export class LoadingDirective {

  constructor(private element: ElementRef<HTMLElement>, private render: Renderer2) { }

  @Input()
  set loading(loading: boolean){
    if(loading)
      this.render.addClass(this.element.nativeElement, "loader");
    else
      this.render.removeClass(this.element.nativeElement, "loader");
  }
}
