import { Component, OnInit, Input } from '@angular/core';
import { Banner } from '@models/product';

@Component({
  selector: 'gob-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  @Input() banner: Banner;
  _banners: Banner[];
  @Input() 
  set banners(val: Banner[]){
    this._banners=val; 
    if(val){
      this.banner=val.find(banner => {
        let start: Date=new Date(banner.start);
        let end: Date=new Date(banner.end);
        let now: Date=new Date();
        if(banner.start==null && banner.end==null)
          return true;
        if(banner.start!=null && banner.end==null && start.getTime()<=now.getTime())
          return true;
        if(start.getTime()<=now.getTime() && end.getTime()>=now.getTime())
          return true;
        return false;
      });
    }
  }

  get banners(){
    return this._banners;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
