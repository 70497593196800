import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { Product, Price } from '@models/product';
import { UserService } from '@services/user.service';

@Pipe({
  name: 'getPrices'
})
export class GetPricesPipe implements PipeTransform {

  constructor(private userServ: UserService){ }

  /**
   * Méthode qui retourne les ou le prices valide pour un produit
   * vérifie les price en fonciton du cover du produit
   */
  transform(product: Product, ...args: any[]): any{
    let prices: Price[]=product.prices.filter(price => {
        let start: Date=new Date(price.start);
        let end: Date=new Date(price.end);
        let now: Date=new Date();
        if(!price.group || this.userServ.user.groupe==price.group){
          if(price.start==null && price.end==null)
            return true;
          if(price.start!=null && price.end==null && start.getTime()<=now.getTime())
            return true;
          if(start.getTime()<=now.getTime() && end.getTime()>=now.getTime())
            return true;
        }
        return false;
      });

    if(product.cover)
      prices=prices.filter(price => price.img==product.cover);

    if(args[0]!==null && args[0]!==undefined){
      if(args[0]<prices.length)
        return prices[args[0]];
      else
        return null;
    }

    return prices;
  }

}

@Pipe({
  name: 'formatPrice'
})
export class FormatPricePipe implements PipeTransform {

  transform(price: any, ...args: any[]){
    if(typeof price!='number'){
      if(price)
        return formatNumber(price.prix, 'fr', '1.2-2');
      else
        return null;
    }
    return formatNumber(price, 'fr', '1.2-2');
  }

}