import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'gob-range',
  templateUrl: './range.component.html',
  styleUrls: ['./range.component.css']
})
export class RangeComponent implements OnInit {
  @Output() readonly setVal: EventEmitter<any>=new EventEmitter<any>();
  @Input() min: number=0;                  //interval min
  @Input() max: number=999;                //interval max
  @Input() minVal: number=0;               //valeur min
  @Input() maxVal: number=50;              //valeur max
  @Input() steps: number=5;                //nombre de step moves
  @Input() symbol: string;                 //unité
  @Input() height: number=30;              //hauteur
  @Input() background: string='#c7ebba';   //couleur du background
  @Input() color: string='#000';           //couleur police
  @Input() title: boolean=false;           //ajout de la légende

  constructor() { }

  ngOnInit(): void {
  }

  getLabel(){
    let sufix: string=((!this.title)? this.symbol : '');
    let result: string=''+this.min+sufix;
    let step: number=Math.floor(this.max/this.steps);
    for(let i: number=1; i*step<this.max; i++){
      result+=' '+i*step+sufix;
    }
    result+=' '+this.max+sufix;

    return result;
  }

  changes(){
    this.setVal.emit({min: this.minVal, max: this.maxVal});
  }
}
