<div class="product-img">
	<div class="img-container">
		<img [src]="getImage()" [alt]="product.name"/>
	</div>
	<div class="banner-container">
		<gob-banner [banners]="product.banners"></gob-banner>
	</div>
	<div class="product-info">
		<a [routerLink]="route.parse('product', [product.ident])" [queryParams]="getQueryParam(product | getPrices:0)" title="En savoir plus" class="info border-gradient-circle">
			<i class="fa-light fa-magnifying-glass-plus"></i>
		</a>
		<button *ngIf="product | getPrices:0" (click)="addCart(product)" type="button" class="btn btn-dark">Panier</button>
	</div>
</div>
<a [routerLink]="route.parse('product', [product.ident])" [queryParams]="getQueryParam(product | getPrices:0)" class="products-price-container">
	<span class="product-title">
		{{product.name}}
	</span>
	<div class="subtitle truncate-2l">
		{{product.subtitle}}
	</div>
	<span class="product-stars">
		<gob-stars [stars]="product.stars"></gob-stars>
	</span>
	<span *ngIf="product | getPrices:0" class="product-price">
		{{product | getPrices:0 | formatPrice}}€
	</span>
</a>