<form (ngSubmit)="search.emit(filter)">
	<div class="row g-3">
		<!-- date de début -->
	  	<div class="col-sm">
			<div class="form-floating mb-3 nsb">
			  <input type="date" class="form-control" id="stat-start" placeholder="Depuis" name="start" [(ngModel)]="filter.start"/>
			  <label for="stat-start">Depuis</label>
			</div>
		</div>

		<!-- debut de fin -->
	  	<div class="col-sm">
			<div class="form-floating mb-3 nsb">
			  <input type="date" class="form-control" id="stat-end" placeholder="Jusqu'à" name="end" [(ngModel)]="filter.end"/>
			  <label for="stat-end">Jusqu'à</label>
			</div>
		</div>

	  	<div class="col-sm-1">
	  		<button type="submit" class="btn btn-primary search">
	  			<i class="fa-light fa-magnifying-glass"></i>
	  		</button>
	  	</div>
	</div>
</form>
