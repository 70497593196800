<div class="block form-admin" [loading]="loading">
	<header class="header-admin">
		<h1 class="admin">Commande n°{{order?.ident}}</h1>
	</header>
	<gob-back [url]="route.get('order-list')"></gob-back>
	<div class="form-content">
		<div class="form-admin-left">
			<gob-message [error]="error" [msg]="msg"></gob-message>
			<div *ngIf="order.ident" class="address-container">
				<div>
					<div class="title-content">
						Addresse de livraison : 
					</div>
					<span *ngIf="order.addressObj.social">
						{{order.addressObj.social | titlecase}} - 
					</span>
					{{order.addressObj.firstname | titlecase}} {{order.addressObj.lastname | titlecase}}<br/>
					{{order.addressObj.address}}<br/>
					{{order.addressObj.cp}}, {{order.addressObj.city}}<br/>
					{{getPays(order.addressObj.pays).name}}
				</div>
				<div>
					<div class="title-content">
						Addresse de facturation : 
					</div>
					<span *ngIf="order.addressInvoiceObj.social">{{order.addressInvoiceObj.social | titlecase}} - </span>
					{{order.addressInvoiceObj.firstname | titlecase}} {{order.addressInvoiceObj.lastname | titlecase}}<br/>
					{{order.addressInvoiceObj.address}}<br/>
					{{order.addressInvoiceObj.cp}}, {{order.addressInvoiceObj.city}}<br/>
					{{getPays(order.addressInvoiceObj.pays).name}}
				</div>
			</div>
			<div class="table-responsive">
				<table class="table">
				    <thead>
				      <tr>
				        <th scope="col">Ref</th>
				        <th scope="col">Nom</th>
				        <th scope="col">Qte</th>
				        <th scope="col">Total</th>
				      </tr>
				    </thead>
				    <tbody class="table-group-divider">
				      <tr *ngFor="let transaction of getTransactions()">
				        <td>{{transaction.ref}}</td>
				        <td>{{transaction.name}}</td>
				        <td>{{transaction.qte}}</td>
				        <td>{{transaction.amountTTC | formatPrice}}€</td>
				      </tr>
				      <tr>
				      	<td colspan="4"></td>
				      </tr>
				      <tr *ngIf="getDiscount()">
				      	<td colspan="2"></td>
				      	<td><b>Remise</b></td>
				      	<td>{{getDiscount() | formatPrice}}€</td>
				      </tr>
				      <tr *ngIf="order.amountShipping">
				      	<td colspan="2"></td>
				      	<td><b>Frais de port</b></td>
				      	<td>{{order.amountShipping | formatPrice}}€</td>
				      </tr>
				      <tr>
				      	<td colspan="2"></td>
				      	<td><b>Total HT</b></td>
				      	<td>{{order.amountHT | formatPrice}}€</td>
				      </tr>
				      <tr>
				      	<td colspan="2"></td>
				      	<td><b>Total TTC</b></td>
				      	<td>{{order.amountTTC | formatPrice}}€</td>
				      </tr>
				    </tbody>
				</table>
			</div>

			<div *ngIf="order.refunds.length && !openRefund" class="refund-container">
				<gob-refund-list [order]="order"></gob-refund-list>
			</div>
			<div *ngIf="admin" class="addRefund">
				<gob-refund-form *ngIf="openRefund" [order]="order" (refund)="refund($event)" (back)="openRefund=false"></gob-refund-form>
				<a (click)="openRefund=true">
					<i class="fa-solid fa-layer-plus"></i> Créer un retour client
				</a>
			</div>

			<!-- commentaire -->
			<div class="form-floating mb-3">
			  <textarea class="form-control" style="height: 150px;" placeholder="Commentaire sur la commande" id="order-comment" [(ngModel)]="order.publicComment" [disabled]="!admin"></textarea>
			  <label for="order-comment">Commentaire</label>
			</div>

			<!-- private commentaire -->
			<div *ngIf="admin" class="form-floating mb-3">
			  <textarea class="form-control" style="height: 150px;" placeholder="Commentaire privé" id="order-privatecomment" [(ngModel)]="order.privateComment"></textarea>
			  <label for="order-privatecomment">Commentaire privé</label>
			</div>

			<div *ngIf="admin" class="btn-container">
				<button scrollTo (click)="setOrder()" type="button" class="btn green">Modifier</button>
			</div>
		</div>
		<div class="form-admin-right">
			<!-- Statut -->
			<div class="card">
			  <div class="card-header">
			    <i class="fa-sharp fa-regular fa-satellite-dish"></i> Etat de la commande
			  </div>
			  <div class="card-body">
			  	<!-- état -->
				<div [ngSwitch]="order.state" class="banner-container">
					<gob-banner *ngSwitchCase="'state-complete'" [banner]="{name: state?.name, color: 'var(--bs-danger)'}"></gob-banner>
					<gob-banner *ngSwitchCase="'state-payed'" [banner]="{name: state?.name, color: 'var(--bs-warning)'}"></gob-banner>
					<gob-banner *ngSwitchCase="'state-send'" [banner]="{name: state?.name, color: 'var(--bs-success)'}"></gob-banner>
					<gob-banner *ngSwitchCase="'state-prepared'" [banner]="{name: state?.name, color: 'var(--bs-success)'}"></gob-banner>
					<gob-banner *ngSwitchCase="'state-pickup'" [banner]="{name: state?.name, color: 'var(--bs-success)'}"></gob-banner>
					<gob-banner *ngSwitchDefault [banner]="{name: state?.name, color: 'var(--bs-info)'}"></gob-banner>
				</div>
				<b>Date : </b>{{order.dateCreate | date:'d, MMMM yyyy'}}

				<!-- réglement -->
				<div *ngIf="order.state!='state-complete'" class="pay-container">
					<!-- réglement CB -->
					<div *ngIf="order.paymentObj?.type=='tpe-payment'">
				    	<i class="fa-regular fa-credit-card"></i>
				    	le {{order.paymentObj.dateCreate | date:'d, MMMM yyyy'}}
					</div>

					<!-- réglement Paypal -->
					<div *ngIf="order.paymentObj?.type=='paypal-payment'">
				    	<i class="fa-brands fa-paypal"></i>
				    	le {{order.paymentObj.dateCreate | date:'d, MMMM yyyy'}}
					</div>

					<a (click)="getInvoice()">
						<i class="fa-regular fa-file-invoice"></i> Télécharger la facture
					</a>
				</div>

				<!-- regler -->
				<div *ngIf="order.state=='state-complete'">
					<b>Regler votre commande : </b>
					<div class="pay-container">
						<!-- réglement CB -->
						<div class="form-check mb-1">
							<input (change)="execMonetico()" class="form-check-input me-1" type="radio" name="paiement" id="paiement-cb" value="cb" [disabled]="stateInit!='state-complete'" #cb>
						    <label class="form-check-label" for="paiement-cb">
						    	<i class="fa-regular fa-credit-card"></i> CB
						    </label>
						</div>

						<!-- réglement Paypal -->
						<div class="form-check">
						    <input (change)="execPaypal()" class="form-check-input me-1" type="radio" name="paiement" id="paiement-paypal" value="paypal" [disabled]="stateInit!='state-complete'" #paypal>
						    <label class="form-check-label" for="paiement-paypal">
						    	<i class="fa-brands fa-paypal"></i> Paypal
						    </label>
						</div>
						<div *ngIf="submitMonetico">
							<gob-monetico [order]="order.ident" [submit]="submitMonetico"></gob-monetico>
						</div>
					</div>
				</div>
			  </div>

			  <div *ngIf="admin" class="card-body">
				  <div class="form-floating mb-3">
					<select class="form-select" id="order-state" name="state" [(ngModel)]="order.state">
				      <option *ngFor="let state of states" [ngValue]="state.ident">{{state.name}}</option>
				    </select>
				    <label for="order-state">Modifier l'état</label>
				  </div>
			  </div>
			</div>

			<!-- Livraison -->
			<div class="card">
			  <div class="card-header">
			    <i class="fa-regular fa-sharp fa-rocket-launch"></i> Livraison
			  </div>
			  <div class="card-body">
				<b>Transporteur : </b>{{((order.dCollect)? 'Retrait boutique' : shipper?.name)}}
				<div *ngIf="order.dCollect">
					<b>Boutique : </b>{{depot?.name}}<br/>
					<b>Date de retrait : </b>{{order.dCollect | date:'d, MMMM yyyy':'':'fr'}}<br/>
					<b>Heure de retrait : </b>{{order.dCollect+' '+order.hCollect | date:'HH\'h\'mm'}}
				</div>
				<div *ngIf="!order.dCollect">
					<div *ngIf="order.ship && order.shipObj.attributes?.relayPoint">
						<b>Point relay : </b>{{order.shipObj.attributes.relayPoint}}
					</div>
					<b>Poids : </b>{{order.weight}}kg
					<div *ngIf="order.ship && order.shipObj.tracking">
						<b>Suivi : </b>{{order.shipObj.tracking}}
					</div>
				</div>
			  </div>

			  <div *ngIf="canPrint()" class="tag-container card-body">
			  	<a *ngIf="!(order.shipObj.attributes && order.shipObj.attributes.expedition)" (click)="doExp()"><i class="fa-solid fa-layer-plus"></i> Créer l'expédition</a>
			  	<!-- colissimo -->
			  	<div *ngIf="isColissimo()">
			  		<a *ngIf="order.shipObj.attributes && order.shipObj.attributes.expedition" (click)="printColissimo()"><i class="fa-solid fa-print"></i> Imprimer les étiquettes</a>
			  	</div>

			  	<div *ngIf="isMR()">
			  		<a *ngIf="order.shipObj.attributes && order.shipObj.attributes.expedition" [href]="order.shipObj.attributes.tag" target="_blank"><i class="fa-solid fa-print"></i> Imprimer les étiquettes</a>
			  	</div>
			  </div>
			</div>
		</div>
	</div>
</div>