<div [loading]="loading">
	<h2 class="admin black">Bannières</h2>
	<gob-back [url]="route.get('class-list')"></gob-back>
	<div class="form-content">
		<form class="form-admin-left" #form="ngForm">
			<gob-message [error]="error" [msg]="msg"></gob-message>

			<div class="title-content">
				Aperçu : 
				<div class="banner-container">
					<gob-banner [banner]="banner"></gob-banner>
				</div>
			</div>
			
			<!-- nom du code -->
			<div class="form-floating mb-3">
			  <input type="text" class="form-control" id="banner-name" placeholder="Nom de la bannières" name="name" [(ngModel)]="banner.name" required />
			  <label for="banner-name">Nom de la bannières</label>
			</div>

			<!-- color du la bannière -->
			<div class="form-floating mb-3">
			  <input type="color" class="form-control" id="banner-color" placeholder="Couleur de la bannières" name="color" [(ngModel)]="banner.color" required />
			  <label for="banner-color">Couleur de la bannières</label>
			</div>

			<button scrollTo (click)="update(form)" class="btn green" [disabled]="form.invalid">{{((banner.ident)? "Modifier" : "Créer")}}</button>
			<button (click)="rm()" *ngIf="banner.ident" class="btn btn-danger">Supprimer</button>
		</form>
	</div>
</div>