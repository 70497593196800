import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { tap, map, shareReplay } from 'rxjs/operators';
import { ApiService} from '@services/api.service';
import { MenuItem, Setting } from '@models/global';
import { Categ, SCateg, Gamme, Marque, Tag, Banner, Filter } from '@models/product';

@Injectable({
	providedIn: 'root'
})
export class GlobalService{
	private menu: MenuItem[];
	private categs: Categ[];
	private scategs: SCateg[];
	private gammes: Gamme[];
	private marques: Marque[];
	private tags: Tag[];
	private banners: Banner[];
	private filters: Filter[];
	private settings: Setting[];
	private classObs: Observable<any>;

	constructor(private api: ApiService){}

	getMenu(){
		if(this.menu)
			return of(this.menu);
		else
			return this.api.get<MenuItem[]>('/menu/items')
				.pipe(
					map(items => GlobalService.structurMenu(items)),
					tap(items => this.menu=items)
				);
	}

	updateMenu(items: MenuItem[]){
		return this.api.post<MenuItem[]>('/menu/items', items)
			.pipe(
				tap(menu => this.menu=menu)
			);
	}

	static structurMenu(items: MenuItem[]){
		let structured: MenuItem[]=[];
		
		//ajout children aux parents
		items.forEach(item => {
			if(item.parent){
				let ind:number=items.findIndex(i => i.ident==item.parent);
				if(ind!=-1)
					items[ind].children.push(item);
			}
			else{
				structured.push(item);
			}
		});

		GlobalService.sortItems(structured);

		return structured;
	}

	static sortItems(items: MenuItem[]){
		items.sort((a, b) => a.ind-b.ind);
		items.forEach(item => GlobalService.sortItems(item.children));
	}

	static addItem(items: MenuItem[]){
	    let item=new MenuItem();
	    if(items.length)
		    item.ind=Math.max(...items.map(i => i.ind))+1;
		else
			item.ind=0;
	    items.push(item);
	}

	getClassification(){
		if(!this.classObs){
			this.classObs=this.api.get<any>('/products/global')
				.pipe(
					shareReplay(1),
					tap(elements => {
						this.categs=elements.categs;
						this.scategs=elements.scategs;
						this.gammes=elements.gammes;
						this.marques=elements.marques;
						this.tags=elements.tags;
      					this.banners=elements.banners;
      					this.filters=elements.filters;
      					this.settings=elements.settings;
					})
				);
		}
		return this.classObs;
	}

	getGlobalFilters(type: string, ident: string){
		return this.api.get<any>(`/products/global/${type}/${ident}`);
	}

	getCategs(){
		if(this.categs)
			return of(this.categs);
		else
			return this.getClassification()
				.pipe(
					map(() => this.categs)
				);
	}

	setCateg(categ: Categ){
		let update: boolean=(categ.ident!=null);
		let url: string='/categs';
		if(update)
			url+='/'+categ.ident;
		return this.api.post(url, categ)
			.pipe(
				tap(categ => {
					if(this.categs){
						if(update)
							this.categs.splice(this.categs.findIndex(c => c.ident==categ.ident), 1, categ);
						else 
							this.categs.push(categ);
					}
				})
			);
	}

	rmCateg(ident: string){
		return this.api.delete<any>(`/categs/${ident}`)
			.pipe(
				tap(() => {
					if(this.categs)
						this.categs.splice(this.categs.findIndex(c => c.ident==ident), 1);
				})
			);
	}

	getSCategs(){
		if(this.scategs)
			return of(this.scategs);
		else
			return this.getClassification()
				.pipe(
					map(() => this.scategs)
				);
	}

	setSCateg(scateg: SCateg){
		let update: boolean=(scateg.ident!=null);
		let url: string='/scategs';
		if(update)
			url+='/'+scateg.ident;
		return this.api.post(url, scateg)
			.pipe(
				tap(scateg => {
					if(this.scategs){
						if(update)
							this.scategs.splice(this.scategs.findIndex(c => c.ident==scateg.ident), 1, scateg);
						else 
							this.scategs.push(scateg);
					}
				})
			);
	}

	rmSCateg(ident: string){
		return this.api.delete<any>(`/scategs/${ident}`)
			.pipe(
				tap(() => {
					if(this.scategs)
						this.scategs.splice(this.scategs.findIndex(c => c.ident==ident), 1);
				})
			);
	}

	getGammes(){
		if(this.gammes)
			return of(this.gammes);
		else
			return this.getClassification()
				.pipe(
					map(() => this.gammes)
				);
	}

	setGamme(gamme: Gamme){
		let update: boolean=(gamme.ident!=null);
		let url: string='/gammes';
		if(update)
			url+='/'+gamme.ident;
		return this.api.post(url, gamme)
			.pipe(
				tap(gamme => {
					if(this.gammes){
						if(update)
							this.gammes.splice(this.gammes.findIndex(c => c.ident==gamme.ident), 1, gamme);
						else 
							this.gammes.push(gamme);
					}
				})
			);
	}

	rmGamme(ident: string){
		return this.api.delete<any>(`/gammes/${ident}`)
			.pipe(
				tap(() => {
					if(this.gammes)
						this.gammes.splice(this.gammes.findIndex(c => c.ident==ident), 1);
				})
			);
	}

	getMarques(){
		if(this.marques)
			return of(this.marques);
		else
			return this.getClassification()
				.pipe(
					map(() => this.marques)
				);
	}

	setMarque(marque: Marque){
		let update: boolean=(marque.ident!=null);
		let url: string='/marques';
		if(update)
			url+='/'+marque.ident;
		return this.api.post(url, marque)
			.pipe(
				tap(marque => {
					if(this.marques){
						if(update)
							this.marques.splice(this.marques.findIndex(c => c.ident==marque.ident), 1, marque);
						else 
							this.marques.push(marque);
					}
				})
			);
	}

	rmMarque(ident: string){
		return this.api.delete<any>(`/marques/${ident}`)
			.pipe(
				tap(() => {
					if(this.marques)
						this.marques.splice(this.marques.findIndex(c => c.ident==ident), 1);
				})
			);
	}

	getTags(){
		if(this.tags)
			return of(this.tags);
		else
			return this.getClassification()
				.pipe(
					map(() => this.tags)
				);
	}

	setTag(tag: Tag){
		let update: boolean=(tag.ident!=null);
		let url: string='/tags';
		if(update)
			url+='/'+tag.ident;
		return this.api.post(url, tag)
			.pipe(
				tap(tag => {
					if(this.tags){
						if(update)
							this.tags.splice(this.tags.findIndex(c => c.ident==tag.ident), 1, tag);
						else 
							this.tags.push(tag);
					}
				})
			);
	}

	rmTag(ident: string){
		return this.api.delete<any>(`/tags/${ident}`)
			.pipe(
				tap(() => {
					if(this.tags)
						this.tags.splice(this.tags.findIndex(c => c.ident==ident), 1);
				})
			);
	}

	getFilters(){
		if(this.filters)
			return of(this.filters);
		else 
			return this.getClassification()
				.pipe(
					map(() => this.filters)
				);
	}

	getBanners(){
		if(this.banners)
			return of(this.banners);
		else
			return this.getClassification()
				.pipe(
					map(() => this.banners)
				);
	}

	setBanner(banner: Banner){
		let update: boolean=(banner.ident!=null);
		let url: string='/banners';
		if(update)
			url+='/'+banner.ident;
		return this.api.post(url, banner)
			.pipe(
				tap(banner => {
					if(this.banners){
						if(update)
							this.banners.splice(this.banners.findIndex(c => c.ident==banner.ident), 1, banner);
						else 
							this.banners.push(banner);
					}
				})
			);
	}

	rmBanner(ident: string){
		return this.api.delete<any>(`/banners/${ident}`)
			.pipe(
				tap(() => {
					if(this.banners)
						this.banners.splice(this.banners.findIndex(c => c.ident==ident), 1);
				})
			);
	}

	getSettings(){
		if(this.settings)
			return of(this.settings);
		else
			return this.getClassification()
				.pipe(
					map(() => this.settings)
				);
	}

	setSettings(settings: Setting[], img: File){
		let data: any=JSON.parse(JSON.stringify(settings));
		data.img=img;
		return this.api.post<Setting[]>('/settings', ApiService.toFormData(data));
	}
}