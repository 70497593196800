import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ProductService } from '@services/product.service';
import { Product } from '@models/product';

@Component({
  selector: 'gob-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.css']
})
export class ProductPageComponent implements OnInit {
  loading: boolean=false;
  product: Product;
  indImg: number=0;

  constructor(private router: ActivatedRoute, private pServ: ProductService) { 
    this.loading=true;
    this.product=new Product();
    this.router.paramMap
      .pipe(
        switchMap(params => this.pServ.getProduct(params.get('id')))
      )
      .subscribe(product => {
        this.product=product;
        this.loading=false;
      });
  }

  ngOnInit(): void {
  }
}
