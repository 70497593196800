import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from '@services/product.service';
import { RouteService } from '@services/route.service';
import { Rating } from '@models/product';

@Component({
  selector: 'gob-review-page',
  templateUrl: './review-page.component.html',
  styleUrls: ['./review-page.component.css']
})
export class ReviewPageComponent implements OnInit {
  loading: boolean=false;
  error: string;
  msg: string;
  rating: Rating;

  constructor(private pServ: ProductService, private router: ActivatedRoute, public route: RouteService) {
    this.loading=true;
    this.pServ.getRating(router.snapshot.paramMap.get('id')).subscribe(rating => {
      this.rating=rating;
      this.loading=false;
    });
  }

  ngOnInit(): void {
  }

  valid(){
    this.loading=true;
    this.pServ.validRating(this.rating.ident).subscribe(rating => {
      this.rating=rating;
      this.loading=false;
    });
  }

  rm(){
    this.loading=true;
    this.pServ.rmRating(this.rating.ident).subscribe(rating => {
      this.rating.state=rating.state;
      this.loading=false;
    });
  }

  getState(state: string){
    let name: string;
    switch(state){
      case 'review':
        name='En attente';
        break;
      case 'actif':
        name='En ligne';
        break;
      case 'supprimé':
        name='Supprimé';
        break;
      default:
        break;
    }
    return name;
  }

  getBanner(){
    let color: string;
    switch(this.rating?.state){
      case 'review':
        color='var(--bs-warning)';
        break;
      case 'actif':
        color='var(--bs-success)';
        break;
      case 'supprimé':
        color='var(--bs-danger)';
        break;
      default:
        break;
    }
    return {color, name: this.getState(this.rating?.state)}
  }

}
