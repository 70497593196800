import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'gob-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.css']
})
export class YoutubeComponent implements OnInit {
  @Input() url: string;
  @Input() id: string;
  safeUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    if(!this.url)
      this.url=`https://www.youtube.com/embed/${this.id}`;
    this.safeUrl=this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
}
