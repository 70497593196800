import { Injectable } from '@angular/core';
import { Product, Price} from '@models/product';
import { Order, Transaction} from  '@models/order';
import { NotifyService } from '@services/notify.service';

@Injectable({
	providedIn: 'root'
})
export class CartService{
	private lines: LineCart[]=[];

	constructor(private notify: NotifyService){
		this.load();
	}

	add(product: Product, price: Price, qte: number){
		if(qte>0){
			let qteCart: number=this.lines.reduce((acc, current) => {
				if(current.product.ident==product.ident)
					return acc+=current.qte*current.price.qte;
				else
					return acc;
			}, 0);
			let line: LineCart=this.lines.find(l => l.price.ident==price.ident);

			if(!product.limitOrder || qteCart+qte*price.qte<=product.limitOrder){
				if(line)
					line.qte+=qte;
				else
					this.lines.push(new LineCart(product, price, qte));
				this.notify.set('Panier', 'Produit(s) ajouté(s) au panier');
			}
			else
				this.notify.set('Panier', `Quantité limitée à ${product.limitOrder}`);
			this.save();
		}
		else
			this.notify.set('Panier', 'Ajout au pinier imposible');
	}

	rm(i: number){
		this.lines.splice(i, 1);
		this.save();
	}

	count(){
		return this.lines.length;
	}

	getLines(){
		return this.lines;
	}

	save(){
		localStorage.setItem('cart', JSON.stringify(this.lines));
	}

	load(){
		this.lines=JSON.parse(localStorage.getItem('cart'));
		if(!this.lines){
			this.lines=[];
			this.save();
		}
	}

	clear(){
		this.lines=[];
		this.save();
		localStorage.setItem('order', null);
	}

	maxQte(product: Product, price: Price){
		let maxPdt: number=null;
		let maxRupture: number=null;

		if(product.limitOrder)
		  maxPdt=Math.floor(product.limitOrder/price.qte);
		if(product.state=='rupture'){
		  let stocks: number=product.stocks.reduce((acc, curent) => acc+curent.qte, 0);
		  maxRupture=Math.max(0, stocks);
		}

		if(maxPdt===null)
		  return maxRupture;
		if(maxRupture===null)
		  return maxPdt;
		return Math.min(maxPdt, maxRupture);
	}

	setOrder(order: Order=null){
		if(!order){
			order=new Order();
			this.getLines().forEach(line => order.transactions.push(new Transaction(line.price.ident, line.qte)));
		}
		localStorage.setItem('order', JSON.stringify(order));

		return order;
	}

	getOrder(){
		if(localStorage.getItem('order'))
			return JSON.parse(localStorage.getItem('order'));
		else
			return this.setOrder();
	}
}

export class LineCart{
	constructor(
		public product: Product,
		public price: Price,
		public qte: number
	){ }
}