import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { SearchHomeComponent } from './search-home/search-home.component';
import { HomeSelectionComponent } from './home-selection/home-selection.component';
import { ProductSelectionComponent } from './product-selection/product-selection.component';
import { ActuComponent } from './actu/actu.component';
import { UiModule } from '../../ui/ui.module';
import { PartialModule } from '@components/page/partial/partial.module';
import { HomeAnimComponent } from './home-anim/home-anim.component';
import { AnimListComponent } from './anim-list/anim-list.component';


@NgModule({
  declarations: [
    HomePageComponent,
    SearchHomeComponent,
    HomeSelectionComponent,
    ProductSelectionComponent,
    ActuComponent,
    HomeAnimComponent,
    AnimListComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    RouterModule,
    PartialModule
  ],
  exports: [
    HomePageComponent
  ]
})
export class HomeModule { }
