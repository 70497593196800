import { Component, OnInit } from '@angular/core';
import { RouteService } from '@services/route.service';
import { StockService } from '@services/stock.service';
import { Depot } from '@models/stock';

@Component({
  selector: 'gob-depot-list',
  templateUrl: './depot-list.component.html',
  styleUrls: ['./depot-list.component.css']
})
export class DepotListComponent implements OnInit {
  loading: boolean=false;
  error: string;
  msg: string;
  depots: Depot[];

  constructor(public route: RouteService, private stockServ: StockService) { }

  ngOnInit(): void {
    this.loading=true;
    this.stockServ.getDepots().subscribe(depots => {
      this.depots=depots;
      this.loading=false;
    });
  }

  rm(ident: string){
    if(confirm('Etes vous sur de vouloir supprimé')){
      this.loading=true;
      this.stockServ.rmDepot(ident).subscribe({
        next: () => {
          this.error=null;
          this.depots.splice(this.depots.findIndex(depot => depot.ident==ident), 1);
          this.loading=false;
        },
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
    }
  }

}
