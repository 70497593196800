import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { RouteService } from '@services/route.service';
import { ProductService } from '@services/product.service';
import { GlobalService } from '@services/global.service';
import { Product, Categ, SCateg, Gamme, Marque, Tag, Banner } from '@models/product';
import { Image } from '@models/image';
import { environment } from '@environments/environment';

declare var bootstrap: any;

@Component({
  selector: 'gob-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.css']
})
export class ProductFormComponent implements OnInit {
  loading: boolean=false;
  error: string;
  msg: string;
  product: Product;
  page: number=0;

  constructor(
    public route: RouteService,
    private pServ: ProductService,
    private router: ActivatedRoute,
    private gServ: GlobalService
  ) {
    this.loading=true;
    this.product=new Product();
    this.router.paramMap
      .pipe(
        switchMap(params => {
          if(params.get('id'))
            return this.pServ.getProduct(params.get('id'), true);
          else
            return of(new Product());
        })
      )
      .subscribe(product => {
        this.product=product;
        if(this.router.snapshot.queryParamMap.get('duplicate')){
          product.duplicateImage=product.ident;
          product.ident=null;
        }
        this.product.isDraft=(product.state=='draft');
        this.product.isRupture=(product.state=='rupture');
        this.loading=false;
      });
  }

  ngOnInit(): void {
    const triggerTabList=document.querySelectorAll('#product-tab button');
    triggerTabList.forEach(triggerEl => {
      const tabTrigger=new bootstrap.Tab(triggerEl);

      triggerEl.addEventListener('click', event => {
        event.preventDefault();
        tabTrigger.show();
      });
    });
  }

  getBadgeClass(){
    return {
      'text-bg-secondary': this.product.state=='draft',
      'text-bg-success': this.product.state=='actif',
      'text-bg-warning': this.product.state=='rupture'
    };
  }

  onFileSelected(event: any) {
    const file=event.target.files;
    this.product.imageFiles=file;
  }

  setDraft(event: any){
    if(event.target.checked)
      this.product.state='draft';
    else
      this.product.state='actif';
    this.product.isDraft=event.target.checked;
  }

  setRupture(event: any){
    if(event.target.checked)
      this.product.state='rupture';
    else
      this.product.state='actif';
    this.product.isRupture=event.target.checked;
  }

  update(form: any){
    this.loading=true;
    let update: boolean=(this.product.ident!=null);
    this.pServ.setProduct(this.product).subscribe({
      next: product => {
        this.msg='Modifications enregistrées';
        if(!update){
          this.product=new Product();
          form.reset();
        }
        else{
          this.product.images=product.images;
          this.product.imageFiles=[];
          this.page=0;
        }
        this.loading=false;
      },
      error: e => {
        this.error=e;
        this.loading=false;
      }
    });
  }

  rm(){
    if(confirm('Etes-vous sur de vouloir supprimer ?')){
      this.loading=true;
      this.pServ.rmProduct(this.product.ident).subscribe({
        next: () => {
          this.loading=false;
          this.route.navigate('product-list');
        },
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
    }
  }

  getImage(){
    return `${environment.imgUrl}/product/${this.product.images[this.page].name}`;
  }

  rmImage(){
    if(!this.product.rmImages)
      this.product.rmImages=[];
    this.product.rmImages.push(this.product.images[this.page].ident);
    this.product.images.splice(this.page, 1);
    if(this.page!=0 && this.product.images.length)
      this.page--;
  }

}
