<!-- nom du produit -->
<div class="form-floating mb-3">
  <input type="text" class="form-control" id="product-name" placeholder="Nom du produit" name="name" [(ngModel)]="product.name" required />
  <label for="product-name">Nom du produit</label>
</div>

<!-- ref du produit -->
<div class="form-floating mb-3">
  <input type="text" class="form-control" id="product-ref" placeholder="Ref du produit" name="ref" [(ngModel)]="product.ref" required />
  <label for="product-ref">Ref du produit</label>
</div>

<!-- codebarre du produit -->
<div class="form-floating mb-3">
  <input type="text" class="form-control" id="product-barrecode" placeholder="Code barre" name="barrecode" [(ngModel)]="product.barrecode" required />
  <label for="product-barrecode">Code barre</label>
</div>

<!-- Poids du produit -->
<div class="form-floating mb-3">
  <input type="number" class="form-control" id="product-weight" placeholder="Poids du produit" name="weight" [(ngModel)]="product.weight" required />
  <label for="product-weight">Poids du produit</label>
</div>

<!-- subtitle -->
<div class="form-floating mb-3">
  <textarea class="form-control" placeholder="Sous titre du produit" id="product-subtitle" [(ngModel)]="product.subtitle" required></textarea>
  <label for="product-subtitle">Sous titre</label>
</div>

<!-- détail -->
<div class="form-floating mb-3">
  <gob-tinymce name="details" placeholder="Détails du produit" (change)="product.details=$event" [value]="product.details"></gob-tinymce>
</div>

<!-- vidéo du produit -->
<div class="form-floating mb-3">
  <input type="text" class="form-control" id="product-video" placeholder="Vidéo du produit" name="video" [(ngModel)]="product.video" />
  <label for="product-video">Vidéo du produit</label>
</div>

<!-- nombre de joueur -->
<div class="form-floating mb-3">
  <input type="number" class="form-control" id="product-players" placeholder="Nombre de joueurs" name="players" [(ngModel)]="product.players" required />
  <label for="product-players">Nombre de joueurs</label>
</div>

<!-- age -->
<div class="form-floating mb-3">
  <input type="number" class="form-control" id="product-age" placeholder="Age" name="age" [(ngModel)]="product.age" required />
  <label for="product-age">Age</label>
</div>

<!-- duration -->
<div class="form-floating mb-3">
  <input type="number" class="form-control" id="product-duration" placeholder="Durée" name="age" [(ngModel)]="product.duration" required />
  <label for="product-duration">Durée</label>
</div>