import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { RouterModule } from '@angular/router';
import { ProductListComponent } from './product-list/product-list.component';
import { PartialModule } from '../partial/partial.module';
import { UiModule } from '../../ui/ui.module';
import { FilterProductComponent } from './filter-product/filter-product.component';
import { ListCardComponent } from './list-card/list-card.component';
import { ListLineComponent } from './list-line/list-line.component';
import { ProductPageComponent } from './product-page/product-page.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { RatingComponent } from './rating/rating.component';
import { RatingFormComponent } from './rating-form/rating-form.component';
import { environment } from '@environments/environment';

@NgModule({
  declarations: [
    ProductListComponent,
    FilterProductComponent,
    ListCardComponent,
    ListLineComponent,
    ProductPageComponent,
    ProductDetailComponent,
    RatingComponent,
    RatingFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PartialModule,
    UiModule,
    RouterModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  exports: [
    ProductListComponent
  ]
})
export class ProductsModule { }
