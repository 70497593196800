import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';
import { RouteService } from '@services/route.service';
import { StockService } from '@services/stock.service';
import { UserService } from '@services/user.service';
import { Depot, Schedule } from '@models/stock';
import { Address, Pays } from '@models/user';

@Component({
  selector: 'gob-depot-form',
  templateUrl: './depot-form.component.html',
  styleUrls: ['./depot-form.component.css']
})
export class DepotFormComponent implements OnInit {
  loading: boolean=false;
  error: string;
  msg: string;
  depot: Depot=new Depot();
  address: Address=new Address();
  initialIsMain: boolean=false;
  pays: Pays[]=[];

  constructor(public route: RouteService, private router: ActivatedRoute, private stockServ: StockService, private userServ: UserService) { 
    this.loading=true;
    this.router.paramMap
      .pipe(
        switchMap(param => {
          if(param.get('id'))
            return this.stockServ.getDepot(param.get('id'));
          else
            return of(new Depot());
        }),
        tap(depot => {
          this.depot=JSON.parse(JSON.stringify(depot));
          this.initialIsMain=depot.isMain;
        }),
        switchMap(depot => {
          if(depot.ident)
            return this.stockServ.getAddress(depot.ident);
          else
            return of(new Address());
        }),
        tap(address => this.address=address),
        switchMap(() => userServ.getPays()),
        tap(pays => this.pays=pays)
      )
      .subscribe(() => this.loading=false);
  }

  ngOnInit(): void {
  }

  addSchedule(){
    this.depot.schedules.push(new Schedule());
  }

  rmSchedule(i: number){
    this.depot.schedules.splice(i, 1);
  }

  update(form: any){
    this.loading=true;
    let update: boolean=(this.depot.ident!=null);
    this.stockServ.setDepot(this.depot, this.address).subscribe({
      next: depot => {
        this.msg='Modifications enregistrées';
        if(update)
          this.depot=depot;
        else{
          this.depot=new Depot();
          this.address=new Address();
          form.reset();
        }
        this.loading=false;
      },
      error: e => {
        this.error=e;
        this.loading=false;
      }
    });
  }

  rm(){
    if(confirm('Etes vous sur de vouloir supprimé')){
      this.loading=true;
      this.stockServ.rmDepot(this.depot.ident).subscribe({
        next: () => this.route.navigate('depot-list'),
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
    }
  }
}
