import { Component, OnInit } from '@angular/core';
import { UserService } from '@services/user.service';
import { Newsletter } from '@models/user';

@Component({
  selector: 'gob-newsletter-list',
  templateUrl: './newsletter-list.component.html',
  styleUrls: ['./newsletter-list.component.css']
})
export class NewsletterListComponent implements OnInit {
  loading: boolean=true;
  newsletters: Newsletter[]=[];
  size: number=5;
  max: number=0;

  constructor(private userServ: UserService) {
    this.list(0);
  }

  ngOnInit(): void {
  }

  list(page: number){
    this.loading=true;
    this.userServ.listNewsletter(page*this.size, this.size).subscribe(news => {
      this.newsletters=news;
      if(this.newsletters.length==this.size)
        this.max=page+2;
      this.loading=false;
    });
  }

  setNewsletter(news: Newsletter){
    this.loading=true;
    this.userServ.setNewsletter(news).subscribe(() => this.loading=false);
  }

  export(){
    this.loading=true;
    this.userServ.export().subscribe(() => this.loading=false);
  }
}
