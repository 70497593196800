import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { formatNumber } from '@angular/common';
import { Product, Price } from '@models/product';
import { ProductService } from '@services/product.service';
import { CartService } from '@services/cart.service';

@Component({
  selector: 'gob-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {
  _product: Product;
  priceSelected: Price;

  @Input() 
  set product(val: Product){
    this._product=val;
    if(val.prices.length){
      this.priceSelected=val.prices[0];
      let priceParam=this.router.snapshot.queryParamMap.get('price');
      if(priceParam){
        let price=val.prices.find(p => p.ident==priceParam);
        if(price){
          this.priceSelected=price;
          this.product.images=[{name: price.img, ident: 'cover'}, ...this.product.images];
        }
      }
    }
  }

  get product(){
    return this._product;
  }

  constructor(private router: ActivatedRoute, public cart: CartService) { }

  ngOnInit(): void {
  }

  getTags(){
    let result=this.product.tags[0].name;
    this.product.tags.forEach((tag, i) => {
      if(i!=0)
        result+=', '+tag.name;
    });
    return result;
  }

  setPrice(ident: string){
    this.priceSelected=this.product.prices.find(p => p.ident==ident);
  }

  canOrder(){
    let stocks: number=this.product.stocks.reduce((acc, curent) => acc+curent.qte, 0);
    return (this.product.state!='rupture' || stocks>0);
  }

  add(input: any){
    let qte: number=input.value;
    let max: number=this.cart.maxQte(this.product, this.priceSelected);
    qte++;
    if(max!==null && qte>max)
      qte=max;
    input.value=qte;
  }

  minus(input: any){
    let qte: number=input.value;
    qte--;
    if(qte<0)
      qte=0;
    input.value=qte;
  }

  addCart(product: Product, priceSelected: Price, qte: any){
    this.cart.add(product, priceSelected, parseInt(qte));
  }

}
