<gob-form-news *ngIf="newsletter"></gob-form-news>
<div class="footer">
	<div class="footer-part-container">
		<section class="footer-part">
			<div>
				<h2>Votre compte</h2>			
			</div>
			<div>
				<a title="Informations personnelles">Informations personnelles</a><br/>
				<a title="Commandes">Commandes</a><br/>
				<a title="Mes adresses">Mes adresses</a>
			</div>
		</section>
		<section class="footer-part">
			<div>
				<h2>Sevice client</h2>			
			</div>
			<div>
				Lundi au Vendredi<br/>
				10h - 19h<br/>
				03 83 32 25 06<br/>
				<a title="contact">Contactez-nous</a>
			</div>
		</section>
		<section class="footer-part">
			<div>
				<h2>Nos plus</h2>			
			</div>
			<div>
				Envoi sous 3 jours ouvrés<br/>
				Retours gratuits sous 14 jours<br/>
				Paiement sécurisé
			</div>
		</section>
		<section class="footer-part">
			<div>
				<h2>Le Gobelin</h2>			
			</div>
			<div>
				<a title="Nous contacter">Qui sommes nous</a><br/>
				<a title="Nous contacter">Nous contacter</a><br/>
				<a title="Politique de confidentialité">Politique de confidentialité</a><br/>
				<a title="Conditions générales">Conditions générales</a>
			</div>
		</section>
	</div>
	<hr/>
	<div class="footer-paiement-container">
		<div class="footer-paiement-part">
			@2024 Caverne Du Gobelin
		</div>
		<div class="footer-paiement-part">
			<i class="fa-brands fa-cc-paypal"></i>
			<i class="fa-brands fa-cc-visa"></i>
			<i class="fa-brands fa-cc-mastercard"></i>
		</div>
		<div class="footer-paiement-part">
			<a href="https://www.facebook.com/CavduGob/" target="_blank"><i class="fa-brands fa-facebook-f"></i></a>
			<a href="https://www.instagram.com/lacavernedugobelin/?hl=fr" target="_blank"><i class="fa-brands fa-instagram"></i></a>
			<a href="https://twitter.com/dugobelin" target="_blank"><i class="fa-brands fa-twitter"></i></a>
			<a href="mailto:demandes-clients@cavernedugobelin.com" target="_blank"><i class="fa-regular fa-envelope"></i></a>
		</div>
	</div>
</div>
