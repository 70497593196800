import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StatFilter } from '@models/filter';

@Component({
  selector: 'gob-dashboard-filter',
  templateUrl: './dashboard-filter.component.html',
  styleUrls: ['./dashboard-filter.component.css']
})
export class DashboardFilterComponent implements OnInit {
  @Output() readonly search: EventEmitter<StatFilter>=new EventEmitter<StatFilter>();
  filter: StatFilter=new StatFilter();

  constructor() { }

  ngOnInit(): void {
  }

}
