<aside class="border-gradient green filter-container">
	<div class="title separator">
		Filtrer Par
		<div class="display-phone filter-drop">
			<i *ngIf="!open" (click)="open=true" class="fa-sharp fa-solid fa-chevron-down"></i>
			<i *ngIf="open" (click)="open=false" class="fa-sharp fa-solid fa-chevron-up"></i>
		</div>
	</div>

	<div [ngClass]="{open: open}" class="filters">
		<!-- prix -->
		<div class="filter">
			<div class="title">
				Prix
			</div>
			<gob-range [title]="true" symbol="€" [max]="500" [steps]="0" [minVal]="filter.priceMin" [maxVal]="filter.priceMax" (setVal)="setPrice($event)"></gob-range>
		</div>

		<!-- age -->
		<div class="filter">
			<div class="title">
				Age 
			</div>
			<gob-range [title]="true" symbol="ans" [max]="18" [steps]="3" [minVal]="filter.yearMin" [maxVal]="filter.yearMax" (setVal)="setAge($event)"></gob-range>
		</div>

		<!-- nombre de joueurs -->
		<div class="filter">
			<div class="title">
				Nombre de joueurs
			</div>
			<gob-range [title]="true" symbol="" [min]="0" [max]="18" [steps]="4" [minVal]="filter.pMin" [maxVal]="filter.pMax" (setVal)="setPlayer($event)"></gob-range>
		</div>

		<!-- Durée -->
		<div *ngIf="hasFilter('filter-time')" class="filter">
			<div class="title">
				Durée
			</div>
			<gob-range [title]="true" symbol="min" [min]="0" [max]="300" [steps]="3" [minVal]="filter.dMin" [maxVal]="filter.dMax" (setVal)="setDuree($event)"></gob-range>
		</div>

		<!-- Promo -->
		<div *ngIf="hasFilter('filter-news') || hasFilter('filter-promo')" class="filter">
			<div class="title">
				Bonnes affaires
			</div>
			<div *ngIf="hasFilter('filter-news')" class="form-check form-switch">
			  <input class="form-check-input" name="news" type="checkbox" [(ngModel)]="filter.news" id="filter-news">
			  <label class="form-check-label" for="filter-news">Nouveauté</label>
			</div>
			<div *ngIf="hasFilter('filter-promo')" class="form-check form-switch">
			  <input class="form-check-input" name="promo" type="checkbox" [(ngModel)]="filter.promo" id="filter-promo">
			  <label class="form-check-label" for="filter-promo">Promo</label>
			</div>
		</div>

		<!-- Catégorie -->
		<div *ngIf="hasFilter('filter-categ')" class="filter">
			<div class="title">
				Catégorie
			</div>
	  		<div class="form-floating mb-3">
				<select class="form-select" id="filter-categ" name="categ" [(ngModel)]="filter.categ">
	              <option [ngValue]="undefined">Selectionnez une catégorie</option>
	              <option *ngFor="let categ of categs" [ngValue]="categ.ident">{{categ.name}}</option>
	            </select>
	            <label for="filter-categ">Catégories</label>
	        </div>
		</div>

		<!-- Sous catégorie -->
		<div *ngIf="hasFilter('filter-scateg')" class="filter">
			<div class="title">
				Sous catégorie
			</div>
	  		<div class="form-floating mb-3">
				<select class="form-select" id="filter-scateg" name="scateg" [(ngModel)]="filter.scateg">
	              <option [ngValue]="undefined">Selectionnez une sous catégorie</option>
	              <option *ngFor="let scateg of scategs" [ngValue]="scateg.ident">{{scateg.name}}</option>
	            </select>
	            <label for="filter-scateg">Sous catégories</label>
	        </div>
		</div>

		<!-- Gamme -->
		<div *ngIf="hasFilter('filter-gamme')" class="filter">
			<div class="title">
				Gamme
			</div>
	  		<div class="form-floating mb-3">
				<select class="form-select" id="filter-gamme" name="gamme" [(ngModel)]="filter.gamme">
	              <option [ngValue]="undefined">Selectionnez une gamme</option>
	              <option *ngFor="let gamme of gammes" [ngValue]="gamme.ident">{{gamme.name}}</option>
	            </select>
	            <label for="filter-gamme">Gammes</label>
	        </div>
		</div>

		<!-- Marque -->
		<div *ngIf="hasFilter('filter-marque')" class="filter">
			<div class="title">
				Marque
			</div>
	  		<div class="form-floating mb-3">
				<select class="form-select" id="filter-marque" name="marque" [(ngModel)]="filter.marque" required>
	              <option [ngValue]="undefined">Selectionnez une marques</option>
	              <option *ngFor="let marque of marques" [ngValue]="marque.ident">{{marque.name}}</option>
	            </select>
	            <label for="filter-marque">Marques</label>
	        </div>
		</div>

		<!-- Tag -->
		<div *ngIf="hasFilter('filter-tag')" class="filter">
			<div class="title">
				Tag
			</div>
	  		<div class="form-floating mb-3">
				<select class="form-select" id="filter-tag" name="tag" [(ngModel)]="filter.tag" required>
	              <option [ngValue]="undefined">Selectionnez un tag</option>
	              <option *ngFor="let tag of tags" [ngValue]="tag.ident">{{tag.name}}</option>
	            </select>
	            <label for="filter-tag">Tags</label>
	        </div>
		</div>
		<button (click)="search()" class="search btn brun">
			Rechercher <i class="fa-regular fa-magnifying-glass"></i>
		</button>
	</div>
</aside>