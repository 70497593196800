<form #form="ngForm" [loading]="loading">
	<gob-message [error]="error"></gob-message>

	<!-- prénom -->
	<div class="form-floating mb-3">
	  <input type="text" class="form-control" id="address-firstname" placeholder="Prénom" name="firstname" [(ngModel)]="address.firstname" required />
	  <label for="address-firstname">Prénom</label>
	</div>

	<!-- nom -->
	<div class="form-floating mb-3">
	  <input type="text" class="form-control" id="address-lastname" placeholder="Nom" name="lastname" [(ngModel)]="address.lastname" required />
	  <label for="address-lastname">Nom</label>
	</div>

	<!-- Raison sociale -->
	<div class="form-floating mb-3">
	  <input type="text" class="form-control" id="address-sociale" placeholder="Raison sociale" name="sociale" [(ngModel)]="address.social" />
	  <label for="address-sociale">Raison sociales</label>
	</div>

	<!-- address -->
	<div class="form-floating mb-3">
	  <input type="text" class="form-control" id="address-address" placeholder="Adresse" name="address" [(ngModel)]="address.address" required />
	  <label for="address-address">Adresse</label>
	</div>

	<!-- CP -->
	<div class="form-floating mb-3">
	  <input type="text" class="form-control" id="address-cp" placeholder="Code postal" name="cp" [(ngModel)]="address.cp" required />
	  <label for="address-cp">Code postal</label>
	</div>

	<!-- ville -->
	<div class="form-floating mb-3">
	  <input type="text" class="form-control" id="address-city" placeholder="Ville" name="city" [(ngModel)]="address.city" required />
	  <label for="address-city">Ville</label>
	</div>

	<div class="form-floating mb-3">
		<select class="form-select" id="address-pays" name="pays" [(ngModel)]="address.pays" required>
		  <option *ngFor="let p of pays" [ngValue]="p.id">{{p.name}}</option>
		</select>
		<label for="address-pays">Pays</label>
	</div>

	<button (click)="setAddress(form)" type="button" class="btn green">Ajouter</button>
</form>
