import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '../../ui/ui.module';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductFormComponent } from './product-form/product-form.component';
import { ProductPageComponent } from './product-page/product-page.component';
import { ProductFilterComponent } from './product-filter/product-filter.component';
import { ProductInfoComponent } from './product-info/product-info.component';
import { ProductPriceComponent } from './product-price/product-price.component';
import { ProductClassComponent } from './product-class/product-class.component';
import { ProductStockComponent } from './product-stock/product-stock.component';



@NgModule({
  declarations: [
    ProductListComponent,
    ProductFormComponent,
    ProductPageComponent,
    ProductFilterComponent,
    ProductInfoComponent,
    ProductPriceComponent,
    ProductClassComponent,
    ProductStockComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UiModule
  ],
  exports: [
    ProductPageComponent
  ]
})
export class ProductModule { }
