import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Shipping } from '@models/shipping';

@Component({
  selector: 'gob-shipping-form',
  templateUrl: './shipping-form.component.html',
  styleUrls: ['./shipping-form.component.css']
})
export class ShippingFormComponent implements OnInit {
  @Output() readonly add: EventEmitter<Shipping>=new EventEmitter<Shipping>();
  shipping: Shipping=new Shipping();
  
  constructor() { }

  ngOnInit(): void {
  }

  valid(){
    this.add.emit(this.shipping);
    this.shipping=new Shipping();
    this.shipping.tva=20;
  }

}
