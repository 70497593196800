import { Component, OnInit } from '@angular/core';
import { Discount } from '@models/order';
import { RouteService } from '@services/route.service';
import { OrderService } from '@services/order.service';

@Component({
  selector: 'gob-promo-list',
  templateUrl: './promo-list.component.html',
  styleUrls: ['./promo-list.component.css']
})
export class PromoListComponent implements OnInit {
  loading: boolean=false;
  discounts: Discount[]=[];

  constructor(public route: RouteService, private orderServ: OrderService) { 
    this.loading=true;
    this.orderServ.getDiscounts().subscribe(discounts => {
      this.discounts=discounts;
      this.loading=false;
    });
  }

  ngOnInit(): void {
  }

  disable(ident: string){
    if(confirm('Êtes-vous sûr de vouloir supprimer ?')){
      this.loading=true;
      this.orderServ.disableDiscount(ident).subscribe(() => this.loading=false);
    }
  }
}
