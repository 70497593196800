import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class RouteService{
	constructor(private router: Router){ }

	//retourn l'uri d'une route qui porte le nom : name
	get(name: string, addRoot: boolean=true){
		let result: string=null;
		this.router.config.forEach((route: any) => {
			let path: string=this.getReccurs(name, route, null);
			if(path!=null)
				result=path;
		});

		if(addRoot)
			result='/'+result;
		return result;
	}

	getReccurs(name: string, route: any, path: string){
		let currentPath: string=((path!=null)? path+"/"+route.path : route.path);
		let result: string=null;
		if(route.data || route.children){
			if(!route.children && route.data && route.data.name==name)
				result=currentPath;
			else if(route.children){
				let final: string=null;
				route.children.forEach((child: any) => {
					if(!final)
						final=this.getReccurs(name, child, currentPath);
				});
				result=final;
			}
			else
				result=null;
		}

		return result;
	}

	//retourne la route qui porte ne nom : name
	getRoute(name: string){
		let result: any=null;
		this.router.config.forEach((route: any) => {
			if(route.data || route.children){
				if(!route.children && route.data.name==name)
					result=route;
				else if(route.children){
					route.children.forEach((children: any) => {
						if(children.data.name==name)
							result=children;
					});
				}	
			}
		});

		return result;
	}

	//méthode qui retourne l'uri avec un parse des param
	parse(name: string, params: string[]=[], addRoot: boolean=true){
		let result: string=null;
		let path: string=this.get(name, addRoot);
		if(path){
			let framgents: string[]=path.split('/:');
			params.forEach((p, i) => framgents[i+1]=p);
			result=framgents.join('/');
		}

		return result;
	}

	//méthode qui navigue vers une url
	navigate(name: string, params: string[]=[], queryParams: any={}){
		let path: string=this.parse(name, params);
		if(path)
			this.router.navigate([path], {queryParams});
	}
}