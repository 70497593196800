import { Directive, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[scrollTo]'
})
export class ScrollDirective{
	@Input() scrollTo: string;

	constructor(){}

	@HostListener('click')
	scroll(){
		let to: number=0;
		if(this.scrollTo){
			const element=document.getElementById(this.scrollTo);
	    if(element){
	    	//const yOffset=-80;  // Définir le décalage ici (vers le haut)
		    //const yPosition=element.getBoundingClientRect().top + window.pageYOffset + yOffset;
	    	to: element.offsetTop-document.getElementById("menu-contrainer").offsetHeight;
			}
		}
		window.scrollTo({
	  	top: to,
	  	behavior: 'smooth'
	  });
	}
}