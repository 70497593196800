import { Component, OnInit, Input } from '@angular/core';
import { Rating } from '@models/product';

@Component({
  selector: 'gob-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css']
})
export class RatingComponent implements OnInit {
  @Input() rating: Rating;

  constructor() { }

  ngOnInit(): void {
  }

}
