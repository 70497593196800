<div [loading]="loading" class="accordion" id="refund-list">
  <div *ngFor="let refund of order.refunds; index as i" class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+i">
        Retour du {{refund.dCreate | date:'d, MMMM yyyy'}} - {{refund.amountTTC}}€
      </button>
    </h2>
    <div id="collapse{{i}}" class="accordion-collapse collapse" data-bs-parent="#refund-list">
    	<div class="accordion-body">
				<div class="table-responsive">
					<table class="table">
					    <thead>
					      <tr>
					        <th scope="col">Ref</th>
					        <th scope="col">Nom</th>
					        <th scope="col">Qte</th>
					        <th scope="col">Total</th>
					      </tr>
					    </thead>
					    <tbody class="table-group-divider">
					      <tr *ngFor="let transaction of getRefunded(refund.ident)">
					        <td>{{transaction.ref}}</td>
					        <td>{{transaction.name}}</td>
					        <td>{{transaction.qte}}</td>
					        <td>{{transaction.amountTTC | formatPrice}}€</td>
					      </tr>
					      <tr>
					      	<td colspan="4"></td>
					      </tr>
					      <tr>
					      	<td colspan="2"></td>
					      	<td><b>Total HT</b></td>
					      	<td>{{refund.amountHT | formatPrice}}€</td>
					      </tr>
					      <tr>
					      	<td colspan="2"></td>
					      	<td><b>Total TTC</b></td>
					      	<td>{{refund.amountTTC | formatPrice}}€</td>
					      </tr>
					    </tbody>
					</table>
				</div>
      </div>
    </div>
  </div>
</div>
