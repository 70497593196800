import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '../../ui/ui.module';
import { PartialModule } from '@components/page/partial/partial.module';
import { CartComponent } from './cart/cart.component';
import { CartInfoComponent } from './cart-info/cart-info.component';
import { CartShipperComponent } from './cart-shipper/cart-shipper.component';
import { PaymentComponent } from './payment/payment.component';
import { KoPaymentComponent } from './ko-payment/ko-payment.component';
import { OkPaymentComponent } from './ok-payment/ok-payment.component';

@NgModule({
  declarations: [
    CartComponent,
    CartInfoComponent,
    CartShipperComponent,
    PaymentComponent,
    KoPaymentComponent,
    OkPaymentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UiModule,
    RouterModule,
    PartialModule
  ],
  exports: [
    CartComponent,
    CartInfoComponent,
    PaymentComponent,
    KoPaymentComponent,
    OkPaymentComponent
  ]
})
export class OrderModule { }
