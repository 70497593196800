import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { tap, switchMap, map, shareReplay } from 'rxjs/operators';
import { ApiService } from './api.service';
import { Discount, StateOrder, Order, RefundRequest, Refund } from '@models/order';
import { MoneticoForm } from '@models/paiement';
import { OrderFilter } from '@models/filter';

@Injectable({
	providedIn: 'root'
})
export class OrderService{
	private readonly statesObs: Observable<StateOrder[]>=this.getStatesObs(false);
	private readonly statesObsAdm: Observable<StateOrder[]>=this.getStatesObs(true);
	private discounts: Discount[];
	private states: StateOrder[];

	constructor(private api: ApiService){ }

	getDiscounts(){
		if(this.discounts)
			return of(this.discounts);
		return this.api.get<Discount[]>('/discounts')
			.pipe(
				tap(discounts => this.discounts=discounts)
			);
	}

	getDiscount(ident: string){
		if(this.discounts)
			return of(this.discounts.find(d => d.ident==ident));
		else
			return this.getDiscounts()
				.pipe(
					map(discounts => this.discounts.find(d => d.ident==ident))
				);
	}

	getDiscountCode(code: string){
		return this.api.get<Discount>(`/discounts/${code}`);
	}

	setDiscount(discount: Discount){
		let update: boolean=(discount.ident!=null);
		let url: string='/discounts';
		if(update)
			url+='/'+discount.ident;
		return this.api.post<Discount>(url, discount)
			.pipe(
				tap(d => this.replaceDiscount(discount, update))
			);
	}

	replaceDiscount(discount: Discount, update: boolean){
		if(this.discounts){
			if(update)
				this.discounts.splice(this.discounts.findIndex(d => d.ident==discount.ident), 1, discount);
			else
				this.discounts.push(discount);
		}
	}

	disableDiscount(ident: string){
		return this.api.get<Discount>(`/discounts/${ident}/disable`)
			.pipe(
				tap(d => {
					if(this.discounts)
						this.discounts.splice(this.discounts.findIndex(d => d.ident==ident), 1);
				})
			);
	}

	getStatesObs(admin: boolean){
		return this.api.get<StateOrder[]>('/orders/states', {admin})
			.pipe(
				shareReplay(1),
				tap(states => this.states=states)
			);
	}

	getStates(admin: boolean=false){
		if(this.states)
			return of(this.states);
		else if(admin)
			return this.statesObsAdm;
		else
			return this.statesObs;
	}

	getState(ident: string, admin: boolean=false){
		return this.getStates(admin)
			.pipe(
				map((states: StateOrder[]) => states.find(s => s.ident==ident))
			);
	}

	setState(state: StateOrder){
		let update: boolean=(state.ident!=null);
		let url: string='/orders/states';
		if(update)
			url+="/"+state.ident;
		return this.api.post<StateOrder>(url, state)
			.pipe(
				tap(state => this.replaceState(state, update))
			);
	}

	replaceState(state: StateOrder, update: boolean){
		if(this.states){
			if(!update)
				this.states.push(state);
			else
				this.states.splice(this.states.findIndex(s => s.ident==state.ident), 1, state);
		}
	}

	rmState(ident: string){
		return this.api.delete(`/orders/states/${ident}`)
			.pipe(
				tap(() => {
					if(this.states)
						this.states.splice(this.states.findIndex(s => s.ident==ident), 1);
				})
			);
	}

	setOrder(order: Order){
		return this.api.post<Order>('/orders/complete', order);
	}

	getOrder(ident: string){
		return this.api.get<Order>(`/orders/${ident}`);
	}

	search(admin: boolean, filter: OrderFilter, start: number, length: number){
		let params: any={...filter, start, length}
		params=JSON.parse(JSON.stringify(filter, (key, value) => ((value===null || value=='') ? undefined : value)));
		let url: string='/my-orders';
		if(admin)
			url='/orders';
		return this.api.get<Order>(url, params);
	}

	updateOrder(order: Order){
		return this.api.post<Order>(`/orders/${order.ident}`, order);
	}

	refund(order: string, request: RefundRequest){
		return this.api.post<Refund>(`/refunds/${order}`, request);
	}

	getInvoice(order: string, admin: boolean){
		let url: string=`/my-invoices/${order}`;
		if(admin)
			url=`/invoices/${order}`;
		return this.api.openGetPDF(url);
	}
}

@Injectable({
	providedIn: 'root'
})
export class PaymentService{
	constructor(private api: ApiService){ }

	getMoneticoForm(order: string){
		return this.api.get<any>(`/payments/${order}/monetico/form`)
			.pipe(
				map(elements => elements.reduce((acc: any, item: any) => {
				    acc[item.name]=item.value;
				    return acc;
				  }, new MoneticoForm()))
			);
	}

	execPaypal(order: string){
		return this.api.get<any>(`/payments/${order}/paypal`);
	}
}