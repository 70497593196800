import { Injectable, Inject,  forwardRef} from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { RouteService } from '@services/route.service';
import { UserService } from '@services/user.service';

@Injectable({
	providedIn: 'root'
})
export class ConnectedGuard implements CanActivate, CanActivateChild{
	constructor(private router: Router, private routes: RouteService, private userServ: UserService){}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree{
		return (this.userServ.isConnected() || this.router.parseUrl(this.routes.get('login')));
	}
	
	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree{
		return (this.userServ.isConnected() || this.router.parseUrl(this.routes.get('login')));
	}
}