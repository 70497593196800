import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map, catchError, tap } from 'rxjs/operators';



@Injectable({
	providedIn: 'root'
})
export class ApiService{

	constructor(private http: HttpClient){ }

	static toFormData(model: any, formData: FormData=new FormData(), parentKey: string=''){
	  for(const key in model){
	    if(model.hasOwnProperty(key) && model[key]!==null && model[key]!==undefined){
	      const fullKey=((parentKey)? `${parentKey}[${key}]` : key); // clé imbriquée

	      if(model[key] instanceof File) {
	        // Si c'est un fichier, on l'ajoute directement à FormData
	        formData.append(fullKey, model[key]);
	      }else if(Array.isArray(model[key])){
	      	if (typeof model[key][0]==='string') {
	          model[key].forEach((item: string, index: number) => {
	            formData.append(`${fullKey}[${index}]`, item);
	          });
	        }
	        else{
		        // Si c'est un tableau, on parcourt chaque élément du tableau de manière récursive
		        model[key].forEach((item: any, index: number) => {
		          ApiService.toFormData(item, formData, `${fullKey}[${index}]`);
		        });	
	        }
	      }else if(typeof model[key]==='object' && model[key]!==null){
	        // Si c'est un objet, on appelle la fonction récursive pour les objets imbriqués
	        ApiService.toFormData(model[key], formData, fullKey);
	      }else{
	        // Sinon, c'est une propriété simple, on l'ajoute directement
	        formData.append(fullKey, model[key]);
	      }
	    }
	  }
	  return formData;
	}

	getOptions(params: any){
		return {
			params: params
		};
	}

	get<T>(url: string, params: any=null){
		return this.http.get<ResponseApi>(environment.servicesUrl+url, this.getOptions(params))
			.pipe(
				catchError(error => { 
					if(error.error.data)
						throw error.error.data;
					throw error.error.error;
				}),
				map(data => data.data)
			);
	}

	post<T>(url: string, data: any, params: any=null){
		return this.http.post<ResponseApi>(environment.servicesUrl+url, data, this.getOptions(params))
			.pipe(
				catchError(error => { 
					if(error.error.data)
						throw error.error;
					throw error.error.error;
				}),
				map(data => data.data)
			);
	}

	delete<T>(url: string, params: any=null){
		return this.http.delete<ResponseApi>(environment.servicesUrl+url, this.getOptions(params))
			.pipe(
				catchError(error => { 
					if(error.error.data)
						throw error.error.data;
					throw error.error.error;
				}),
				map(data => data.data)
			);
	}

	getFile(url: string, params: any=null){
		return this.http.get(environment.servicesUrl+url, {params, responseType: 'blob'})
			.pipe(
				catchError(error => { 
					if(error.error.data)
						throw error.error.data;
					throw error.error.error;
				})
			);
	}

	getCSVFile(url: string, params: any=null){
	  	return this.getFile(url, params)
	  		.pipe(
	  			tap(response => this.download(response))
	  		);
	}

	download(response: any, name: string='export.csv'){
		const a=document.createElement('a');
		const objectUrl=URL.createObjectURL(response);
		a.href=objectUrl;
		a.download='export.csv';
		a.click();
		URL.revokeObjectURL(objectUrl);
	}

	openGetPDF(url: string, params: any=null, blank: boolean=true){
		return this.getFile(url, params)
			.pipe(
				tap(response => this.openFile(response, blank))
			);
	}

	openFile(response: any, blank: boolean=true, type: string='application/pdf'){
      const blob=new Blob([response], {type});
      const url=window.URL.createObjectURL(blob);

      // Ouvrir l'URL dans un nouvel onglet
      if(blank)
	      window.open(url, '_blank');

      //libération de l'espace mémoire
      window.URL.revokeObjectURL(url);
  	}
}

export interface ResponseApi{
	msg?: string;
	error?: string;
	data: any;
}