import { Component, OnInit } from '@angular/core';
import { Shipper, Shipping } from '@models/shipping';
import { ShippingService } from '@services/shipping.service';
import { RouteService } from '@services/route.service';

@Component({
  selector: 'gob-shipper-list',
  templateUrl: './shipper-list.component.html',
  styleUrls: ['./shipper-list.component.css']
})
export class ShipperListComponent implements OnInit {
  loading: boolean=false;
  shippers: Shipper[];

  constructor(private shipServ: ShippingService, public route: RouteService) {
    this.loading=true;
    this.shipServ.getShippers(true).subscribe(shippers => {
      this.shippers=shippers;
      this.loading=false;
    });
  }

  ngOnInit(): void {
  }

  getMaxWeight(shipper: Shipper){
    let weight: string;
    if(shipper.shippings){
      let max: Shipping=shipper.shippings.reduce((prev, current) => {
        let max: Shipping=current;
        if(!prev.weightMax || (current.weightMax!=null && prev.weightMax>current.weightMax))
          max=prev;
        return max;
      });

      if(max.weightMax)
        weight=`${max.weightMax}kg`;
      else
        weight='Illimité';
    }

    return weight;
  }

  rm(ident: string){
    if(confirm('Etes vous sur de vouloir supprimer ?')){
      this.loading=true;
      this.shipServ.rmShipper(ident).subscribe(() => this.loading=false);
    }
  }

}
