import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';
import { GlobalService } from '@services/global.service';
import { ProductFilter } from '@models/filter';
import { Categ, SCateg, Gamme, Marque, Tag } from '@models/product';

@Component({
  selector: 'gob-filter-product',
  templateUrl: './filter-product.component.html',
  styleUrls: ['./filter-product.component.css']
})
export class FilterProductComponent implements OnInit {
  @Output('filter') readonly filterEvent: EventEmitter<ProductFilter>=new EventEmitter<ProductFilter>();
  open: boolean=false;
  filters: string[]=[];       // liste des filtres actifs de la page produits
  filter: ProductFilter=new ProductFilter();
  categs: Categ[]=[];
  scategs: SCateg[]=[];
  gammes: Gamme[]=[];
  marques: Marque[]=[];
  tags: Tag[]=[];
  
  constructor(private router: ActivatedRoute, private gServ: GlobalService) {
    this.router.paramMap
      .pipe(
        switchMap(params => {
          if(params.get('type')){
            let obs: Observable<any>=null;
            switch(params.get('type')){
              case 'categ':
                obs=this.gServ.getCategs();
                break;
              case 'scateg':
                obs=this.gServ.getSCategs();
                break;
              case 'gamme':
                obs=this.gServ.getGammes();
                break;
              case 'marque':
                obs=this.gServ.getMarques();
                break;
              case 'tag':
                obs=this.gServ.getTags();
                break;
              default: 
                break;
            }
            return obs.pipe(
              map(elements => elements.find((e: any) => e.ident==params.get('id'))),
              tap(element => this.filters=element.filters),
              switchMap(() => this.gServ.getGlobalFilters(params.get('type'), params.get('id')))
            );
          }
          else 
            return of(null);
        })
      )
      .subscribe(elements => {
        this.categs=elements.categs;
        this.scategs=elements.scategs;
        this.gammes=elements.gammes;
        this.marques=elements.marques;
        this.tags=elements.tags;
      });
  }

  ngOnInit(): void {
  }

  hasFilter(ident: string){
    return this.filters.find(filter => filter==ident);
  }

  search(){
    this.filterEvent.emit(this.filter);
  }

  setAge(event: any){
    this.filter.yearMin=event.min;
    this.filter.yearMax=event.max;
  }

  setPrice(event: any){
    this.filter.priceMin=event.min;
    this.filter.priceMax=event.max;
  }

  setDuree(event: any){
    this.filter.dMin=event.min;
    this.filter.dMax=event.max;
  }

  setPlayer(event: any){
    this.filter.pMin=event.min;
    this.filter.pMax=event.max;
  }
}
