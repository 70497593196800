import { Component, OnInit, Input } from '@angular/core';
import { Animation } from '@models/animation';

@Component({
  selector: 'gob-anim-list',
  templateUrl: './anim-list.component.html',
  styleUrls: ['./anim-list.component.css']
})
export class AnimListComponent implements OnInit {
  @Input() animations: Animation[];
  ind: number=0;

  constructor() { }

  ngOnInit(): void {
  }

  getStyle(){
    let style: any={
      width: `${this.animations.length*50}%`,
      marginLeft: `${-this.ind*50}%`
    }

    let styleMobile: any={
      width: `${this.animations.length*100}%`,
      marginLeft: `${-this.ind*100}%`
    }

    if(window.innerWidth>667)
      return style;
    else 
      return styleMobile
  }

  prev(){
    if(this.ind>0)
      this.ind--;
  }

  next(){
    if(this.ind+1!=this.animations.length)
      this.ind++;
  }

}
