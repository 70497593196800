import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GlobalService } from '@services/global.service';
import { Categ, SCateg, Gamme, Marque, Banner, Tag} from '@models/product';
import { ProductFilter } from '@models/filter';

@Component({
  selector: 'gob-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.css']
})
export class ProductFilterComponent implements OnInit {
  @Output() readonly search: EventEmitter<ProductFilter>=new EventEmitter<ProductFilter>();
  categs: Categ[]=[];
  scategs: SCateg[]=[];
  gammes: Gamme[]=[];
  marques: Marque[]=[];
  banners: Banner[]=[];
  tags: Tag[]=[];
  filter: ProductFilter;

  constructor(private gServ: GlobalService) {
    this.filter=new ProductFilter();
    this.gServ.getClassification().subscribe(classes => {
      this.categs=classes.categs;
      this.scategs=classes.scategs;
      this.gammes=classes.gammes;
      this.marques=classes.marques;
      this.tags=classes.tags;
      this.banners=classes.banners;
    });
  }

  ngOnInit(): void {
  }

}
