<div class="anim-container">
	<div class="animations" [ngStyle]="getStyle()">
		<div *ngFor="let animation of animations" class="animation">
			<div class="img-container">
				<img [src]="animation.img" alt="{{animation.title}}"/>
			</div>
			<div class="anim-date">
				Le {{animation.date | date:'dd/MM/yyyy'}} à {{animation.date | date:'HH:mm'}}
			</div>
			<h3 class="title">{{animation.title}}</h3>
			<div class="subtitle truncate">
				{{animation.subtitle}}
			</div>
			<div class="button">			
				<a [href]="animation.url" [title]="animation.title">Voir</a>
			</div>
		</div>
	</div>
	<div class="arrow-container">
		<a class="arrow" title="precedent" (click)="prev()"><i class="border-gradient-circle fa-solid fa-chevron-left"></i></a>
	</div>
	<div class="arrow-container right">
		<a class="arrow" title="suivant" (click)="next()"><i class="border-gradient-circle fa-solid fa-chevron-right"></i></a>
	</div>
</div>