<div class="block form-admin">
	<h1 *ngIf="!admin" class="admin">Mes informations</h1>
	<h1 *ngIf="admin" class="admin">Utilisateur : {{user.name}} {{user.lastname}}</h1>
	<div class="form-content" [loading]="loading">
		<form (ngSubmit)="update()" #userForm="ngForm">
			<gob-back *ngIf="admin" [url]="route.get('user-list')"></gob-back>
			<gob-message [error]="error" [msg]="msg"></gob-message>

			<!-- nom -->
			<div class="form-floating mb-3">
			  <input type="text" class="form-control" id="log-name" placeholder="Nom" name="lastname" [(ngModel)]="user.lastname" required />
			  <label for="log-name">Nom</label>
			</div>

			<!-- prénom -->
			<div class="form-floating mb-3">
			  <input type="text" class="form-control" id="log-lastname" placeholder="Prénom" name="name" [(ngModel)]="user.name" required />
			  <label for="log-lastname">Prénom</label>
			</div>

			<!-- date de naissance -->
			<div class="form-floating mb-3">
			  <input type="date" class="form-control" id="log-birth" placeholder="Date de naissance" name="birth" [(ngModel)]="user.birth" required />
			  <label for="log-birth">Date de naissance</label>
			</div>

			<!-- email -->
			<div class="form-floating mb-3">
			  <input type="email" class="form-control" id="log-mail" placeholder="E-mail" name="email" [(ngModel)]="user.email" required email />
			  <label for="log-mail">E-mail</label>
			</div>

			<!-- pass -->
			<div class="form-floating mb-3">
			  <input type="password" class="form-control" id="log-pass" placeholder="Mot de passe" name="pass" [(ngModel)]="user.pass" />
			  <label for="log-pass">Mot de passe</label>
			</div>

			<!-- tel -->
			<div class="form-floating mb-3">
			  <input type="tel" class="form-control" id="log-tel" placeholder="Téléphone" name="phone" [(ngModel)]="user.phone" required />
			  <label for="log-tel">Téléphone</label>
			</div>
			<button scrollTo type="submit" scrollTo class="btn green" [disabled]="userForm.invalid">Valider</button>
		</form>
		<div class="form-admin-right">
			<!-- Group et localisation -->
			<div class="card">
			  <div class="card-header">
			    <i class="fa-light fa-users-between-lines"></i> {{((admin)? 'Compte utilisateur' : 'Mon compte')}}
			  </div>
			  <div *ngIf="!admin && user.groupe" class="card-body">
			  	<div class="card-title">Mon groupe</div>
				{{group?.name}}
			  </div>
			  <div *ngIf="admin" class="card-body">
			      <div class="form-floating mb-3">
			    	<select class="form-select" id="user-group" name="group" [(ngModel)]="user.groupe">
			          <option [ngValue]="null">Selectionnez un groupe</option>
			          <option *ngFor="let g of groups" [ngValue]="g.ident">{{g.name}}</option>
			        </select>
			        <label for="user-group">Groupe client</label>
			      </div>
			  </div>
			  <div class="card-body">
			  	<div class="card-title">{{((admin)? 'Adresses' : 'Mon adresses')}}</div>
			  	<a *ngIf="!admin" [routerLink]="route.get('my-addresses')">Voir mes adresses</a>
			  	<a *ngIf="admin" [routerLink]="route.parse('user-addresses', [user.ident])">Voir les adresses</a>
			  </div>
			</div>


			<!-- droit -->
			<div *ngIf="(user.rights.length && user.roles.length) || admin" class="card">
			  <div class="card-header">
			    <i class="fa-sharp fa-light fa-handshake"></i> {{((admin)? 'Authorisations' : 'Mes authorisations')}}
			  </div>
			  <div *ngIf="admin" class="card-body">
					<div *ngIf="getRoleList().length" class="input-group mb-3">
						<div class="form-floating">
						  <select class="form-select" id="role-right" #roleItem>
						    <option *ngFor="let role of getRoleList()" value="{{role.ident}}">{{role.name}}</option>
						  </select>
						  <label for="role-right">Ajouter un rôle</label>
						</div>
						<button (click)="user.roles.push(roleItem.value)" class="input-group-text brun">
							<i class="fa-solid fa-plus"></i>
					  	</button>
					</div>
					<div *ngIf="!getRoleList().length">
						Tous les rôles ont été ajoutés
					</div>
			  </div>
			  <div *ngIf="user.roles.length" class="card-body">
			  	<div class="card-title">{{((admin)? 'Rôles' : 'Mes rôles')}}</div>
			  		<a *ngIf="!admin">
						<span *ngFor="let role of user.roles" class="text-box">{{findRole(role)?.name}}</span> 
					</a>
					<a *ngIf="admin">
						<span (click)="rmRole(role)" *ngFor="let role of user.roles" class="text-box close">{{findRole(role)?.name}}</span> 
					</a>
			  </div>
			  <div *ngIf="admin && !user.roles.length" class="card-body">
			  	<div class="card-title">Aucun rôle</div>
			  </div>
			  <div *ngIf="getRightList().length" class="card-body">
			  	<div class="card-title">{{((admin)? 'Droits' : 'Mes droits')}}</div>
				<!-- <span *ngFor="let right of user.rights" class="text-box">{{right}}</span> -->
			  	<ul>
			  		<li *ngFor="let right of getRightList()">{{right?.libelle}}</li>
			  	</ul>
			  </div>
			  <div *ngIf="admin && !getRightList().length" class="card-body">
			  	<div class="card-title">Aucun droit</div>
			  </div>
			</div>
		</div>
	</div>
</div>
