import { Component, OnInit, Input } from '@angular/core';
import { Product } from '@models/product';
import { environment } from '@environments/environment';

@Component({
  selector: 'gob-diapo',
  templateUrl: './diapo.component.html',
  styleUrls: ['./diapo.component.css']
})
export class DiapoComponent implements OnInit {
  @Input() product: Product;
  indImg: number=0;

  constructor() { }

  ngOnInit(): void {
  }

  getImage(ind: number){
    return `${environment.imgUrl}/product/${this.product.images[ind].name}`;
  }

}
