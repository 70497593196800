import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '../../ui/ui.module';
import { ShipperPageComponent } from './shipper-page/shipper-page.component';
import { ShipperListComponent } from './shipper-list/shipper-list.component';
import { ShipperFormComponent } from './shipper-form/shipper-form.component';
import { ShippingFormComponent } from './shipping-form/shipping-form.component';

@NgModule({
  declarations: [
    ShipperPageComponent,
    ShipperListComponent,
    ShipperFormComponent,
    ShippingFormComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    UiModule
  ],
  exports: [
    ShipperPageComponent
  ]
})
export class ShippingModule { }
