import { Component, OnInit, Input } from '@angular/core';
import { formatNumber } from '@angular/common';
import { RouteService } from '@services/route.service';
import { CartService } from '@services/cart.service';
import { Product, Price } from '@models/product';
import { environment } from '@environments/environment';
import { GetPricesPipe } from '@pipes/price.pipe';

@Component({
  selector: 'gob-list-line',
  templateUrl: './list-line.component.html',
  styleUrls: ['./list-line.component.css']
})
export class ListLineComponent implements OnInit {
  @Input() products: Product[];

  constructor(public route: RouteService, private gPrice: GetPricesPipe, private cart: CartService) { }

  ngOnInit(): void {
  }

  getImage(product: Product){
    return `${environment.imgUrl}/product/${product.images[0].name}`;
  }

  getQueryParam(product: Product, price: Price){
    if(product.cover)
      return {price: price.ident};
    else
      return {};
  }

  addCart(product: Product){
    this.cart.add(product, this.gPrice.transform(product, 0), 1);
  }
}
