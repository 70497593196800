<div class="list-container" [loading]="loading">
  <gob-message [error]="error" [msg]="msg"></gob-message>
  <gob-product-filter (search)="search($event, 0)"></gob-product-filter>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Nom</th>
          <th scope="col">Ref.</th>
          <th scope="col">Catégorie</th>
          <th scope="col">Etat</th>
          <th scope="col"><i class="green fa-solid fa-pen-to-square"></i></th>
          <th scope="col"><i class="green fad fa-clone"></i></th>
          <th scope="col"><i class="red fa-solid fa-trash"></i></th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr *ngFor="let product of products">
          <td>
            <div *ngIf="product.images.length" class="img-container">
              <img [src]="getImage(product)" class="img-product"/>
            </div>
          </td>
          <td>{{product.name}}</td>
          <td>{{product.ref}}</td>
          <td>{{getCateg(product)?.name}}</td>
          <td>{{product.state | titlecase}}</td>
          <td>
            <a href="" [routerLink]="route.parse('product-set', [product.ident])"><i class="green fa-solid fa-pen-to-square"></i></a>
          </td>
          <td>
            <a href="" [routerLink]="route.parse('product-set', [product.ident])" [queryParams]="{duplicate: true}"><i class="green fad fa-clone"></i></a>
          </td>
          <td>
          	<a (click)="rm(product.ident)"><i class="red fa-solid fa-trash"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <gob-pagination [max]="max" (change)="next($event)"></gob-pagination>
</div>