<div [loading]="loading">
	<gob-back [url]="route.get('order-state-list')"></gob-back>
	<div class="form-content">
		<form class="form-admin-left" #form="ngForm">
			<gob-message [error]="error" [msg]="msg"></gob-message>
			<!-- nom de l'état -->
			<div class="form-floating mb-3">
			  <input type="text" class="form-control" id="state-name" placeholder="Nom de l'état" name="name" [(ngModel)]="state.name" required />
			  <label for="state-name">Nom de l'état</label>
			</div>

			<!-- mail envoyé au client -->
			<div class="mb-3">
				<gob-tinymce [height]="300" placeholder="Mail envoyé au client" (change)="state.mail=$event" [value]="state.mail"></gob-tinymce>
			</div>

			<button scrollTo (click)="update()" class="btn green" [disabled]="form.invalid">{{((state.ident)? "Modifier" : "Créer")}}</button>
			<button (click)="rm(state.ident)" *ngIf="state.ident && state.editable" class="btn btn-danger">Supprimer</button>
		</form>
	</div>
</div>