<article class="block">
	<div class="header-selection">
		<header>
			<h2 class="title"> Notre <span class="go-line">Sélection</span></h2>
		</header>
		<div class="selection-nav">
			<div class="categ-container">
				<a [ngClass]="{selected: tabActive=='news'}" (click)="setTab('news')" title="Nouveautés">Nouveautés</a>
				<a [ngClass]="{selected: tabActive=='promos'}" (click)="setTab('promos')" title="Promos">Promos</a>
				<a [ngClass]="{selected: tabActive=='pos3'}" (click)="setTab('pos3')" title="Promos">{{getPos3Name()}}</a>
				<a [ngClass]="{selected: tabActive=='pos4'}" (click)="setTab('pos4')" title="Promos">{{getPos4Name()}}</a>
			</div>
			<div class="arrow-container">
				<a title="precedent" (click)="prev()"><i class="border-gradient-circle fa-solid fa-chevron-left"></i></a>
				<a title="suivant" (click)="next()"><i class="border-gradient-circle fa-solid fa-chevron-right"></i></a>
			</div>
		</div>
	</div>

	<div [ngSwitch]="tabActive" class="products-container">
		<gob-product-selection *ngSwitchCase="'news'" [products]="news" [ind]="ind"></gob-product-selection>
		<gob-product-selection *ngSwitchCase="'promos'" [products]="promos" [ind]="ind"></gob-product-selection>
		<gob-product-selection *ngSwitchCase="'pos3'" [products]="pos3" [ind]="ind"></gob-product-selection>
		<gob-product-selection *ngSwitchCase="'pos4'" [products]="pos4" [ind]="ind"></gob-product-selection>
	</div>
</article>