import { Component, OnInit, AfterViewChecked, Input, Output, EventEmitter } from '@angular/core';
import { ScriptService } from '@services/script.service';

declare var tinymce: any;

@Component({
  selector: 'gob-tinymce',
  templateUrl: './tinymce.component.html',
  styleUrls: ['./tinymce.component.css']
})
export class TinymceComponent implements OnInit {
  private static ind: number=0;
  @Input() height: number=500;
  @Input() placeholder: string;
  @Input() name: string;
  @Output() readonly change: EventEmitter<string>=new EventEmitter<string>();
  ind: number;
  editor: any;
  _value: string;

  @Input()
  set value(value: string){
    this._value=value;
    if(this.editor)
      this.editor.setContent(this.value);
  }

  get value(){
    return this._value;
  }


  constructor(private scriptServ: ScriptService) { 
    this.ind=TinymceComponent.ind;
    TinymceComponent.ind++;
    this.name=`tiny${this.ind}`;
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked(){
    this.init();
  }

  init(){
    //load tinymce
    this.scriptServ.loadScript('tinymce', 'lib/tinymce/tinymce.min.js')
      .subscribe(() => tinymce.init({
        selector: `#tiny-${this.ind}`,
        height: this.height,
        placeholder: this.placeholder,
        menubar: false,
        paste_as_text: true,
        toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
        setup: (editor: any) => {
          editor.on('init', () => {
            if(this.value)
              editor.setContent(this.value);
          });

          editor.on('Change KeyUp Undo Redo', () => {
            this.change.emit(editor.getContent());
          });
        }
      })
    );
  }

}
