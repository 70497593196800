import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '@environments/environment';
import { ConnectPageComponent } from './connect-page/connect-page.component';
import { SignFormComponent } from './sign-form/sign-form.component';
import { LogFormComponent } from './log-form/log-form.component';
import { ForgotFormComponent } from './forgot-form/forgot-form.component';
import { UiModule } from '../../ui/ui.module';



@NgModule({
  declarations: [
    ConnectPageComponent,
    SignFormComponent,
    LogFormComponent,
    ForgotFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UiModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  exports: [
    ConnectPageComponent
  ]
})
export class ConnectModule { }
