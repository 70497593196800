import { Stock } from '@models/stock';
import { Image } from '@models/image';

export class Categ{
  ident: string;
  name: string;
  filters: string[]=[];
}

export class SCateg{
  ident: string;
  name: string;
  filters: string[]=[];
}

export class Gamme{
  ident: string;
  name: string;
  filters: string[]=[];
}

export class Marque{
  ident: string;
  name: string;
  filters: string[]=[];
}

export class Tag{
  ident: string;
  name: string;
  filters: string[]=[];
}

export class Banner{
  ident?: string;
  name: string;
  color: string='#ffffff';
  start?: string;
  end?: string;
}

export class Filter{
  ident: string;
  name: string;
}

export class Rating{
  ident?: string;
  customer?: string;
  product?: string;
  date?: string;
  rate?: number;
  review?: string;
  state?: 'review' | 'actif' | 'supprimé'='review';
}

export class Price {
  ident: string;
  product: string;
  qte: number=1;
  isRemise: boolean;
  group: string=null;
  prix: number=0;
  start: string;
  end: string;
  img: string;
  priceImageFile: File;
  rmImage: boolean=false;
}

export class Product{
  //img: string;
  //subtitle: string;
  ident: string;
  name: string;
  ref: string;
  categ: string=null;
  scateg: string=null;
  gamme: string=null;
  marque: string=null;
  isNew: boolean=false;
  isPromo: boolean=false;
  barrecode: string;
  prixAchat: number;
  tva: number;
  weight: number;
  details: string;
  subtitle: string;
  video?: string;
  players: number;
  duration?: number;
  age: number;
  lowStock: number;
  isDraft: boolean=false;
  isRupture: boolean=false;
  dispoFrns: number;
  limitOrder: number;
  duplicateImage: string;
  state: 'actif' | 'draft' | 'rupture' | 'supprimé'='draft';
  stars: number;
  images: Image[]=[];
  cover?: string;
  tags: Tag[]=[];
  ratings: Rating[]=[];
  prices: Price[]=[];
  banners: Banner[]=[];
  stocks: Stock[]=[];
  linked?: Product[];
  imageFiles: File[]=[];
  rmImages: string[]=[];
}

export class ProductSelected{
  news: Product[];
  promos: Product[];
  pos3: Product[];
  pos4: Product[];
}