<form (ngSubmit)="search.emit(filter)" #form="ngForm">
	<div class="row g-3">
		<!-- prénom -->
	  	<div class="col-sm">
			<div class="form-floating mb-3 nsb">
			  <input type="text" class="form-control" id="user-filter-name" placeholder="Prénom" name="name" [(ngModel)]="filter.name"/>
			  <label for="user-filter-name">Prénom</label>
			</div>
		</div>

		<!-- nom -->
	  	<div class="col-sm">
			<div class="form-floating mb-3 nsb">
			  <input type="text" class="form-control" id="user-filter-lastname" placeholder="Nom" name="last" [(ngModel)]="filter.lastname"/>
			  <label for="user-filter-lastname">Nom</label>
			</div>
		</div>

		<!-- mail -->
	  	<div class="col-sm">
			<div class="form-floating mb-3">
			  <input type="mail" class="form-control" id="user-filter-mail" placeholder="Prénom" name="email" [(ngModel)]="filter.email"/>
			  <label for="user-filter-mail">E-mail</label>
			</div>
		</div>
	</div>

	<div class="row g-3">
		<!-- groupe client -->
	  	<div class="col-sm">
	      <div class="form-floating mb-3 nsb">
	    	<select class="form-select" id="user-filter-group" name="group" [(ngModel)]="filter.group">
	          <option [ngValue]="null || undefined">Tous les groupes</option>
	          <option *ngFor="let group of groups" [ngValue]="group.ident">{{group.name}}</option>
	        </select>
	        <label for="user-filter-group">Groupe client</label>
	      </div>
		</div>

		<!-- role -->
	  	<div class="col-sm">
	      <div class="form-floating mb-3 nsb">
	    	<select class="form-select" id="user-filter-role" name="role" [(ngModel)]="filter.role">
	          <option [ngValue]="null || undefined">Tous les rôles</option>
	          <option *ngFor="let role of roles" [ngValue]="role.ident">{{role.name}}</option>
	        </select>
	        <label for="user-filter-role">Rôle client</label>
	      </div>
		</div>

	  	<div class="col-sm-1">
	  		<button type="submit" class="btn btn-primary search">
	  			<i class="fa-light fa-magnifying-glass"></i>
	  		</button>
	  	</div>
	</div>
</form>
