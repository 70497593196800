import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { FormNewsComponent } from './form-news/form-news.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { UiModule } from '../../ui/ui.module';
import { GetPricesPipe, FormatPricePipe } from '@pipes/price.pipe';
import { SearchComponent } from './search/search.component';
import { StepOrderComponent } from './step-order/step-order.component';
import { AddressFormComponent } from './address-form/address-form.component';


@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    FormNewsComponent,
    ProductCardComponent,
    GetPricesPipe,
    FormatPricePipe,
    SearchComponent,
    StepOrderComponent,
    AddressFormComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    FormNewsComponent,
    ProductCardComponent,
    GetPricesPipe,
    FormatPricePipe,
    SearchComponent,
    StepOrderComponent,
    AddressFormComponent
  ]
})
export class PartialModule { }
