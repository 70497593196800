import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Observer } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ScriptService {
    private scripts: ScriptModel[]=[];
    private css: ScriptModel[]=[];

    public loadScript(name: string, src: string){
        return this.load(new Script(name, src));
    }

    public loadCSS(name: string, src: string){
        return this.load(new Script(name, src), false);
    }

    public load(script: ScriptModel, isScript: boolean=true): Observable<ScriptModel> {
        return new Observable<ScriptModel>((observer: Observer<ScriptModel>) => {
            let existingScript: ScriptModel=null;
            if(isScript)
                existingScript=this.scripts.find(s => s.name == script.name);
            else
                existingScript=this.css.find(s => s.name == script.name);

            // Complete if already loaded
            if (existingScript && existingScript.loaded) {
                observer.next(existingScript);
                observer.complete();
            }
            else {
                // Add the script
                if(isScript)
                    this.scripts.push(script);
                else
                    this.css.push(script);

                // Load the script
                let scriptElement: any;

                if(isScript){
                    scriptElement=document.createElement("script");
                    scriptElement.type="text/javascript";
                    scriptElement.src=script.src;
                }
                else{
                    scriptElement=document.createElement("link");
                    scriptElement.type="text/css";
                    scriptElement.rel="stylesheet";
                    scriptElement.media="screen";
                    scriptElement.href=script.src;
                }

                scriptElement.onload=() => {
                    script.loaded=true;
                    observer.next(script);
                    observer.complete();
                };

                scriptElement.onerror=(error: any) => {
                    observer.error("Couldn't load script " + script.src);
                };

                document.getElementsByTagName('body')[0].appendChild(scriptElement);
            }
        });
    }
}

export interface ScriptModel{
    name: string,
    src: string,
    loaded: boolean
}

export class Script implements ScriptModel{
    constructor(public name: string, public src: string, public loaded: boolean=false){ }
}