import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gob-connect-page',
  templateUrl: './connect-page.component.html',
  styleUrls: ['./connect-page.component.css']
})
export class ConnectPageComponent implements OnInit {
  readonly FORM_LOGIN='login';
  readonly FORM_SIGNUP='signup';
  readonly FORM_FORGOT='forgot';

  form: string=this.FORM_LOGIN;

  constructor() { }

  ngOnInit(): void {
  }
}
