import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { forkJoin } from 'rxjs';
import { StockService } from '@services/stock.service';
import { OrderService } from '@services/order.service';
import { OrderFilter } from '@models/filter';
import { StateOrder } from '@models/order';
import { Depot } from '@models/stock';

@Component({
  selector: 'gob-order-filter',
  templateUrl: './order-filter.component.html',
  styleUrls: ['./order-filter.component.css']
})
export class OrderFilterComponent implements OnInit {
  @Output() readonly search: EventEmitter<OrderFilter>=new EventEmitter<OrderFilter>();
  @Input() admin: boolean=false;
  depots: Depot[]=[];
  states: StateOrder[]=[];
  filter: OrderFilter;

  constructor(private stockServ: StockService, private orderServ: OrderService) {
    this.filter=new OrderFilter();
    forkJoin({
      depots: this.stockServ.getDepotsClickCollect(),
      states: this.orderServ.getStates(false)
    }).subscribe(values => {
      this.depots=values.depots;
      this.states=values.states;
    });
  }

  ngOnInit(): void {
  }

  doSearch(){
    console.log('test'); 
    this.search.emit(this.filter);
  }

}
