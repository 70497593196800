<div [loading]="loading">
	<gob-back [url]="route.get('promo-list')"></gob-back>
	<div class="form-content">
		<form class="form-admin-left" #form="ngForm">
			<gob-message [error]="error" [msg]="msg"></gob-message>
			<!-- nom du code -->
			<div class="form-floating mb-3">
			  <input type="text" class="form-control" id="promo-name" placeholder="Nom du code promo" name="name" [(ngModel)]="discount.name" required />
			  <label for="promo-name">Nom du code promo</label>
			</div>

			<!-- montant du code -->
			<div class="form-floating mb-3">
			  <input type="text" class="form-control" id="promo-amount" placeholder="Montant" name="amount" [(ngModel)]="discount.amount" required [disabled]="discount.ident"/>
			  <label for="promo-amount">Montants</label>
			</div>

			<!-- type de remise -->
			<div class="form-floating mb-3">
			  <select class="form-select" id="promo-type" name="type" [(ngModel)]="discount.type">
			    <option value="%">%</option>
			    <option value="€">€</option>
			  </select>
			  <label for="promo-type">Type de remise</label>
			</div>

			<!-- reusable -->
			<div class="form-check form-switch mb-3">
			  <input class="form-check-input" type="checkbox" id="form-usable" name="reusable" [(ngModel)]="discount.isReusable"/>
			  <label class="form-check-label" for="form-usable">Code utilisable plusieurs fois</label>
			</div>

			<button scrollTo (click)="update()" class="btn green" [disabled]="form.invalid">{{((discount.ident)? "Modifier" : "Créer")}}</button>
			<button *ngIf="discount.ident" (click)="disable()" class="btn btn-danger">Désactiver</button>
		</form>
		<div class="form-admin-right">
			<!-- Détails du code promo -->
			<div *ngIf="discount.ident" class="card">
			  <div class="card-header">
			    <i class="fa-regular fa-binary-lock"></i> Détails
			  </div>
			  <div class="card-body">
				<div [ngClass]="getClass()" class="badge">{{discount.state | titlecase}}</div>
				<div class="title-content divid">
					Code utilisateur
				</div>
				{{discount.codeNumber}}
				<div class="title-content divid">
					Montant utilisé sur ce code
				</div>
				{{discount.amountUsed}}€
			  </div>
			</div>
		</div>
	</div>
</div>