import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserService } from '@services/user.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'gob-forgot-form',
  templateUrl: './forgot-form.component.html',
  styleUrls: ['./forgot-form.component.css']
})
export class ForgotFormComponent implements OnInit {
  @Output() login: EventEmitter<boolean>=new EventEmitter<boolean>();
  loading: boolean=false;
  msg: string;

  constructor(private userServ: UserService) { }

  ngOnInit(): void {
  }

  forgotPass(email: string){
    this.loading=true;
    this.userServ.forgot(email)
      .pipe(
        tap(() => this.loading=false)
      )
      .subscribe(() => {
        this.msg='Vous avez reçu un e-mail avec les instructions pour vous conncter.';
        this.loading=false;
      });
  }

}
