import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class SearchService{
	searching: boolean=false;

	search(search: boolean){
		this.searching=search;
	}
}