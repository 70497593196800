import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators'
import { ApiService } from '@services/api.service';
import { Product, Rating, ProductSelected } from '@models/product';
import { ProductFilter, RatingFilter } from '@models/filter';

@Injectable({
	providedIn: 'root'
})
export class ProductService{
	private readonly selectedObs: Observable<ProductSelected>=this.getSelectedObs();

	constructor(private api: ApiService){ }

	list(filter: ProductFilter=new ProductFilter(), start: number=0, length: number=50){
		let params: any=JSON.parse(JSON.stringify(filter, (key, value) => (value===null ? undefined : value)));
		params.start=start;
		params.length=length;
		return this.api.get<Product>('/products/list/admin', params);
	}

	getSelectedObs(){
		return this.api.get<ProductSelected>('/products/selections')
			.pipe(
				shareReplay(1)
			);
	}

	getSelected(){
		return this.selectedObs;
	}

	searchProducts(filter: ProductFilter=new ProductFilter(), start: number=0, length: number=12){
		let params: any=JSON.parse(JSON.stringify(filter));
		params.start=start;
		params.length=length;
		return this.api.get<Product[]>('/products/list', params);
	}

	getProduct(ident: string, admin: boolean=false){
		return this.api.get<Product>(`/products/${ident}`, {admin});
	}

	setProduct(product: Product){
		let data: any=JSON.parse(JSON.stringify(product));

		//modification des tag
		data.tags=product.tags.map(tag => tag.ident);

		//modification des image du produit
		data.imageFiles=product.imageFiles;

		//modification des image des tarif
		data.prices=product.prices.map((price, i) => {
			if(price.priceImageFile){
				let p: any={...price};
				p.priceImageFile=null;
				p.imageFileName=`priceImage${i}`;
				data[`priceImage${i}`]=price.priceImageFile;
				return p;
			}
			else
				return price;
		});
		return this.api.post<Product>(`/products${((product.ident)? '/'+product.ident : '')}`, ApiService.toFormData(data));
	}

	rmProduct(ident: string){
		return this.api.delete<Product>(`/products/${ident}`);
	}

	rateProduct(ident: string, rating: Rating, token: string){
		return this.api.post<Rating>(`/ratings/${ident}`, {...rating, token});
	}

	searchRating(filter: RatingFilter, start: number, length: number){
		return this.api.get<Rating[]>('/ratings', {...filter, start, length});
	}

	getRating(ident: string){
		return this.api.get<Rating>(`/ratings/${ident}`)
	}

	validRating(ident: string){
		return this.api.get<Rating>(`/ratings/valid/${ident}`);
	}

	rmRating(ident: string){
		return this.api.delete<Rating>(`/ratings/${ident}`);
	}
}