<div class="accordion" id="item-list-{{i}}">
  <div class="accordion-item">
    <h2 id="go-item-{{item.ident}}" class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#item-'+i" aria-expanded="false" [attr.aria-controls]="'item-'+i">
        {{item.name}}
      </button>
    </h2>
    <div id="{{'item-'+i}}" class="accordion-collapse collapse" [loading]="loading" [attr.data-bs-parent]="'#item-list-'+i">
      <form class="accordion-body" #form="ngForm">
        <!-- up down -->
        <div class="actions-container">
          <a (click)="emitDown(indChild)"><i class="fa-light fa-down-from-line"></i></a>
          <a (click)="emitTop(indChild)"><i class="fa-light fa-down-from-line fa-rotate-180"></i></a>
          <a (click)="addItem()"><i class="fa-light fa-layer-plus"></i></a>
          <a (click)="emitRm(indChild)"><i class="fa-light fa-trash"></i></a>
        </div>

      	<!-- Name -->
        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="item-name-{{i}}" placeholder="Nom" name="name" [(ngModel)]="item.name" required/>
          <label for="item-name-{{i}}">Nom</label>
        </div>

        <!-- type -->
        <div class="form-floating mb-3">
          <select class="form-select" id="item-type-{{i}}" name="type" [(ngModel)]="item.type">
            <option value="title">Titre</option>
            <option value="categ">Catégorie</option>
            <option value="scateg">Sous catégorie</option>
            <option value="gamme">Gamme</option>
            <option value="marque">Marque</option>
            <option value="tag">Tag</option>
          </select>
          <label for="item-type-{{i}}">Type d'item</label>
        </div>

        <!-- elements -->
        <div [ngSwitch]="item.type">
          <!-- categs -->
          <div *ngSwitchCase="'categ'" class="form-floating mb-3">
            <select class="form-select" id="item-val-{{i}}" name="val" [(ngModel)]="item.val" required>
              <option [ngValue]="null">Selectionnez une catégorie</option>
              <option *ngFor="let categ of categs" [ngValue]="categ.ident">{{categ.name}}</option>
            </select>
            <label for="item-val-{{i}}">Catégorie cible</label>
          </div>

            <!-- scategs -->
          <div *ngSwitchCase="'scateg'" class="form-floating mb-3">
            <select class="form-select" id="item-val-{{i}}" name="val" [(ngModel)]="item.val" required>
              <option [ngValue]="null">Selectionnez une sous catégorie</option>
              <option *ngFor="let scateg of scategs" [ngValue]="scateg.ident">{{scateg.name}}</option>
            </select>
            <label for="item-val-{{i}}">Sous catégorie cible</label>
          </div>

            <!-- gammes -->
          <div *ngSwitchCase="'gamme'" class="form-floating mb-3">
            <select class="form-select" id="item-val-{{i}}" name="val" [(ngModel)]="item.val" required>
              <option [ngValue]="null">Selectionnez une gamme</option>
              <option *ngFor="let gamme of gammes" [ngValue]="gamme.ident">{{gamme.name}}</option>
            </select>
            <label for="item-val-{{i}}">Gamme cible</label>
          </div>

            <!-- marques -->
          <div *ngSwitchCase="'marque'" class="form-floating mb-3">
            <select class="form-select" id="item-val-{{i}}" name="val" [(ngModel)]="item.val" required>
              <option [ngValue]="null">Selectionnez une marque</option>
              <option *ngFor="let marque of marques" [ngValue]="marque.ident">{{marque.name}}</option>
            </select>
            <label for="item-val-{{i}}">Marque cible</label>
          </div>

            <!-- categs -->
          <div *ngSwitchCase="'tag'" class="form-floating mb-3">
            <select class="form-select" id="item-val-{{i}}" name="val" [(ngModel)]="item.val" required>
              <option [ngValue]="null">Selectionnez un tag</option>
              <option *ngFor="let tag of tags" [ngValue]="tag.ident">{{tag.name}}</option>
            </select>
            <label for="item-val-{{i}}">Tag cible</label>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div *ngFor="let child of item.children; index as iChild" class="children-container">
	<div class="child-icon">
		<i class="fa-solid fa-arrow-turn-down-right"></i>
	</div>
	<div class="child">
		<gob-menu-item (top)="topItem(iChild, item.children)" (down)="downItem(iChild, item.children)" (rm)="rmItem(iChild, item.children)" [item]="child"></gob-menu-item>
	</div>
</div>