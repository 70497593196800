import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { ShippingService } from '@services/shipping.service';
import { RouteService } from '@services/route.service';
import { Shipper, Shipping } from '@models/shipping';

@Component({
  selector: 'gob-shipper-form',
  templateUrl: './shipper-form.component.html',
  styleUrls: ['./shipper-form.component.css']
})
export class ShipperFormComponent implements OnInit {
  loading: boolean=false;
  error: string;
  msg: string;
  shipper: Shipper;

  constructor(private router: ActivatedRoute, private shipServ: ShippingService, public route: RouteService) {
    this.shipper=new Shipper();
    this.loading=true;
    this.router.paramMap
      .pipe(
        switchMap(params => {
          if(params.get('id')){
            return this.shipServ.getShipper(params.get('id'))
              .pipe(
                map(shipper => JSON.parse(JSON.stringify(shipper)))
              );
          }
          else
            return of(new Shipper());
        })
      )
      .subscribe({
        next: (shipper) => {
          this.shipper=shipper;
          this.loading=false;
        },
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
  }

  ngOnInit(): void {
  }

  update(form: any){
    this.loading=true;
    let update: boolean=(this.shipper.ident!=null);
    this.shipServ.setShipper(this.shipper).subscribe({
      next: () => {
        this.msg='Modifications enregistrées';
        if(!update){
          this.shipper=new Shipper();
          form.reset();
        }
        this.loading=false;
      },
      error: e => {
        this.error=e;
        this.loading=false;
      }
    });
  }

  rm(ident: string){
    if(confirm('Etes vous sur de vouloir supprimer ?')){
      this.loading=true;
      this.shipServ.rmShipper(ident).subscribe({
        next: () => {
          this.loading=false;
          this.route.navigate('shipper-list');
        },
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
    }
  }

}
