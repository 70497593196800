import { Component, OnInit } from '@angular/core';
import { StateOrder } from '@models/order';
import { OrderService } from '@services/order.service';
import { RouteService } from '@services/route.service';

@Component({
  selector: 'gob-order-state-list',
  templateUrl: './order-state-list.component.html',
  styleUrls: ['./order-state-list.component.css']
})
export class OrderStateListComponent implements OnInit {
  loading: boolean=false;
  states: StateOrder[]=[];

  constructor(private orderServ: OrderService, public route: RouteService) { 
    this.loading=true;
    this.orderServ.getStates(true).subscribe(states => {
      this.states=states;
      this.loading=false;
    });
  }

  ngOnInit(): void {
  }

  rm(ident: string){
    if(confirm('Etes vous sur de vouloir supprimer ?')){
      this.loading=true;
      this.orderServ.rmState(ident).subscribe(() => this.loading=false);
    }
  }

}
