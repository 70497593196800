import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '../../ui/ui.module';
import { ClassPageComponent } from './class-page/class-page.component';
import { ClassListComponent } from './class-list/class-list.component';
import { CategFormComponent } from './categ-form/categ-form.component';
import { ScategFormComponent } from './scateg-form/scateg-form.component';
import { GammeFormComponent } from './gamme-form/gamme-form.component';
import { MarqueFormComponent } from './marque-form/marque-form.component';
import { TagFormComponent } from './tag-form/tag-form.component';
import { BannerFormComponent } from './banner-form/banner-form.component';



@NgModule({
  declarations: [
    ClassPageComponent,
    ClassListComponent,
    CategFormComponent,
    ScategFormComponent,
    GammeFormComponent,
    MarqueFormComponent,
    TagFormComponent,
    BannerFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UiModule
  ],
  exports: [
    ClassPageComponent
  ]
})
export class ClassifyModule { }
