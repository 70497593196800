import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CartService } from '@services/cart.service';
import { ShippingService } from '@services/shipping.service';
import { ScriptService, Script } from '@services/script.service';
import { RouteService } from '@services/route.service';
import { UserService } from '@services/user.service';
import { StockService } from '@services/stock.service';
import { Product } from '@models/product';
import { Depot, ScheduleDate, Schedule } from '@models/stock';
import { Order } from '@models/order';
import { Address } from '@models/user';
import { Shipper } from '@models/shipping';
import { environment } from '@environments/environment';

declare var jQuery: any;

@Component({
  selector: 'gob-cart-shipper',
  templateUrl: './cart-shipper.component.html',
  styleUrls: ['./cart-shipper.component.css']
})
export class CartShipperComponent implements OnInit {
  @ViewChild('relaypoint') relaypoint: ElementRef<HTMLInputElement>;
  private colissimoElement: any;
  private readonly nbDate: number=7;
  private readonly minDate: number=2;
  private readonly intervalH: number=15;
  loading: boolean=false;
  colissimoToken: string;  
  order: Order;
  shippers: Shipper[]=[];
  depots: Depot[]=[];
  depotSelected: Depot;
  address: Address;
  datesSelected: ScheduleDate[]=[];
  hoursSelected: string[]=[];

  constructor(
    private cart: CartService,
    private shipServ: ShippingService,
    private scriptServ: ScriptService,
    public route: RouteService, 
    private cdRef: ChangeDetectorRef,
    private stockServ: StockService,
    private userServ: UserService
  ) {
    if(!this.userServ.isConnected())
      this.route.navigate('login', [], {redirect: 'cart-shipper'});
    else{
      this.loading=true;
      this.order=this.cart.getOrder();
      forkJoin({
        shippers: this.shipServ.getShippers(),
        depots: this.stockServ.getDepotsClickCollect()
      }).subscribe(values => {
        this.shippers=values.shippers;
        this.depots=values.depots;
        this.loading=false;
      });
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if(jQuery('#relay-map-colisimo').frameColissimoClose)
      jQuery('#relay-map-colisimo').frameColissimoClose();
    delete (window as any)['selectColissimo'];
  }

  getShipping(shipper: Shipper){
    let weight: number=this.cart.getLines().reduce((acc, current) => acc+=current.qte*current.price.qte*current.product.weight, 0);
    return shipper.shippings.find(shipping => shipping.weightMin<=weight && (shipping.weightMax==null || shipping.weightMax>weight));
  }

  setRelay(){
    this.order.relayPoint=null;
    if(this.order.shipper=='mondial-relay')
      this.openMondialRelay();
    else if(this.order.shipper=='colissimo-relay'){
      this.loading=true;
      this.shipServ.getColissimoToken()
        .subscribe((data: any) => {
          this.colissimoToken=data.token;
          this.openColissimo();
        });
    }
    this.emptyCC();
  }

  setClickCollect(active: boolean){
    this.order.shipper=null;
    if(active)
      this.setDepot(0);
  }

  setDepot(i: number){
    this.loading=true;
    this.depotSelected=this.depots[i];
    this.stockServ.getAddress(this.depotSelected.ident).subscribe(address => {
      this.address=address;
      this.datesSelected=this.getDates();
      this.hoursSelected=[];
      this.loading=false;
    });
  }

  //méthode qui récup les x prochaine dates valides
  getDates(){
    let dates: ScheduleDate[]=[];
    const daysOfWeek=['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
    const futureDate=new Date();

    //création d'une date à +x jour
    futureDate.setDate(futureDate.getDate()+this.minDate);

    //recherche des x dates ouverts
    while(dates.length<this.nbDate){
      let current: Schedule[]=this.depotSelected.schedules.filter(schedule => daysOfWeek[futureDate.getDay()]==schedule.day);
      let d: ScheduleDate[]=current.map(schedule => {
        return {
          date: new Date(futureDate), 
          start: schedule.startH,
          end: schedule.endH
        }
      });

      //ajout des dates
      dates=[...dates, ...d];

      //incrément de la date
      futureDate.setDate(futureDate.getDate()+1);
    }

    return dates;
  }

  setDate(i: string){
    if(i!=""){
      let date: ScheduleDate=this.datesSelected[parseInt(i)];
      this.hoursSelected=this.getHours(date);
      this.order.hCollect=null;

      const offset=date.date.getTimezoneOffset();
      let dCollect: Date=new Date(date.date.getTime() - (offset*60*1000));
      this.order.dCollect=dCollect.toISOString().split('T')[0];
    }
  }

  //méthode qui retourne les horaires pour une date
  getHours(sd: ScheduleDate){
    const slots: string[]=[];

    // Convertir les heures de début et de fin en objets Date
    const start=new Date();
    const [startHours, startMinutes]=sd.start.split(':').map(Number);
    start.setHours(startHours, startMinutes, 0, 0);

    const end=new Date();
    const [endHours, endMinutes]=sd.end.split(':').map(Number);
    end.setHours(endHours, endMinutes, 0, 0);

    // Boucle pour ajouter des créneaux de 15 minutes
    const current=new Date(start);
    while(current<=end){
      const hours=current.getHours().toString().padStart(2, '0');
      const minutes=current.getMinutes().toString().padStart(2, '0');
      slots.push(`${hours}:${minutes}:00`);

      // Ajouter 15 minutes
      current.setMinutes(current.getMinutes()+this.intervalH);
    }

    return slots;
  }

  //méthode qui enleve le Click & collect
  emptyCC(){
    this.depotSelected=null;
    this.order.address=null;
    this.order.dCollect=null;
    this.order.hCollect=null;
  }

  openMondialRelay(){
    this.loading=true;
    this.scriptServ.loadScript('mondial-relay', 'lib/mondial-relay/mondial-relay.js')
      .subscribe(() => {
        this.showMapMR();
        this.loading=false;
      });
  }

  showMapMR(){
    this.setRelayID();
    jQuery("#relay-map-mr").MR_ParcelShopPicker({
      Target: "#relay-point",
      Brand: environment.mr_api_enseigne,
      Country: "FR",
      NbResults: "7",
      Responsive: true,
      ShowResultsOnMap: true,
      OnParcelShopSelected: (data: any) => {
        this.cdRef.detectChanges();
        this.order.relayPoint=this.relaypoint.nativeElement.value;
        this.setRelayID();
      }
    });
  }

  setRelayID(){
    this.cdRef.detectChanges();
    this.order.relayPoint=this.relaypoint.nativeElement.value;
  }

  openColissimo(){
    this.loading=true;
    this.scriptServ.loadScript('mapbox', 'lib/colissimo/mapbox.js')
      .pipe(
        switchMap(() => this.scriptServ.loadCSS('mapbox', 'lib/colissimo/mapbox.css')),
        switchMap(() => this.scriptServ.loadScript('colissimo', 'lib/colissimo/colissimo.js'))
      )
      .subscribe(() => {
        this.showMapColissimo();
        this.loading=false;
      });
  }

  showMapColissimo(){
    (window as any)['selectColissimo']=this.selectColissimo.bind(this);
    if(this.colissimoElement)
      this.colissimoElement.frameColissimoClose();
    this.colissimoElement=jQuery('#relay-map-colissimo');
    this.colissimoElement.frameColissimoOpen({
      "ceLang" : "fr",
      "callBackFrame" : 'selectColissimo',
      "URLColissimo" : " https://ws.colissimo.fr",
      "ceCountryList" : "FR,ES,GB,PT,DE",
      "ceCountry" : "FR",
      "dyPreparationTime" : "1",
      "ceAddress" : "1 rue de rivoli",
      "ceZipCode" : "75001",
      "ceTown" : "Paris",
      "token" : this.colissimoToken
    });

  }

  selectColissimo(point: any){
    this.order.relayPoint=point.identifiant;
  }

  canNext(){
    if(this.depotSelected)
      return (this.order.dCollect && this.order.hCollect); 
    return (this.order.shipper && (this.order.relayPoint || (this.order.shipper!='mondial-relay' && this.order.shipper!='colissimo-relay')));
  }

  next(){
    if(this.depotSelected)
      this.order.address=this.depotSelected.address;
    this.cart.setOrder(this.order);
    this.route.navigate('cart-info');
  }
}
