import { Component, OnInit } from '@angular/core';
import { User, Group, Role } from '@models/user';
import { forkJoin } from 'rxjs';
import { RouteService } from '@services/route.service';
import { UserService } from '@services/user.service';
import { UserFilter } from '@models/filter';

@Component({
  selector: 'gob-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  loading: boolean=false;
  users: User[]=[];
  groups: Group[]=[];
  roles: Role[]=[];
  size: number=50;
  maxPage: number;
  search: UserFilter=new UserFilter();

  constructor(public route: RouteService, private userServ: UserService){
    this.loading=true;
    forkJoin({
      users: this.userServ.getUsers(this.search, 0, this.size),
      groups: this.userServ.getGroups(),
      roles: this.userServ.getRoles()
    }).subscribe(values => {
      this.users=values.users;
      this.groups=values.groups;
      this.roles=values.roles;
      this.setMaxPage(0);
      this.loading=false;
    });
  }

  ngOnInit(): void {
  }

  list(page: number){
    this.loading=true;
    this.userServ.getUsers(this.search, page*this.size, this.size).subscribe(users =>{
      this.users=users;
      this.setMaxPage(page);
      this.loading=false;
    });
  }

  setMaxPage(page: number){
    if(this.users.length<this.size)
      this.maxPage=page;
    else
      this.maxPage=page+2;
  }

  getGroup(id: string){
    return this.groups.find(group => group.ident==id);
  }

  getRoles(roles: string[]){
    let result: string[]=[];
    roles.forEach(e => {
      let role: Role=this.roles.find(r => r.ident==e);
      if(role)
        result.push(role.name);
    });
    return result.join(', ');
  }

  setUsers(filter: UserFilter){
    this.search=JSON.parse(
      JSON.stringify(filter, (key, value) => (value === null ? undefined : value))
    );
    
    this.list(0);
  }

}
