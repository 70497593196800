<div class="list-container" [loading]="loading">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Nom</th>
        <th scope="col">Poids max</th>
        <th scope="col">Disponible</th>
        <th scope="col"><i class="green fa-solid fa-pen-to-square"></i></th>
        <th scope="col"><i class="red fa-solid fa-trash"></i></th>
      </tr>
    </thead>
    <tbody class="table-group-divider">
      <tr *ngFor="let shipper of shippers">
        <td>{{shipper.name}}</td>
        <td>{{getMaxWeight(shipper)}}</td>
        <td>{{((shipper.disabled)? 'non' : 'oui')}}</td>
        <td>
        	<a href="" [routerLink]="route.parse('shipper-set', [shipper.ident])"><i class="green fa-solid fa-pen-to-square"></i></a>
        </td>
        <td>
        	<a *ngIf="shipper.editable" (click)="rm(shipper.ident)"><i class="red fa-solid fa-trash"></i></a>
        </td>
      </tr>
    </tbody>
  </table>
</div>