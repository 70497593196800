<div class="detail-container">
	<h1>{{product.name}}</h1>
	<div class="stars">
		<gob-stars [stars]="product.stars"></gob-stars>
	</div>
	<div *ngIf="product | getPrices:0" class="product-price">
		<span *ngIf="!(product | getPrices:1)">{{product | getPrices:0 | formatPrice}}€</span>
		<span *ngIf="product | getPrices:1">x{{priceSelected?.qte}} pour {{priceSelected | formatPrice}}€</span>
	</div>
	<div class="product-subtitle">
		{{product.subtitle}}
	</div>
	<div class="display-phone">
		<gob-diapo [product]="product"></gob-diapo>
	</div>
	<div class="elements">
		<div>
			<div *ngIf="product.duration" class="element">Durée</div>
			<div *ngIf="product.players" class="element">Joueurs</div>
			<div *ngIf="product.age" class="element">Age</div>
			<div *ngIf="product.tags.length" class="element">Tags</div>
			<div class="element">Disponibilité</div>
			<div *ngIf="product | getPrices:1" class="element element-form">Colisage</div>
			<div class="element element-form">Quantité</div>
		</div>
		<div>
			<div *ngIf="product.duration" class="element"> : {{product.duration}}min</div>
			<div *ngIf="product.players" class="element"> : {{product.players}}</div>
			<div *ngIf="product.age" class="element"> : {{product.age}}+</div>
			<div *ngIf="product.tags.length" class="element"> : {{getTags()}}</div>
			<div class="element">
			 : 
			 <span *ngIf="canOrder()" class="dispo">En stock</span>
			 <span *ngIf="!canOrder()" class="rupture">Rupture</span>
			</div>
			<div *ngIf="product | getPrices:1" class="element">
				<select (change)="setPrice(priceVal.value)" class="form-select" #priceVal>
					<option *ngFor="let price of product | getPrices" [value]="price.ident" [selected]="priceSelected.ident==price.ident">x{{price.qte}} pour {{price | formatPrice}}€</option>
				</select>
			</div>
			<div class="element">
				<div class="input-group mb-3">
				  <button (click)="minus(qte)" class="input-group-text" [disabled]="!canOrder()">
					<i class="fa-solid fa-minus"></i>
				  </button>
			      <input type="number" value="1" class="qte form-control" [disabled]="!canOrder()" min="0" max="{{cart.maxQte(product, priceSelected)}}" #qte/>
				  <button (click)="add(qte)" [disabled]="!canOrder()" class="input-group-text">
					<i class="fa-solid fa-plus"></i>
				  </button>
				</div>
			</div>
		</div>
	</div>
	<div class="add-cart">
		<button (click)="addCart(product, priceSelected, qte.value)" type="button" class="btn green" [disabled]="!canOrder() || !(product | getPrices:0)">Ajouter au panier</button>
	</div>
	<div *ngIf="product.video" class="video">
		<div class="accordion" id="product-video-container">
		  <div class="accordion-item">
		    <h2 class="accordion-header">
		      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#product-video">
		      	Vidéo
		      </button>
		    </h2>
		    <div id="product-video" class="accordion-collapse collapse" data-bs-parent="#product-video-container">
		      <div class="accordion-body">
				<gob-youtube [id]="product.video"></gob-youtube>
		      </div>
		    </div>
		  </div>
		</div>
	</div>
</div>