import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '../../ui/ui.module';
import { PromoPageComponent } from './promo-page/promo-page.component';
import { PromoListComponent } from './promo-list/promo-list.component';
import { PromoFormComponent } from './promo-form/promo-form.component';



@NgModule({
  declarations: [
    PromoPageComponent,
    PromoListComponent,
    PromoFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UiModule
  ],
  exports: [
    PromoPageComponent
  ]
})
export class PromoCodeModule { }
