<div class="form-content" [loading]="loading">
  <div class="form-admin-left">
    <header id="go-categ" class="header-admin">
      <h2 class="admin">Liste des catégories</h2>
      <div>
        <a href="" [routerLink]="route.get('categ-form')"><i class="fa-solid fa-layer-plus"></i></a>
      </div>
    </header>
    <gob-message [error]="error" [msg]="msg"></gob-message>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Nom</th>
          <th scope="col"><i class="green fa-solid fa-pen-to-square"></i></th>
          <th scope="col"><i class="red fa-solid fa-trash"></i></th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr *ngFor="let categ of categs">
          <td>{{categ.name}}</td>
          <td>
            <a href="" [routerLink]="route.parse('categ-set', [categ.ident])"><i class="green fa-solid fa-pen-to-square"></i></a>
          </td>
          <td>
            <a (click)="rmCateg(categ.ident)"><i class="red fa-solid fa-trash"></i></a>
          </td>
        </tr>
      </tbody>
    </table>

    <header id="go-scateg" class="header-admin">
      <h2 class="admin">Liste des sous catégories</h2>
      <div>
        <a href="" [routerLink]="route.get('scateg-form')"><i class="fa-solid fa-layer-plus"></i></a>
      </div>
    </header>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Nom</th>
          <th scope="col"><i class="green fa-solid fa-pen-to-square"></i></th>
          <th scope="col"><i class="red fa-solid fa-trash"></i></th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr *ngFor="let scateg of scategs">
          <td>{{scateg.name}}</td>
          <td>
            <a href="" [routerLink]="route.parse('scateg-set', [scateg.ident])"><i class="green fa-solid fa-pen-to-square"></i></a>
          </td>
          <td>
            <a (click)="rmSCateg(scateg.ident)"><i class="red fa-solid fa-trash"></i></a>
          </td>
        </tr>
      </tbody>
    </table>

    <header id="go-gamme" class="header-admin">
      <h2 class="admin">Liste des gammes</h2>
      <div>
        <a href="" [routerLink]="route.get('gamme-form')"><i class="fa-solid fa-layer-plus"></i></a>
      </div>
    </header>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Nom</th>
          <th scope="col"><i class="green fa-solid fa-pen-to-square"></i></th>
          <th scope="col"><i class="red fa-solid fa-trash"></i></th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr *ngFor="let gamme of gammes">
          <td>{{gamme.name}}</td>
          <td>
            <a href="" [routerLink]="route.parse('gamme-set', [gamme.ident])"><i class="green fa-solid fa-pen-to-square"></i></a>
          </td>
          <td>
            <a (click)="rmGamme(gamme.ident)"><i class="red fa-solid fa-trash"></i></a>
          </td>
        </tr>
      </tbody>
    </table>

    <header id="go-marque" class="header-admin">
      <h2 class="admin">Liste des marques</h2>
      <div>
        <a href="" [routerLink]="route.get('marque-form')"><i class="fa-solid fa-layer-plus"></i></a>
      </div>
    </header>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Nom</th>
          <th scope="col"><i class="green fa-solid fa-pen-to-square"></i></th>
          <th scope="col"><i class="red fa-solid fa-trash"></i></th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr *ngFor="let marque of marques">
          <td>{{marque.name}}</td>
          <td>
            <a href="" [routerLink]="route.parse('marque-set', [marque.ident])"><i class="green fa-solid fa-pen-to-square"></i></a>
          </td>
          <td>
            <a (click)="rmMarque(marque.ident)"><i class="red fa-solid fa-trash"></i></a>
          </td>
        </tr>
      </tbody>
    </table>

    <header id="go-tag" class="header-admin">
      <h2 class="admin">Liste des tags</h2>
      <div>
        <a href="" [routerLink]="route.get('tag-form')"><i class="fa-solid fa-layer-plus"></i></a>
      </div>
    </header>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Nom</th>
          <th scope="col"><i class="green fa-solid fa-pen-to-square"></i></th>
          <th scope="col"><i class="red fa-solid fa-trash"></i></th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr *ngFor="let tag of tags">
          <td>{{tag.name}}</td>
          <td>
            <a href="" [routerLink]="route.parse('tag-set', [tag.ident])"><i class="green fa-solid fa-pen-to-square"></i></a>
          </td>
          <td>
            <a (click)="rmTag(tag.ident)"><i class="red fa-solid fa-trash"></i></a>
          </td>
        </tr>
      </tbody>
    </table>

    <header id="go-banner" class="header-admin">
      <h2 class="admin">Liste des bannières</h2>
      <div>
        <a href="" [routerLink]="route.get('banner-form')"><i class="fa-solid fa-layer-plus"></i></a>
      </div>
    </header>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Nom</th>
          <th scope="col">Aperçu</th>
          <th scope="col"><i class="green fa-solid fa-pen-to-square"></i></th>
          <th scope="col"><i class="red fa-solid fa-trash"></i></th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr *ngFor="let banner of banners">
          <td>{{banner.name}}</td>
          <td>
            <div [style.backgroundColor]="banner.color" class="banner">
              {{banner.name}}
            </div>
          </td>
          <td>
            <a href="" [routerLink]="route.parse('banner-set', [banner.ident])"><i class="green fa-solid fa-pen-to-square"></i></a>
          </td>
          <td>
            <a (click)="rmBanner(banner.ident)"><i class="red fa-solid fa-trash"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="form-admin-right">
    <div class="card">
      <div class="card-header">
        <i class="fa-regular fa-list-tree"></i> Classification
      </div>
      <div class="card-body">
          <a class="go-to" scrollTo="go-categ">Catégories<i class="fa-solid fa-arrow-up-right-from-square"></i></a>
          <a class="go-to" scrollTo="go-scateg">Sous catégories<i class="fa-solid fa-arrow-up-right-from-square"></i></a>
          <a class="go-to" scrollTo="go-gamme">Gammes<i class="fa-solid fa-arrow-up-right-from-square"></i></a>
          <a class="go-to" scrollTo="go-marque">Marques<i class="fa-solid fa-arrow-up-right-from-square"></i></a>
          <a class="go-to" scrollTo="go-tag">Tags<i class="fa-solid fa-arrow-up-right-from-square"></i></a>
          <a class="go-to" scrollTo="go-banner">Bannières<i class="fa-solid fa-arrow-up-right-from-square"></i></a>
      </div>
    </div>
  </div>
</div>