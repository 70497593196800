import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { RouteService } from '@services/route.service';
import { UserService } from '@services/user.service';

@Injectable({
	providedIn: 'root'
})
export class RightGuard implements CanActivate, CanActivateChild{
	constructor(private router: Router, private routes: RouteService, private userServ: UserService){ }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree{
		if(this.hasRight(route))
			return true;
		else
			return this.router.parseUrl(this.routes.get('login'));
	}	

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree{
		if(this.hasRight(route))
			return true;
		else
			return this.router.parseUrl(this.routes.get('login'));
	}

	hasRight(route: ActivatedRouteSnapshot){
		return (!route.data || !route.data.right || this.userServ.hasRight(route.data.right));
	}
}