import { Component, OnInit } from '@angular/core';
import { GlobalService } from '@services/global.service';
import { MenuItem } from '@models/global';

@Component({
  selector: 'gob-menu-page',
  templateUrl: './menu-page.component.html',
  styleUrls: ['./menu-page.component.css']
})
export class MenuPageComponent implements OnInit {
  loading: boolean=false;
  error: string;
  msg: string;
  items: MenuItem[]=[];

  constructor(private gServ: GlobalService) {
    this.loading=true;
    this.gServ.getMenu().subscribe({
      next: items => {
        this.items=items;
        this.loading=false;
      },
      error: e => {
        this.error=e;
        this.loading=false;
      }
    });
  }

  ngOnInit(): void {
  }

  addItem(){
    GlobalService.addItem(this.items);
  }

  update(){
    this.loading=true;
    this.gServ.updateMenu(this.items).subscribe({
      next: (items) => {
        this.msg='Modifications enregistrées';
        this.loading=false;
      },
      error: e => {
        this.error=e;
        this.loading=false;
      }
    });
  }

  getLevel1(){
    return this.items.filter(item => !item.parent);
  }

}
