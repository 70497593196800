<div class="block searching" [ngClass]="{open: search.searching}">
	<div class="search-form">
		<gob-back (back)="close()"></gob-back>
		<div class="search-container">
			<div class="input-group mb-3">
			  <div class="form-floating">
			    <input type="text" class="border-gradient-left form-control" id="seaching-fullname" placeholder="Recherchez un produit" [formControl]="text"/>
			    <label for="seaching-fullname">Recherchez</label>
			  </div>
			  <button class="input-group-text brun">
				<i class="fa-regular fa-magnifying-glass"></i>
			  </button>
			</div>
		</div>
		<div class="result-container" [loading]="loading">
			<div *ngIf="text.value!='' && !products.length" class="no-content">
				Aucun produit ne correspond à votre recherche
			</div>
			<a *ngFor="let product of products" [routerLink]="route.parse('product', [product.ident])" (click)="close()" class="result" title="{{product.name}}">
				<span class="img-container">
					<img [src]="getImage(product)" title="product.name"/>
				</span>
				<span class="truncate-1l">
					<b>{{product.name}}</b>, {{product.subtitle}}
				</span>
			</a>
		</div>
	</div>
</div>
