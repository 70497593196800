import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '../../ui/ui.module';
import { ReviewListComponent } from './review-list/review-list.component';
import { ReviewPageComponent } from './review-page/review-page.component';
import { ReviewFilterComponent } from './review-filter/review-filter.component';

@NgModule({
  declarations: [
    ReviewListComponent,
    ReviewPageComponent,
    ReviewFilterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UiModule
  ],
  exports: [
    ReviewListComponent,
    ReviewPageComponent
  ]
})
export class ReviewModule { }
