<div class="list-container" [loading]="loading">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Nom</th>
        <th scope="col">Droits</th>
        <th scope="col"><i class="green fa-solid fa-pen-to-square"></i></th>
        <th scope="col"><i class="red fa-solid fa-trash"></i></th>
      </tr>
    </thead>
    <tbody class="table-group-divider">
      <tr *ngFor="let role of roles">
        <td>{{role.name}}</td>
        <td>{{role.rights.length}}</td>
        <td>
        	<a href="" [routerLink]="route.parse('role-set', [role.ident])"><i class="green fa-solid fa-pen-to-square"></i></a>
        </td>
        <td>
        	<a (click)="rm(role.ident)"><i class="red fa-solid fa-trash"></i></a>
        </td>
      </tr>
    </tbody>
  </table>
</div>