<div [loading]="loading">
	<gob-header></gob-header>
	<div class="block form-admin">
		<gob-step-order [ind]="1"></gob-step-order>
		<div class="back-container">
			<gob-back [url]="route.get('cart-shipper')"></gob-back>
		</div>
		<!-- addresse d'envoi -->
		<h2 class="admin">Adresse de livraison</h2>
		<div *ngIf="!order.dCollect">
			<div *ngIf="!openShip" class="address-container">
				<ul class="list-group">
				  <li *ngFor="let address of addresses; first as isFirst" class="list-group-item">
				    <input class="form-check-input me-1" type="radio" name="shipping" id="shipping-address-{{address.ident}}" value="{{address.ident}}" [(ngModel)]="order.address" [checked]="isFirst">
				    <label class="form-check-label" for="shipping-address-{{address.ident}}">
				    	<b>{{address.firstname | titlecase}} {{address.lastname | titlecase}}</b>
				    	<span class="truncate-2l">
				    		{{address.address}}, <br/>
				    		{{address.cp}}, {{address.city}}, {{getPays(address.pays)}}
				    	</span>
				    </label>
				  </li>
				</ul>
			</div>
			<a *ngIf="!openShip" (click)="openShip=true" class="add-address">
				<i class="fa-solid fa-layer-plus"></i> Ajouter une adresse
			</a>
			<div *ngIf="openShip" class="address-container">
				<gob-back (back)="openShip=false"></gob-back>
				<gob-address-form (add)="addAddress($event)"></gob-address-form>
			</div>
		</div>
		<div *ngIf="order.dCollect" class="no-content">
			Vous avez choisi le retrait en boutique
		</div>

		<!-- addresse de facturation -->
		<h2 class="admin">Adresse de facturation</h2>
		<div *ngIf="!order.dCollect" class="form-check form-switch mb-3">
		  <input (change)="setInvoice($event)" class="form-check-input" type="checkbox" id="invoice-same" name="invoice" [(ngModel)]="sameInvoice" #invoice/>
		  <label class="form-check-label" for="invoice-same">Utiliser l'adresse de livraison pour la facturation</label>
		</div>

		<div *ngIf="!sameInvoice || order.dCollect">
			<div *ngIf="!openInvoice" class="address-container">
				<ul class="list-group">
				  <li *ngFor="let address of addresses; first as isFirst" class="list-group-item">
				    <input class="form-check-input me-1" type="radio" name="shippingInvoice" id="shipping-invoice-{{address.ident}}" value="{{address.ident}}" [(ngModel)]="order.addressInvoice" [checked]="isFirst">
				    <label class="form-check-label" for="shipping-invoice-{{address.ident}}">
				    	<b>{{address.firstname | titlecase}} {{address.lastname | titlecase}}</b>
				    	<span class="truncate-2l">
				    		{{address.address}}, <br/>
				    		{{address.cp}}, {{address.city}}, {{getPays(address.pays)}}
				    	</span>
				    </label>
				  </li>
				</ul>
			</div>
			<a *ngIf="!openInvoice" (click)="openInvoice=true" class="add-address">
				<i class="fa-solid fa-layer-plus"></i> Ajouter une adresse
			</a>
			<div *ngIf="openInvoice" class="address-container">
				<gob-back (back)="openInvoice=false"></gob-back>
				<gob-address-form (add)="addAddress($event)"></gob-address-form>
			</div>
		</div>

		<div class="next">
			<button scrollTo type="button" (click)="next()" class="btn green" [disabled]="!order.address">Valider</button>
		</div>
	</div>
</div>