import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { UserService } from '@services/user.service';
import { User } from '@models/user';

@Component({
  selector: 'gob-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.css']
})
export class UserAdminComponent implements OnInit {
  loading: boolean=false;
  user: User;

  constructor(private router: ActivatedRoute, private userServ: UserService) {
    this.loading=true;
    this.router.paramMap
      .pipe(
        switchMap(params => this.userServ.getUser(params.get('id')))
      ).subscribe(user => {
        this.user=user;
        this.loading=false;
      });
  }

  ngOnInit(): void {
  }

}
