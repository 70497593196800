<form class="miniLoader-inside" method="post" name="Monetico" target="_top" action="https://p.monetico-services.com/test/paiement.cgi" #formMonetico>
    <input type="hidden" name="version" [value]="form?.version"/>
    <input type="hidden" name="TPE" [value]="form?.TPE"/>
    <input type="hidden" name="date" [value]="form?.date"/>
    <input type="hidden" name="montant" [value]="form?.montant"/>
    <input type="hidden" name="reference" [value]="form?.reference"/>
    <input type="hidden" name="MAC" [value]="form?.MAC"/>
    <input type="hidden" name="url_retour_ok" [value]="form?.url_retour_ok"/>
    <input type="hidden" name="url_retour_err" [value]="form?.url_retour_err"/>
    <input type="hidden" name="lgue" [value]="form?.lgue"/>
	<input type="hidden" name="contexte_commande" [value]="form?.contexte_commande"/>
    <input type="hidden" name="societe" [value]="form?.societe"/>
    <input type="submit" class="btn green" value="Valider"/>
</form>