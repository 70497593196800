<div class="block form-admin">
	<header class="header-admin">
		<h1 class="admin">Avis client</h1>
	</header>
	<div class="list-container" [loading]="loading">
	  <div class="content-form">
	  	<gob-review-filter (search)="search(0, $event)"></gob-review-filter>
	  	<table class="table">
		    <thead>
		      <tr>
		        <th scope="col">Date</th>
		        <th scope="col">Produit</th>
		        <th scope="col">Client</th>
		        <th scope="col"><img src="/assets/img/skin/star.png"></th>
		        <th scope="col">Avis</th>
		        <th scope="col">Etat</th>
			    <th scope="col"><i class="green fa-solid fa-pen-to-square"></i></th>
		      </tr>
		    </thead>
		    <tbody class="table-group-divider">
		      <tr *ngFor="let rating of ratings; index as i">
		        <td>{{rating.date | date:'dd-MM-yyyy'}}</td>
		        <td>{{rating.product}}</td>
		        <td>{{rating.customer}}</td>
		        <td><gob-stars [stars]="rating.rate"></gob-stars></td>
		        <td>
		        	<div class="truncate-1l">
			        	{{rating.review}}
		        	</div>
		        </td>
		        <td>{{getState(rating.state)}}</td>
			    <td>
			    	<a [routerLink]="router.parse('review-set', [rating.ident])">
			    		<i class="green fa-solid fa-pen-to-square"></i>
			    	</a>
			    </td>
		      </tr>
		    </tbody>
		  </table>
		  <gob-pagination (change)="search($event)" [activePage]="page" [max]="max"></gob-pagination>
	  </div>
  	</div>
</div>