import { Injectable, ErrorHandler } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    if(req.url.includes(environment.servicesUrl) && localStorage.getItem('session')){
      const clone=req.clone({ setHeaders: {'X-ACCESS-SESSION': localStorage.getItem('session')}});
      return next.handle(clone);
    }

    return next.handle(req);
  }
}