import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { tap, switchMap } from 'rxjs/operators';
import { ProductService } from '@services/product.service';
import { Product } from '@models/product';
import { ProductFilter } from '@models/filter';

@Component({
  selector: 'gob-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {
  loading: boolean=false;
  isFilter: boolean=false;
  isGrid: boolean=true;
  page: number=0;
  size: number=12;
  max: number=0;
  filter: ProductFilter=new ProductFilter();
  _products: Product[];

  set products(val: Product[]){
    this._products=val;
    if(val.length==this.size)
      this.max=this.page+2;
  }

  get products(){
    return this._products;
  }


  constructor(private pServ: ProductService, private router: ActivatedRoute) {
    this.products=[];
    this.loading=true;
    this.router.paramMap
      .pipe(
        switchMap(params => {
          this.filter=new ProductFilter(params.get('type'), params.get('id'));
          return this.search();
        })
      )
      .subscribe(() => this.loading=false);
  }

  ngOnInit(): void {
  }

  next(page: number, filter: ProductFilter){
    this.page=page;
    this.loading=true;
    if(this.isFilter)
      this.filter=filter;
    this.search(filter).subscribe(() => this.loading=false);
  }

  search(filter?: ProductFilter){
    let search: ProductFilter=((filter)? filter : this.filter)
    return this.pServ.searchProducts(search, this.page*this.size, this.size)
      .pipe(
        tap(products => this.products=products)
      );
  }

  filterProduct(filter: ProductFilter){
    this.isFilter=true;
    this.max=0;
    this.next(0, {...this.filter, ...filter});
  }

}
