import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ProductService } from '@services/product.service';
import { GlobalService } from '@services/global.service';
import { Product } from '@models/product';
import { Setting } from '@models/global';

@Component({
  selector: 'gob-home-selection',
  templateUrl: './home-selection.component.html',
  styleUrls: ['./home-selection.component.css']
})
export class HomeSelectionComponent implements OnInit {
  loading: boolean=false;
  news: Product[]=[];
  promos: Product[]=[];
  pos3: Product[]=[];
  pos4: Product[]=[];
  settings: Setting[]=[];
  tabActive: string='news';
  ind: number=0;

  constructor(private gServ: GlobalService, private pServ: ProductService) { }

  ngOnInit(): void {
    this.loading=true;
    forkJoin({
      settings: this.gServ.getSettings(),
      products: this.pServ.getSelected()
    })
    .subscribe(values => {
      this.settings=values.settings;
      this.news=values.products.news;
      this.promos=values.products.promos;
      this.pos3=values.products.pos3;
      this.pos4=values.products.pos4;
      this.loading=false;
    }) 
  }

  getPos3Name(){
    return this.settings.find(setting => setting.name=='selection.pos3.name')?.val;
  }

  getPos4Name(){
    return this.settings.find(setting => setting.name=='selection.pos4.name')?.val;
  }

  setTab(tab: string){
    this.tabActive=tab;
    this.ind=0;
  }

  next(){
    let max: number=this.news.length;
    switch(this.tabActive){
      case 'news':
        max=this.news.length;
        break;
      case 'promos':
        max=this.promos.length;
        break;
      case 'pos3':
        max=this.pos3.length;
        break;
      case 'pos4':
        max=this.pos4.length;
        break;
      default:
        break;
    }
    if(this.ind+1!=max)
      this.ind++;
  }

  prev(){
    this.ind--;
    if(this.ind<0)
      this.ind=0;
  }

}
