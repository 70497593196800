export type Day='lundi' | 'mardi' | 'mercredi' | 'jeudi' | 'vendredi' | 'samedi' | 'dimanche';

export class MenuItem{
	ident: string;
	parent?: string;
	name: string='Sans nom';
	type: 'title' | 'categ' | 'scateg' | 'gamme' | 'marque' | 'tag'='title';
	val: string=null;
	ind: number;
	children: MenuItem[]=[];
}

export class Setting{
	name: string;
	val: string;
	display: string;
}