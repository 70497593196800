import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { StatService } from '@services/stat.service';
import { StatFilter } from '@models/filter';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'gob-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.css']
})
export class DashboardPageComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  loading: boolean=false;
  datas: any;
  chartOptions: any={
      chart: {
        type: 'line'
      },
      title: {
        text: 'Chiffre d’affaires et Marge'
      },
      xAxis: {
        categories: []
      },
      yAxis: {
        title: {
          text: 'Montant (€)'
        }
      },
      series: [
        {
          name: 'Chiffre d’affaires HT',
          type: 'line',
          data: [],
          color: this.getColors().colorCA
        },
        {
          name: 'Marge',
          type: 'line',
          data: [],
          color: this.getColors().colorMarge
        }
      ],
      credits: {
        enabled: false
      }
    };

  constructor(private stat: StatService, private date: DatePipe) { 
    this.initData();
  }

  ngOnInit(): void {
  }

  initData(filter?: StatFilter){
    this.loading=true;
    //relance le graph
    this.datas=null;
    if(!filter)
      filter=new StatFilter();
    this.stat.getStats(filter).subscribe(datas => {
      this.loading=false;
      this.setDatas(datas);
    });
  }

  setDatas(datas: any){
    this.chartOptions.xAxis.categories=datas.CADays.map((e:any) => e.date);
    this.chartOptions.series[0].data=datas.CADays.map((e:any) => e.value);
    this.chartOptions.series[1].data=datas.margeDays.map((e:any) => e.value);
    this.datas=datas;
  }

  getColors(){
    const rootStyles=getComputedStyle(document.documentElement);
    const colorCA=rootStyles.getPropertyValue('--color-green').trim();
    const colorMarge=rootStyles.getPropertyValue('--color-brun').trim();

    return {colorCA, colorMarge};
  }

}
