<div class="block form-admin">
	<header class="header-admin">
		<h1 class="admin">Paramètres d'affichage</h1>
	</header>
	<div class="list-container" [loading]="loading">
	  <form class="content-form" #form="ngForm">
	  	<section>
		  	<!-- selection en page d'accueil -->
		    <header class="header-admin">
		      <h2 class="admin">Produit en page d'accueil</h2>
		    </header>
		    <gob-message [error]="error" [msg]="msg"></gob-message>
		    <!-- nouvauté -->
			<div class="form-check form-switch mb-3">
			  <input class="form-check-input" type="checkbox" id="pdt-new" name="pdtnew" checked [(ngModel)]="isNews"/>
			  <label class="form-check-label" for="pdt-new">Nouveauté</label>
			</div>

		    <!-- promo -->
			<div class="form-check form-switch mb-3">
			  <input class="form-check-input" type="checkbox" id="pdt-promo" name="pdtnew" checked [(ngModel)]="isPromo"/>
			  <label class="form-check-label" for="pdt-promo">Promo</label>
			</div>

		    <!-- pos 3 -->
			<div class="row g-3">
			  	<div class="title-pos col-sm-2">
				    <!-- active -->
					<div class="form-check form-switch mb-3">
					  <input class="form-check-input" type="checkbox" id="active-pos3" name="activePos3" [(ngModel)]="isPos3"/>
					  <label class="form-check-label" for="active-pos3">Position 3 :</label>
					</div>
			  	</div>
			  	<div *ngIf="isPos3" class="col-sm-4">
			  		<div class="form-floating mb-3">
						<select class="form-select" id="pos3-type" name="pos3Type" [required]="isPos3" [(ngModel)]="pos3Type">
							<option [ngValue]="''">Séléctionnez une classification</option>
							<option value="categ">Catégorie</option>
							<option value="scateg">Sous catégorie</option>
							<option value="gamme">Gamme</option>
							<option value="marque">Marque</option>
							<option value="tag">Tag</option>
						</select>
						<label for="pos3-type">Type de produits</label>
					</div>
				</div>

			  	<div *ngIf="isPos3" class="col-sm-4">
					<!-- categs -->
			  		<div *ngIf="pos3Type=='categ'" class="form-floating mb-3">
						<select (change)="setPosName(3)" class="form-select" id="pos3-categ" name="pos3Categ" [required]="isPos3" [(ngModel)]="pos3Value">
							<option [ngValue]="''">Séléctionnez une catégorie</option>
							<option *ngFor="let categ of categs" value="{{categ.ident}}">{{categ.name}}</option>
						</select>
						<label for="pos3-categ">Catégories</label>
					</div>

					<!-- scategs -->
			  		<div *ngIf="pos3Type=='scateg'" class="form-floating mb-3">
						<select (change)="setPosName(3)" class="form-select" id="pos3-scateg" name="pos3SCateg" [required]="isPos3" [(ngModel)]="pos3Value">
							<option [ngValue]="''">Séléctionnez une sous catégorie</option>
							<option *ngFor="let scateg of scategs" value="{{scateg.ident}}">{{scateg.name}}</option>
						</select>
						<label for="pos3-scateg">Sous catégories</label>
					</div>

					<!-- gammes -->
			  		<div *ngIf="pos3Type=='gamme'" class="form-floating mb-3">
						<select (change)="setPosName(3)" class="form-select" id="pos3-gamme" name="pos3gamme" [required]="isPos3" [(ngModel)]="pos3Value">
							<option [ngValue]="''">Séléctionnez une gamme</option>
							<option *ngFor="let gamme of gammes" value="{{gamme.ident}}">{{gamme.name}}</option>
						</select>
						<label for="pos3-gamme">Marques</label>
					</div>

					<!-- marques -->
			  		<div *ngIf="pos3Type=='marque'" class="form-floating mb-3">
						<select (change)="setPosName(3)" class="form-select" id="pos3-marque" name="pos3marque" [required]="isPos3" [(ngModel)]="pos3Value">
							<option [ngValue]="''">Séléctionnez une marque</option>
							<option *ngFor="let marque of marques" value="{{marque.ident}}">{{marque.name}}</option>
						</select>
						<label for="pos3-marque">Marques</label>
					</div>

					<!-- tags -->
			  		<div *ngIf="pos3Type=='tag'" class="form-floating mb-3">
						<select (change)="setPosName(3)" class="form-select" id="pos3-tag" name="pos3tag" [required]="isPos3" [(ngModel)]="pos3Value">
							<option [ngValue]="''">Séléctionnez un tag</option>
							<option *ngFor="let tag of tags" value="{{tag.ident}}">{{tag.name}}</option>
						</select>
						<label for="pos3-tag">Tags</label>
					</div>
			  	</div>
		  	</div>

		    <!-- pos 4 -->
			<div class="row g-3">
			  	<div class="title-pos col-sm-2">
				    <!-- active -->
					<div class="form-check form-switch mb-3">
					  <input class="form-check-input" type="checkbox" id="active-pos4" name="activePos4" [(ngModel)]="isPos4"/>
					  <label class="form-check-label" for="active-pos4">Position 4 :</label>
					</div>
			  	</div>
			  	<div *ngIf="isPos4" class="col-sm-4">
			  		<div class="form-floating mb-3">
						<select class="form-select" id="pos4-type" name="pos4Type" [required]="isPos4" [(ngModel)]="pos4Type">
							<option [ngValue]="''">Séléctionnez une classification</option>
							<option value="categ">Catégorie</option>
							<option value="scateg">Sous catégorie</option>
							<option value="gamme">Gamme</option>
							<option value="marque">Marque</option>
							<option value="tag">Tag</option>
						</select>
						<label for="pos4-type">Type de produits</label>
					</div>
				</div>
				
			  	<div *ngIf="isPos4" class="col-sm-4">
					<!-- categs -->
			  		<div *ngIf="pos4Type=='categ'" class="form-floating mb-3">
						<select (change)="setPosName(4)" class="form-select" id="pos4-categ" name="pos4Categ" [required]="isPos4" [(ngModel)]="pos4Value">
							<option [ngValue]="''">Séléctionnez une catégorie</option>
							<option *ngFor="let categ of categs" value="{{categ.ident}}">{{categ.name}}</option>
						</select>
						<label for="pos4-categ">Catégories</label>
					</div>

					<!-- scategs -->
			  		<div *ngIf="pos4Type=='scateg'" class="form-floating mb-3">
						<select (change)="setPosName(4)" class="form-select" id="pos4-scateg" name="pos4SCateg" [required]="isPos4" [(ngModel)]="pos4Value">
							<option [ngValue]="''">Séléctionnez une sous catégorie</option>
							<option *ngFor="let scateg of scategs" value="{{scateg.ident}}">{{scateg.name}}</option>
						</select>
						<label for="pos4-scateg">Sous catégories</label>
					</div>

					<!-- gammes -->
			  		<div *ngIf="pos4Type=='gamme'" class="form-floating mb-3">
						<select (change)="setPosName(4)" class="form-select" id="pos4-gamme" name="pos4gamme" [required]="isPos4" [(ngModel)]="pos4Value">
							<option [ngValue]="''">Séléctionnez une gamme</option>
							<option *ngFor="let gamme of gammes" value="{{gamme.ident}}">{{gamme.name}}</option>
						</select>
						<label for="pos4-gamme">Marques</label>
					</div>

					<!-- marques -->
			  		<div *ngIf="pos4Type=='marque'" class="form-floating mb-3">
						<select (change)="setPosName(4)" class="form-select" id="pos4-marque" name="pos4marque" [required]="isPos4" [(ngModel)]="pos4Value">
							<option [ngValue]="''">Séléctionnez une marque</option>
							<option *ngFor="let marque of marques" value="{{marque.ident}}">{{marque.name}}</option>
						</select>
						<label for="pos4-marque">Marques</label>
					</div>

					<!-- tags -->
			  		<div *ngIf="pos4Type=='tag'" class="form-floating mb-3">
						<select (change)="setPosName(4)" class="form-select" id="pos4-tag" name="pos4tag" [required]="isPos4" [(ngModel)]="pos4Value">
							<option [ngValue]="''">Séléctionnez un tag</option>
							<option *ngFor="let tag of tags" value="{{tag.ident}}">{{tag.name}}</option>
						</select>
						<label for="pos4-tag">Tags</label>
					</div>
			  	</div>
		  	</div>
		</section>


	  	<!-- selection en page d'accueil -->
	  	<section>
		    <header class="header-admin">
		      <h2 class="admin">Compte à rebours</h2>
		    </header>
		    <!-- image -->
			<div class="form-floating mb-3">
				<div class="img-container">
					<img [src]="getImage()"/>
				</div>
		    	<input type="file" name="img" (change)="setFile($event)"/>
		    </div>

		    <!-- date de fin -->
		    <div class="row">
		    	<div class="col-sm-6">
					<div class="form-floating mb-3">
					  <input type="date" class="form-control" id="end-date" placeholder="Date de fin" name="enddate" [(ngModel)]="endDate" required />
					  <label for="end-date">Date de fin</label>
					</div>
		    	</div>
		    	<div class="col-sm-6">
					<div class="form-floating mb-3">
					  <input type="time" class="form-control" id="end-h" placeholder="Heure de fin" name="endh" [(ngModel)]="endH" required />
					  <label for="end-date">Heure de fin</label>
					</div>
				</div>
		    </div>

		    <!-- titre -->
			<div class="form-floating mb-3">
			  <input type="text" class="form-control" id="countdown-title" placeholder="Titre" name="count-title" [(ngModel)]="title" required />
			  <label for="countdown-title">Titre</label>
			</div>

		    <!-- détail -->
			<div class="form-floating mb-3">
			  <input type="text" class="form-control" id="countdown-details" placeholder="Détails" name="count-details" [(ngModel)]="details" required />
			  <label for="countdown-details">Détails</label>
			</div>

		    <!-- url -->
			<div class="form-floating mb-3">
			  <input type="text" class="form-control" id="countdown-url" placeholder="Url" name="count-url" [(ngModel)]="url" required />
			  <label for="countdown-url">Url</label>
			</div>
		</section>

		<button scrollTo (click)="changeSettings()" type="submit" class="btn green" [disabled]="form.invalid">Valider</button>
	  </form>
  	</div>
</div>