import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { tap, reduce, map, shareReplay } from 'rxjs/operators';
import { ApiService } from './api.service';
import { User, Role, Address, Pays, Right, Group, Newsletter } from '@models/user';
import { UserFilter } from '@models/filter';

@Injectable({
	providedIn: 'root'
})
export class UserService{
	_user: User;
	_session: string;
	private groups: Group[];
	private roles: Role[];
	private pays: Pays[];
	private rights: Right[];

	//obserable 
	private readonly rolesObs: Observable<Role[]>=this.getRolesObs();
	private readonly paysObs: Observable<Pays[]>=this.getPaysObs();
	private readonly groupsObs: Observable<Group[]>=this.getGroupsObs();
	private readonly rightsObs: Observable<Right[]>=this.getRightsObs();

	//cosntructor
	constructor(private api: ApiService){ }

	//accessor
	get user(): User{
		this._user=JSON.parse(localStorage.getItem('user'));
		return this._user;
	}

	set user(value: User){
		this._user=value;
	 	localStorage.setItem('user', JSON.stringify(this._user));
	}

	get session(): string{
		this._session=localStorage.getItem('session');
		return this._session;
	}

	set session(value: string){
		this._session=value;
	 	localStorage.setItem('session', this._session);
	}

	clear(){
		this.user=null;
		this.session=null;
		localStorage.clear();
	}

	hasRight(right: string){
		return (this.user!=null && this.user.rights.filter(r => r==right).length>0);
	}

	isConnected(){
		return (localStorage.getItem('user')!=null);
	}

	myAccount(){
		return JSON.parse(localStorage.getItem('user'));
	}

	login(email: string, pass: string){
		return this.api.post<any>('/users/connect', {email, pass})
			.pipe(
				tap(data => this.session=data.session),
				tap(data => this.user=data.user)
			);
	}

	signup(user: User, token: string){
		return this.api.post<User>('/users/subscribe', {user, token})
			.pipe(
				tap(user => this.user=user)
			);
	}

	forgot(email: string){
		return this.api.get<any>('/users/forgot');
	}

	unlog(){
		return this.api.get<any>('/users/unlog')
			.pipe(
				tap(() => this.clear())
			);
	}

	//récupération des informations global de l'utilisateur courant
	userGlobals(id: string=null){
		return this.api.get<any>(`/admin/user-globals${((id)? '/'+id : '')}`);
	}

	getPays(){
		if(this.pays)
			return of(this.pays);
		else
			return this.paysObs;
	}

	getPaysObs(){
		return this.api.get<Pays[]>('/pays')
			.pipe(
				shareReplay(1),
				tap(pays => this.pays=pays)
			);
	}

	getUsers(search: UserFilter, start: number=0, length: number=50){
		let params: any=search;
		params.start=start;
		params.length=length;
		return this.api.get<User[]>('/users', params);
	}

	getUser(id: string){
		return this.api.get<User>(`/users/${id}`);
	}

	//modification d'un utilisateur
	setUser(user: User){
		return this.api.post<User>(`/users/${user.ident}`, user);
	}

	//modification de l'utilisateur courant
	setUserInfo(user: User){
		return this.setUser(user)
			.pipe(
				tap(u => {
					if(u.ident==this.user.ident)
						this.user=u
				})
			);
	}

	addAddress(address: Address){
		return this.api.post<Address>('/addresses', address);
	}

	getUserAddress(user: string=null){
		return this.api.get<Address>(`/addresses${((user)? '/'+user : '')}`);
	}

	rmAddress(ident: string){
		return this.api.delete<Address>(`/addresses/${ident}`);
	}

	getRolesObs(){
		return this.api.get<Role[]>('/roles')
			.pipe(
				shareReplay(1),
				tap(roles => this.roles=roles)
			);
	}

	getRoles(){
		if(this.roles)
			return of(this.roles);
		else
			return this.rolesObs;
	}

	getRole(ident: string){
		if(this.roles)
			return of(this.roles.find(r => r.ident==ident));
		else
			return this.getRoles()
				.pipe(
					map((roles: Role[]) => roles.find(r => r.ident==ident))
				);
	}

	setRole(role: Role){
		let update: boolean=(role.ident!=null);

		//url
		let url: string='/roles';
		if(update)
			url+='/'+role.ident;

		//query
		return this.api.post<Role>(url, role)
			.pipe(
				tap(role => this.replaceRole(role, update))
			);
	}

	rmRole(ident: string){
		return this.api.delete<Role>(`/roles/${ident}`)
			.pipe(
				tap(() => {
					if(this.roles)
						this.roles.splice(this.roles.findIndex(r => r.ident==ident), 1);
				})
			);
	}

	//modification du role ans la liste en local 
	replaceRole(role: Role, update: boolean){
		if(this.roles){
			if(!update)
				this.roles.push(role);
			else{
			    let ind: number=this.roles.findIndex(e => e.ident==role.ident);
			    if(ind!=-1)
			      this.roles.splice(ind, 1, role);
			}
		}
	}

	getGroupsObs(){
		return this.api.get<Group[]>('/customers/groups')
			.pipe(
				shareReplay(1),
				tap(groups => this.groups=groups)
			);
	}

	getGroups(){
		if(this.groups)
			return of(this.groups);
		else
			return this.groupsObs;
	}

	getGroup(id: string){
		return this.api.get<Group>(`/customers/groups/${id}`);
	}

	setGroup(group: Group){
		let url: string='/customers/groups';
		if(group.ident)
			url+='/'+group.ident;
		return this.api.post<Group>(url, group)
			.pipe(
				tap(result => {
					if(group.ident && this.groups)
						this.groups.splice(this.groups.findIndex(g => group.ident==g.ident), 1);
					if(!group.ident && this.groups)
						this.groups.push(group);
				})
			);
	}

	rmGroup(id: string){
		return this.api.delete<Group>(`/customers/groups/${id}`)
			.pipe(
				tap(() => {
					if(this.groups)
						this.groups.splice(this.groups.findIndex(group => group.ident==id), 1);
				})
			);
	}

	getRightsObs(){
		return this.api.get<Right[]>('/rights')
			.pipe(
				shareReplay(1),
				tap(rights => this.rights=rights)
			);
	}

	getRights(){
		if(this.rights)
			return of(this.rights);
		else
			return this.rightsObs;
	}

	listNewsletter(start: number, length: number){
		return this.api.get<Newsletter>('/newletters/subscribers', {start, length});
	}

	setNewsletter(news: Newsletter){
		return this.api.post<Newsletter>(`/newletters/subscribe/${news.email}`, news);
	}

	export(){
		return this.api.getCSVFile('/newletters/exports');
	}

	subscribe(news: Newsletter){
		return this.api.post<Newsletter>('/newletters/subscribe', news);
	}
}