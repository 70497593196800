import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UiModule } from '@components/ui/ui.module';
import { PartialModule } from '@components/page/partial/partial.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StateOrderModule } from './state-order/state-order.module';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderPageComponent } from './order-page/order-page.component';
import { OrderFilterComponent } from './order-filter/order-filter.component';
import { RefundListComponent } from './refund-list/refund-list.component';
import { RefundFormComponent } from './refund-form/refund-form.component';


@NgModule({
  declarations: [
    OrderListComponent,
    OrderPageComponent,
    OrderFilterComponent,
    RefundListComponent,
    RefundFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UiModule,
    StateOrderModule,
    PartialModule
  ],
  exports: [
    StateOrderModule,
    OrderListComponent,
    OrderPageComponent
  ]
})
export class OrderModule { }
