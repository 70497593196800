import { Component, OnInit, Input } from '@angular/core';
import { NotifyService, Notification } from '@services/notify.service';

declare var bootstrap: any;

@Component({
  selector: 'gob-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  notif: Notification;

  constructor(private notify: NotifyService) {
    this.notif=new Notification('Notification', 'Ceci est une notification toast !');
    this.notify.notify.subscribe((notif: Notification) => {
      this.notif=notif;
      this.showToast();
    });
  }

  ngOnInit(): void {
  }

  showToast() {
    const toastEl=document.getElementById('myToast');
    const toast=new bootstrap.Toast(toastEl);
    toast.show();
  }

  hideToast() {
    const toastEl=document.getElementById('myToast');
    const toast=new bootstrap.Toast(toastEl);
    toast.hide();
  }

}
