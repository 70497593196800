<div class="products-container">
	<div *ngFor="let product of products" class="product">
		<a [routerLink]="route.parse('product', [product.ident])" [queryParams]="getQueryParam(product, product | getPrices:0)" class="img-container" [title]="product.name">
			<img [src]="getImage(product)" [alt]="product.name"/>
			<div class="banner-container">
				<gob-banner [banners]="product.banners"></gob-banner>
			</div>
		</a>
		<div class="info-container">
			<a [routerLink]="route.parse('product', [product.ident])" [queryParams]="getQueryParam(product, product | getPrices:0)" class="product-link" [title]="product.name">
				<span class="product-title">
					{{product.name}}
				</span>
				<gob-stars [stars]="product.stars"></gob-stars>
				<span class="product-price">
					<span *ngIf="product | getPrices:0">
						{{product | getPrices:0 | formatPrice}}€
					</span>
					<span>
						<i class="fad fa-timer"></i>{{product.duration}}
					</span>
					<span>
						<i class="fad fa-cake-candles"></i>{{product.age}}+
					</span>
					<span>
						<i class="fad fa-swords"></i>{{product.players}}
					</span>
				</span>
				<span class="subtitle truncate-2l">
					{{product.subtitle}}
				</span>
			</a>
			<div *ngIf="product | getPrices:0" class="add-cart">
				<button type="button" (click)="addCart(product)" class="btn green">Ajouter au panier</button>
			</div>
		</div>
	</div>
</div>
