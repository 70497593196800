<div class="list-container" [loading]="loading">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Nom</th>
        <th scope="col">Code</th>
        <th scope="col">Type</th>
        <th scope="col">Montant</th>
        <th scope="col">Etat</th>
        <th scope="col"><i class="green fa-solid fa-pen-to-square"></i></th>
        <th scope="col"><i class="red fa-solid fa-trash"></i></th>
      </tr>
    </thead>
    <tbody class="table-group-divider">
      <tr *ngFor="let discount of discounts">
        <td>{{discount.name}}</td>
        <td>{{discount.codeNumber}}</td>
        <td>{{discount.type}}</td>
        <td>{{discount.amount}}</td>
        <td>{{discount.state}}</td>
        <td>
        	<a href="" [routerLink]="route.parse('promo-set', [discount.ident])"><i class="green fa-solid fa-pen-to-square"></i></a>
        </td>
        <td>
        	<a (click)="disable(discount.ident)"><i class="red fa-solid fa-trash"></i></a>
        </td>
      </tr>
    </tbody>
  </table>
</div>