<div [loading]="loading" class="form-container">
	<!-- prix d'achat du produit -->
	<div class="form-floating mb-3">
	  <input type="number" class="form-control" id="product-achat" placeholder="Prix d'achat" name="prixAchat" [(ngModel)]="product.prixAchat" required />
	  <label for="product-achat">Prix d'achat</label>
	</div>

	<!-- tva du produit -->
	<div class="form-floating mb-3">
	  <input type="number" class="form-control" id="product-tva" placeholder="Taux de TVA" name="tva" [(ngModel)]="product.tva" required />
	  <label for="product-tva">Taux de TVA</label>
	</div>

	<!-- limit order -->
	<div class="form-floating mb-3">
	  <input type="number" class="form-control" id="product-limitorder" placeholder="Qte limite par commande" name="limitOrder" [(ngModel)]="product.limitOrder" />
	  <label for="product-limitorder">Qte limite par commande</label>
	</div>

	<!-- tarifs -->
	<div *ngIf="!product.prices.length">
		Aucun tarif
	</div>
	<div *ngIf="product.prices.length">
	  <div class="title-content green">
	  	Tarifs :
	  </div>
	  <div class="accordion" id="price-list">
		  <div *ngFor="let price of product.prices; index as i;" class="accordion-item">
		    <h2 class="accordion-header">
		      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#price-'+i" aria-expanded="false" [attr.aria-controls]="'price-'+i">
		        x{{price.qte}} : {{price.prix}}€
		      </button>
		    </h2>
		    <div id="{{'price-'+i}}" class="accordion-collapse collapse" data-bs-parent="#price-list">
		      <div class="accordion-body">
				<!-- qte -->
				<div class="form-floating mb-3">
				  <input type="text" class="form-control" id="price-qte-{{i}}" placeholder="Qte" name="qte{{i}}" [(ngModel)]="price.qte" required/>
				  <label for="price-qte-{{i}}">Qte</label>
				</div>

				<!-- price -->
				<div class="form-floating mb-3">
				  <input type="text" class="form-control" id="price-amount-{{i}}" placeholder="Montant TTC" name="amountttc{{i}}" [(ngModel)]="price.prix" required/>
				  <label for="price-amount-{{i}}">Montant TTC</label>
				</div>

				<!-- client groupe -->
			    <div class="form-floating mb-3">
			      <select class="form-select" id="price-group-{{i}}" name="groupclient{{i}}" [(ngModel)]="price.group">
			        <option [ngValue]="null">Tous les clients</option>
			        <option *ngFor="let group of groups" [ngValue]="group.ident">{{group.name}}</option>
			      </select>
			      <label for="price-group-{{i}}">Groupe client</label>
			    </div>

			    <!-- date start -->
				<div class="form-floating mb-3">
				  <input type="date" class="form-control" id="price-start-{{i}}" placeholder="Début" name="pricestart{{i}}" [(ngModel)]="price.start"/>
				  <label for="price-start-{{i}}">Début</label>
				</div>

				<!-- date end -->
				<div class="form-floating mb-3">
				  <input type="date" class="form-control" id="price-end-{{i}}" placeholder="Fin" name="priceend{{i}}" [(ngModel)]="price.end"/>
				  <label for="price-end-{{i}}">Fin</label>
				</div>

				<!-- isRemise -->
				<div class="form-check form-switch mb-3">
				  <input class="form-check-input" type="checkbox" id="product-isRemise-{{i}}" name="isRemise{{i}}" [(ngModel)]="price.isRemise"/>
				  <label class="form-check-label" for="product-isRemise-{{i}}">Tarif non soumis au remise</label>
				</div>

				<div *ngIf="price.img" class="mb-3">
				  	<div class="img-container">
				  		<img [src]="getImage(price)"/>
				  		<a (click)="rmImage(price)" class="close-image"><i class="fa-sharp fa-solid fa-circle-xmark"></i></a>
				  	</div>
				</div>

				<div class="mb-3">
				  <input type="file" (change)="onFileSelected($event, price, i)"/>
				</div>

		       	<a (click)="rm(i)" class="btn btn-danger">Supprimer</a>
		      </div>
		    </div>
		  </div>
		</div>
	</div>
	

	<div class="add-price mb-3">
		<a (click)="add()">
			<i class="fa-solid fa-layer-plus"></i> Ajouter une tarification
		</a>
	</div>
</div>