import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { RouteService } from '@services/route.service';
import { UserService } from '@services/user.service';
import { CartService } from '@services/cart.service';
import { Address, Pays } from '@models/user';
import { Order, Transaction} from  '@models/order';

@Component({
  selector: 'gob-cart-info',
  templateUrl: './cart-info.component.html',
  styleUrls: ['./cart-info.component.css']
})
export class CartInfoComponent implements OnInit {
  loading: boolean=false;
  openShip: boolean=false;
  sameInvoice: boolean=true;
  openInvoice: boolean=false;
  addresses: Address[]=[];
  pays: Pays[]=[];
  order: Order;

  constructor(public route: RouteService, public userServ: UserService, private cart: CartService) {
    if(!this.userServ.isConnected())
      this.route.navigate('login', [], {redirect: 'cart-info'});
    else{
      this.loading=true;
      this.order=this.cart.getOrder();
      forkJoin({
        addresses: this.userServ.getUserAddress(),
        pays: this.userServ.getPays()
      }).subscribe(values => {
        this.addresses=values.addresses;
        if(this.addresses.length && !this.order.dCollect)
          this.order.address=this.addresses[0].ident;
        this.pays=values.pays;
        this.loading=false;
      });
    }
  }

  ngOnInit(): void {
  }

  getPays(i: number){
    return this.pays.find(pays => pays.id==i).name;
  }

  addAddress(address: Address){
    this.addresses.push(address);
    this.openShip=false;
    this.openInvoice=false;
  }

  setInvoice(input: any){
    if(input.target.checked)
      this.order.addressInvoice=null;
  }

  next(){
    this.cart.setOrder(this.order);
    this.route.navigate('cart-payment');
  }

}
