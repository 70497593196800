export class UserFilter{
	name?: string;
	lastname?: string;
	email?: string;
	group?: string;
	role?: string;
}

export class OrderFilter{
	email?: string;
	dateStart?: string;
	dateEnd?: string;
	shop?: string;
	state?: string;
}

export class RatingFilter{
	start?: number;
	length?: number;
	dateStart?: string;
	dateEnd?: string;
	state?: string;
}

export class StatFilter{
	start?: string;
	end?: string;
}

export class ProductFilter{
	name?: string;
	fullname?: string;
	categ?: string;
	scateg?: string;
	gamme?: string;
	marque?: string;
	tag?: string;
	priceMin?: number=0;
	priceMax?: number=500;
	yearMin?: number=0;
	yearMax?: number=18;
	dMin: number=0;
	dMax: number=300;
	pMin: number=0;
	pMax: number=18;
	news: boolean;
	promo: boolean;

	constructor(type?: string, val?: string){
		switch(type){
			case 'name':
				this.name=val;
				break;
			case 'categ':
				this.categ=val;
				break;
			case 'scateg':
				this.scateg=val;
				break;
			case 'gamme':
				this.gamme=val;
				break;
			case 'marque':
				this.marque=val;
				break;
			case 'tag':
				this.tag=val;
				break;
			default:
				break;
		}
	}
}