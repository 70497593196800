import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'gob-stars',
  templateUrl: './stars.component.html',
  styleUrls: ['./stars.component.css']
})
export class StarsComponent implements OnInit {
  _stars: number;
  @Input()
  set stars(val: number){
    this._stars=Math.floor(val);
  };

  get stars(){
    return this._stars;
  }

  constructor() {
    this.stars=0;
  }

  ngOnInit(): void {
  }

}
