<article class="block green no-paddng">
	<div class="text-container">
		<div class="timer-container">
			<gob-timer *ngIf="time" [endDate]="time"></gob-timer>
			<header>
				<h2 class="title light">{{title}}</h2>
			</header>
			<div class="text">
				{{details}}<br/>
			</div>
			<div class="button">
				<a [href]="url" class="border-gradient btn btn-light">En savoir +</a>
			</div>
		</div>
	</div>
	<div class="img-container">
		<img [src]="getImage()" alt="ventes privées"/>
	</div>
</article>
