<div [loading]="loading">
	<gob-header></gob-header>
	<div class="block form-admin">
		<gob-step-order [ind]="0"></gob-step-order>
		<div *ngIf="!cart.getLines().length" class="no-content">
			Votre panier est vide.
		</div>
		<div *ngIf="cart.getLines().length"> 
			<div class="msg-container">
				<gob-message [error]="error"></gob-message>
			</div>
			<h2 class="admin">Votre panier</h2>
			<table class="table">
				<thead>
					<tr>
					  <th scope="col"></th>
					  <th scope="col">Produit</th>
					  <th scope="col">Qte</th>
					  <th scope="col">Total</th>
					  <th scope="col"><i class="red fa-solid fa-trash"></i></th>
					</tr>
				</thead>
				<tbody class="table-group-divider">
					<tr *ngFor="let line of cart.getLines(); index as i;">
						<td>
							<div class="img-container">
								<img [src]="getImage(line.product)" title="{{line.product.name}}"/>
							</div>
						</td>
						<td>
							{{line.price.qte}}x {{line.product.name}}
						</td>
						<td>
							<input type="number" (change)="cart.save()" class="form-control" value="line.qte" [(ngModel)]="line.qte" min="1" [max]="cart.maxQte(line.product, line.price)"/>
						</td>
						<td>
							{{(line.qte*line.price.prix) | formatPrice}}€
						</td>
						<td>
							<a (click)="cart.rm(i)"><i class="red fa-solid fa-trash"></i></a>
						</td>
					</tr>
					<tr>
						<td colspan="4"></td>
						<td class="discount-cell">
						  <input type="text" class="form-control" id="discount" placeholder="Code promo" name="dicount" [formControl]="discountControl"/>
						</td>
					</tr>
					<tr *ngIf="discount">
						<td colspan="3"></td>
						<td>
							<b>Remise</b>
						</td>
						<td>
							<b>{{getDiscount()}}€</b>
						</td>
					</tr>
					<tr>
						<td colspan="3"></td>
						<td>
							<b>Total</b>
						</td>
						<td>
							<b>{{getTotal()}}€</b>
						</td>
					</tr>
				</tbody>
			</table>
			<div class="next">
				<a (click)="next()" class="next btn green">
					Valider
				</a>
			</div>
		</div>
	</div>
</div>