export class User{
    ident: string;
    img?: string;
	lastname: string;
	name: string;
	birth: string;
	email: string;
	phone?: string;
	pass?: string;
	groupe: string;
    rights: string[]=[];
    roles: string[]=[];
}

export class Newsletter{
	email: string;
	actif: boolean;
}

export class Role{
	ident: string=null;
	name: string;
	rights: string[]=[];
}

export class Right{
	name: string;
	libelle: string;
}

export class Group{
	ident: string;
	name: string;
}

export class Address{
	ident: string;
	firstname: string;
	lastname: string;
	social?: string;
	address: string;
	cp: string;
	city: string;
	pays: number=72;
	state: string;
}

export class Pays{
	id: number;
	name: string;
	prefixe: string;
	codeIso: string;
}