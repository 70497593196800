import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '../../../ui/ui.module';
import { OrderStateListComponent } from './order-state-list/order-state-list.component';
import { OrderStateFormComponent } from './order-state-form/order-state-form.component';
import { OrderStatePageComponent } from './order-state-page/order-state-page.component';



@NgModule({
  declarations: [
    OrderStateListComponent,
    OrderStateFormComponent,
    OrderStatePageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UiModule
  ],
  exports: [
    OrderStatePageComponent
  ]
})
export class StateOrderModule { }
