<form (ngSubmit)="search.emit(filter)" #form="ngForm">
	<div class="row g-3">
		<!-- date de début -->
	  	<div class="col-sm">
			<div class="form-floating mb-3 nsb">
			  <input type="date" class="form-control" id="review-start" placeholder="Depuis" name="start" [(ngModel)]="filter.dateStart"/>
			  <label for="review-start">Depuis</label>
			</div>
		</div>

		<!-- debut de fin -->
	  	<div class="col-sm">
			<div class="form-floating mb-3 nsb">
			  <input type="date" class="form-control" id="review-end" placeholder="Jusqu'à" name="end" [(ngModel)]="filter.dateEnd"/>
			  <label for="review-end">Jusqu'à</label>
			</div>
		</div>

		<!-- state -->
	  	<div class="col-sm">
	      <div class="form-floating mb-3 nsb">
	    	<select class="form-select" id="review-state" name="state" [(ngModel)]="filter.state">
	          <option [ngValue]="null || undefined">Tous les états</option>
	          <option value="review">En attente d'approbation</option>
	          <option value="actif">En ligne</option>
	          <option value="supprimé">Supprimé</option>
	        </select>
	        <label for="review-state">Etat</label>
	      </div>
		</div>

	  	<div class="col-sm-1">
	  		<button type="submit" class="btn btn-primary search">
	  			<i class="fa-light fa-magnifying-glass"></i>
	  		</button>
	  	</div>
	</div>
</form>