import { Component, OnInit } from '@angular/core';
import { GlobalService } from '@services/global.service';
import { Setting } from '@models/global';
import { environment } from '@environments/environment';

@Component({
  selector: 'gob-actu',
  templateUrl: './actu.component.html',
  styleUrls: ['./actu.component.css']
})
export class ActuComponent implements OnInit {
  time: Date;
  title: string;
  details: string;
  url: string;

  constructor(private gServ: GlobalService) {
    this.gServ.getSettings().subscribe(values => {
      this.time=new Date(values.find(setting => setting.name=='home.countdown.end').val);
      this.title=values.find(setting => setting.name=='home.countdown.titre').val;
      this.details=values.find(setting => setting.name=='home.countdown.details').val;
      this.url=values.find(setting => setting.name=='home.countdown.url').val;
    });
  }

  ngOnInit(): void {
  }

  getImage(){
    return `${environment.imgUrl}/skin/countdown.jpg`;
  }
}
