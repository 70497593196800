import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData, DatePipe } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { HighchartsChartModule } from 'highcharts-angular';
import { environment } from '@environments/environment';

import { ROUTES } from './app.routes';

import { AppComponent } from './app.component';
import { PageModule } from './components/page/page.module';
import { AdminModule } from './components/admin/admin.module';
import { UiModule } from './components/ui/ui.module';
import { NotConnectedInterceptor } from './core/interceptor/not-connected.interceptor';
import { AccessTokenInterceptor } from './core/interceptor/access-token.interceptor';
import { GetPricesPipe } from '@pipes/price.pipe';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(ROUTES),
    PageModule,
    AdminModule,
    UiModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HighchartsChartModule
  ],
  providers: [
    GetPricesPipe,
    DatePipe,
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: HTTP_INTERCEPTORS, useClass: NotConnectedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AccessTokenInterceptor, multi: true },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
