import { Component, OnInit } from '@angular/core';
import { RouteService } from '@services/route.service';

@Component({
  selector: 'gob-order-state-page',
  templateUrl: './order-state-page.component.html',
  styleUrls: ['./order-state-page.component.css']
})
export class OrderStatePageComponent implements OnInit {

  constructor(public route: RouteService) { }

  ngOnInit(): void {
  }

}
