import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { forkJoin } from 'rxjs';
import { UserService } from '@services/user.service';
import { Group, Role } from '@models/user';
import { UserFilter } from '@models/filter';

@Component({
  selector: 'gob-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.css']
})
export class UserFilterComponent implements OnInit {
  groups: Group[]=[];
  roles: Role[]=[];
  filter: UserFilter=new UserFilter();
  @Output() readonly search: EventEmitter<UserFilter>=new EventEmitter<UserFilter>();

  constructor(private userServ: UserService) {
    forkJoin({
      groups: this.userServ.getGroups(),
      roles: this.userServ.getRoles()
    }).subscribe(values => {
      this.groups=values.groups;
      this.roles=values.roles;
    });
  }

  ngOnInit(): void {
  }

}
