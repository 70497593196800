<div class="block form-admin" [loading]="loading">
	<gob-back *ngIf="!admin" [url]="route.get('my-account')"></gob-back>
	<gob-back *ngIf="admin" [url]="route.parse('user-set', [admin])"></gob-back>
	<h1 *ngIf="!admin" class="admin">Mes adresses</h1>
	<h1 *ngIf="admin" class="admin">Les adresses</h1>
	<div class="form-content">
		<div class="form-admin-left">
			<gob-message [error]="error" [msg]="msg"></gob-message>
			<div *ngIf="!addresses.length">
				Aucune adresse
			</div>
			<!-- addresse list -->
			<div *ngIf="addresses.length" class="accordion" id="addresses-list">
			  <div *ngFor="let address of addresses; index as i;" class="accordion-item">
			    <h2 class="accordion-header">
			      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#address-'+i" aria-expanded="false" [attr.aria-controls]="'address-'+i">
			        <span *ngIf="address.social">{{address?.social}},&nbsp;</span>{{address.firstname}} - {{address.lastname}} 
			      </button>
			    </h2>
			    <div id="{{'address-'+i}}" class="accordion-collapse collapse" data-bs-parent="#addresses-list">
			      <div class="accordion-body">
			      	<!-- Prénom -->
			       	<div>
			       		<span class="title-content">Prénom : </span>{{address.firstname}}
			       	</div>
			      	<!-- nom -->
			       	<div>
			       		<span class="title-content">Nom : </span>{{address.lastname}}
			       	</div>
			      	<!-- social -->
			       	<div *ngIf="address.social">
			       		<span class="title-content">Raison sociale : </span>{{address.social}}
			       	</div>
			      	<!-- address -->
			       	<div>
			       		<span class="title-content">Adresse : </span>{{address.address}}
			       	</div>
			      	<!-- CP -->
			       	<div>
			       		<span class="title-content">Code postal : </span>{{address.cp}}
			       	</div>
			      	<!-- city -->
			       	<div>
			       		<span class="title-content">Ville : </span>{{address.city}}
			       	</div>
			      	<!-- Pays -->
			       	<div>
			       		<span class="title-content">Pays : </span>{{getPays(address.pays).name}}
			       	</div>
			       	<a *ngIf="!admin" (click)="rm(address.ident)" class="btn btn-danger">Supprimer</a>
			      </div>
			    </div>
			  </div>
			</div>
		</div>
		<div class="form-admin-right">
		</div>
	</div>
</div>