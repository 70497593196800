import { Component, OnInit } from '@angular/core';
import { formatNumber } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { OrderService } from '@services/order.service';
import { RouteService } from '@services/route.service';
import { StockService } from '@services/stock.service';
import { Order, StateOrder } from '@models/order';
import { Depot } from '@models/stock';
import { OrderFilter } from '@models/filter';

@Component({
  selector: 'gob-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class OrderListComponent implements OnInit {
  loading: boolean=false;
  orders: Order[]=[];
  _filter: OrderFilter=new OrderFilter();
  size: number=50;
  max: number=0;
  page: number=0;
  admin: boolean=false;
  states: StateOrder[]=[];
  depots: Depot[]=[];

  set filter(value: OrderFilter){
    this._filter=value;
    this.search(0);
  }

  get filter(){
    return this._filter;
  }

  constructor(
      private orderServ: OrderService,
      public route: RouteService,
      private router: ActivatedRoute,
      private stockServ: StockService
    ) {
    this.loading=true;
    this.router.queryParamMap.subscribe(params => this.admin=(params.get('admin') && params.get('admin')=='true'));
    forkJoin({
      states: this.orderServ.getStates(false),
      depots: this.stockServ.getDepotsClickCollect()
    }).subscribe(values => {
      this.states=values.states;
      this.depots=values.depots;
      this.search(0);
    });
  }

  ngOnInit(): void {
  }

  search(page: number){
    this.loading=true;
    this.page=page;
    this.orderServ.search(this.admin, this.filter, page*this.size, this.size)
      .subscribe(orders => {
        this.orders=orders;
        if(this.orders.length==this.size)
          this.max=page+2;
        this.loading=false;
      });
  }

  getState(order: Order){
    return this.states.find(state => state.ident==order.state)?.name;
  }

  getShop(order: Order){
    let name: string=null;
    if(order.dCollect)
      name=this.depots.find(depot => depot.address==order.address)?.name;
    return name;
  }

}
