import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { forkJoin } from 'rxjs';
import { GlobalService } from '@services/global.service';
import { MenuItem } from '@models/global';
import { Categ, SCateg, Gamme, Marque, Tag } from '@models/product';

@Component({
  selector: 'gob-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent implements OnInit {
  private static ind: number=0;

  @Input() item: MenuItem;
  @Input() indChild: number;
  @Output() readonly top: EventEmitter<number>=new EventEmitter<number>();
  @Output() readonly down: EventEmitter<number>=new EventEmitter<number>();
  @Output() readonly rm: EventEmitter<number>=new EventEmitter<number>();
  i: number=0;
  loading: boolean=false;
  categs: Categ[]=[];
  scategs: SCateg[]=[];
  gammes: Gamme[]=[];
  marques: Marque[]=[];
  tags: Tag[]=[];
  
  constructor(private gServ: GlobalService) {
    this.i=MenuItemComponent.ind;
    MenuItemComponent.ind++;
    this.loading=true;
    forkJoin({
      categs: this.gServ.getCategs(),
      scategs: this.gServ.getSCategs(),
      gammes: this.gServ.getGammes(),
      marques: this.gServ.getMarques(),
      tags: this.gServ.getTags()
    }).subscribe(values => {
      this.categs=values.categs;
      this.scategs=values.scategs;
      this.gammes=values.gammes;
      this.marques=values.marques;
      this.tags=values.tags;
      this.loading=false;
    });
  }

  ngOnInit(): void {
  }

  emitDown(i: number){
    //si !element 1er niveau
    if(!this.indChild)
      this.down.emit(this.item.ind);
    else
      this.gServ.getMenu().subscribe(items => this.downItem(i, items));
  }

  emitTop(i: number){
    //si !element 1er niveau
    if(!this.indChild)
      this.top.emit(this.item.ind);
    else
      this.gServ.getMenu().subscribe(items => this.topItem(i, items));
  }

  emitRm(i: number){
    //si !element 1er niveau
    if(!this.indChild)
      this.rm.emit(this.item.ind);
    else
      this.gServ.getMenu().subscribe(items => this.rmItem(i, items));
  }

  topItem(i: number, children: MenuItem[]){
    //get item
    let item: MenuItem=children[i];

    //si item !first element
    if(item.ind!=Math.min(...children.map(child => child.ind))){
      //sort items
      GlobalService.sortItems(children);

      //get new pos
      i=children.findIndex(e => e.ind==item.ind);

      //switch ind
      let newInd: number=children[i-1].ind;
      children[i-1].ind=item.ind;
      item.ind=newInd;

      GlobalService.sortItems(children);
    }
  }

  downItem(i: number, children: MenuItem[]){
    //get item
    let item: MenuItem=children[i];

    //si item !last element
    if(item.ind!=Math.max(...children.map(child => child.ind))){
      //sort items
      GlobalService.sortItems(children);

      //get new pos
      i=children.findIndex(e => e.ind==item.ind);

      //switch ind
      let newInd: number=children[i+1].ind;
      children[i+1].ind=item.ind;
      item.ind=newInd;
      
      GlobalService.sortItems(children);
    }
  }

  rmItem(i: number, children: MenuItem[]){
    children.splice(i, 1);
  }

  addItem(){
    GlobalService.addItem(this.item.children);
  }

}
