import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@services/user.service';
import { RouteService } from '@services/route.service';
import { User } from '@models/user';

@Component({
  selector: 'gob-sign-form',
  templateUrl: './sign-form.component.html',
  styleUrls: ['./sign-form.component.css']
})
export class SignFormComponent implements OnInit {
  @Output() readonly login: EventEmitter<boolean>=new EventEmitter<boolean>();
  error: string;
  loading: boolean=false;
  token: string;

  constructor(private userServ: UserService, private route: RouteService, private router: ActivatedRoute) { }

  ngOnInit(): void {
  }

  signup(user: User){
    this.loading=true;
    this.userServ.signup(user, this.token).subscribe({
      next: () => {
        this.loading=false;
        let redirect: string=this.router.snapshot.queryParamMap.get('redirect');
        if(redirect)
          this.route.navigate(redirect);
        else
          this.route.navigate('admin-home');
      },
      error: error => {
        this.loading=false;
        this.error=error;
      }
    });
  }

}
