<article class="block">
	<div class="anim-part">
		<header>
			<h2 class="title">Prochaines <br/> Animations</h2>
		</header>
		<div class="subtitle">
			Des tournois épiques et des animations captivantes. Plongez dans l'avantue du jeu.
		</div>
		<div class="button hidden-phone">
			<a class="btn green btn-primary">Voirs les animations</a>
		</div>
	</div>
	<div class="anim-container">
		<gob-anim-list [animations]="animations"></gob-anim-list>
	</div>
	<div class="button display-phone">
		<a class="btn green btn-primary">Voirs les animations</a>
	</div>
</article>
