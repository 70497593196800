import { Routes } from '@angular/router';
import { RightGuard } from './core/guard/right.guard';
import { ConnectedGuard } from './core/guard/connected.guard';
import { HomePageComponent } from './components/page/home/home-page/home-page.component';
import { ProductListComponent } from './components/page/products/product-list/product-list.component';
import { ProductPageComponent } from './components/page/products/product-page/product-page.component';
import { BarPageComponent } from './components/page/bar/bar-page/bar-page.component';
import { ConnectPageComponent } from './components/page/connect/connect-page/connect-page.component';
import { HomeComponent as HomeAdmin } from './components/admin/home/home.component';
import { MyAccountComponent } from './components/admin/user/my-account/my-account.component';
import { MyAddressesComponent } from './components/admin/user/my-addresses/my-addresses.component';
import { RolePageComponent } from './components/admin/user/user-role/role-page/role-page.component';
import { RoleListComponent } from './components/admin/user/user-role/role-list/role-list.component';
import { RoleFormComponent } from './components/admin/user/user-role/role-form/role-form.component';
import { PromoPageComponent } from './components/admin/promo-code/promo-page/promo-page.component';
import { PromoListComponent } from './components/admin/promo-code/promo-list/promo-list.component';
import { PromoFormComponent } from './components/admin/promo-code/promo-form/promo-form.component';
import { OrderStatePageComponent } from './components/admin/order/state-order/order-state-page/order-state-page.component';
import { OrderStateListComponent } from './components/admin/order/state-order/order-state-list/order-state-list.component';
import { OrderStateFormComponent } from './components/admin/order/state-order/order-state-form/order-state-form.component';
import { ShipperListComponent } from './components/admin/shipping/shipper-list/shipper-list.component';
import { ShipperFormComponent } from './components/admin/shipping/shipper-form/shipper-form.component';
import { ShipperPageComponent } from './components/admin/shipping/shipper-page/shipper-page.component';
import { MenuPageComponent } from './components/admin/menu/menu-page/menu-page.component';
import { ClassPageComponent } from './components/admin/classify/class-page/class-page.component'; 
import { ClassListComponent } from './components/admin/classify/class-list/class-list.component'; 
import { CategFormComponent } from './components/admin/classify/categ-form/categ-form.component'; 
import { ScategFormComponent } from './components/admin/classify/scateg-form/scateg-form.component'; 
import { GammeFormComponent } from './components/admin/classify/gamme-form/gamme-form.component'; 
import { MarqueFormComponent } from './components/admin/classify/marque-form/marque-form.component'; 
import { TagFormComponent } from './components/admin/classify/tag-form/tag-form.component'; 
import { BannerFormComponent } from './components/admin/classify/banner-form/banner-form.component'; 
import { UserListComponent } from './components/admin/user/user-list/user-list.component'; 
import { UserAdminComponent } from './components/admin/user/user-admin/user-admin.component'; 
import { DepotPageComponent } from './components/admin/stock/depot-page/depot-page.component';
import { DepotListComponent } from './components/admin/stock/depot-list/depot-list.component';
import { DepotFormComponent } from './components/admin/stock/depot-form/depot-form.component';
import { ProductPageComponent as ProductAdmin } from './components/admin/product/product-page/product-page.component';
import { ProductListComponent as ProductListAdmin } from './components/admin/product/product-list/product-list.component';
import { ProductFormComponent } from './components/admin/product/product-form/product-form.component';
import { CartComponent } from './components/page/order/cart/cart.component';
import { CartInfoComponent } from './components/page/order/cart-info/cart-info.component';
import { CartShipperComponent } from './components/page/order/cart-shipper/cart-shipper.component';
import { PaymentComponent } from './components/page/order/payment/payment.component';
import { OkPaymentComponent } from './components/page/order/ok-payment/ok-payment.component';
import { KoPaymentComponent } from './components/page/order/ko-payment/ko-payment.component';
import { OrderListComponent } from './components/admin/order/order-list/order-list.component';
import { OrderPageComponent } from './components/admin/order/order-page/order-page.component';
import { SettingFormComponent } from './components/admin/setting/setting-form/setting-form.component';
import { NewsletterListComponent } from './components/admin/user/newsletter-list/newsletter-list.component';
import { ReviewListComponent } from './components/admin/review/review-list/review-list.component';
import { ReviewPageComponent } from './components/admin/review/review-page/review-page.component';
import { DashboardPageComponent } from './components/admin/dashboard/dashboard-page/dashboard-page.component';
import { GroupListComponent } from './components/admin/group/group-list/group-list.component';
import { GroupFormComponent } from './components/admin/group/group-form/group-form.component';
import { GroupPageComponent } from './components/admin/group/group-page/group-page.component';

export const ROUTES: Routes=[
	{path: '', pathMatch: 'full', redirectTo: '/accueil'},
	{path: 'accueil', component: HomePageComponent , data: {name: 'home'}},
	{path: 'products/:type/:id/:name', component: ProductListComponent, data: {name: 'products-list'}},
	{path: 'products/:id', component: ProductPageComponent, data: {name: 'product'}},
	{path: 'cafe-jeux', component: BarPageComponent, data: {name: 'bar'}},
	{path: 'login', component: ConnectPageComponent, data: {name: 'login'}},
	{path: 'cart', component: CartComponent, data: {name: 'cart'}},
	{path: 'orders/infos', component: CartInfoComponent, data: {name: 'cart-info'}},
	{path: 'orders/shippers', component: CartShipperComponent, data: {name: 'cart-shipper'}},
	{path: 'orders/payments', canActivateChild: [ConnectedGuard], component: PaymentComponent, data: {name: 'cart-payment'}},
	{path: 'orders/payments/ko', canActivateChild: [ConnectedGuard], component: KoPaymentComponent, data: {name: 'cart-ko'}},
	{path: 'orders/payments/ok', canActivateChild: [ConnectedGuard], component: OkPaymentComponent, data: {name: 'cart-ok'}},
	{path: 'admin', canActivateChild: [ConnectedGuard], children: 
		[
			{path: 'home', component: HomeAdmin, data: {name: 'admin-home', isAdmin: true}},
			{path: 'my-account', component: MyAccountComponent, data: {name: 'my-account', isAdmin: true}},
			{path: 'my-addresses', component: MyAddressesComponent, data: {name: 'my-addresses', isAdmin: true}},
			{path: 'user-addresses/:user', component: MyAddressesComponent, canActivate: [RightGuard], data: {name: 'user-addresses', right: 'users', isAdmin: true}},
			{path: 'roles', component: RolePageComponent, canActivateChild: [RightGuard], children: [
				{path: '', pathMatch: 'full', component: RoleListComponent, data: {name: 'roles-list', right: 'users', isAdmin: true}},
				{path: 'form', component: RoleFormComponent, data: {name: 'role-form', right: 'users', isAdmin: true}},
				{path: ':id', component: RoleFormComponent, data: {name: 'role-set', right: 'users', isAdmin: true}}
			]},
			{path: 'promo-code', component: PromoPageComponent, canActivateChild: [RightGuard], children: [
				{path: '', pathMatch: 'full', component: PromoListComponent, data: {name: 'promo-list', right: 'discounts', isAdmin: true}},
				{path: 'form', component: PromoFormComponent, data: {name: 'promo-form', right: 'discounts', isAdmin: true}},
				{path: ':id', component: PromoFormComponent, data: {name: 'promo-set', right: 'discounts', isAdmin: true}}
			]},
			{path: 'orders/states', component: OrderStatePageComponent, canActivateChild: [RightGuard], children:[
				{path: '', pathMatch: 'full', component: OrderStateListComponent, data: {name: 'order-state-list', right:'orders', isAdmin: true}},
				{path: 'form', component: OrderStateFormComponent, data: {name: 'order-state-form', right: 'orders', isAdmin: true}},
				{path: ':id', component: OrderStateFormComponent, data: {name: 'order-state-set', right: 'orders', isAdmin: true}}
			]},
			{path: 'orders/list', canActivate: [RightGuard], component: OrderListComponent, data: {name: 'order-list', right: 'orders', isAdmin: true}},
			{path: 'orders/my-order', component: OrderListComponent, data: {name: 'my-order', isAdmin: true}},
			{path: 'orders/:id', component: OrderPageComponent, data: {name: 'order-set', right: 'orders-set', isAdmin: true}},
			{path: 'shippers', component: ShipperPageComponent, canActivateChild: [RightGuard], children:[
				{path: '', pathMatch: 'full', component: ShipperListComponent, data: {name: 'shipper-list', right: 'shippings', isAdmin: true}},
				{path: 'form', component: ShipperFormComponent, data: {name: 'shipper-form', right: 'shippings', isAdmin: true}},
				{path: ':id', component: ShipperFormComponent, data: {name: 'shipper-set', right: 'shippings', isAdmin: true}}
			]},
			{path: 'menu', component: MenuPageComponent, canActivate: [RightGuard], data: {name: 'menu', right: 'settings', isAdmin: true}},
			{path: 'classifications', component: ClassPageComponent, canActivateChild: [RightGuard], children: [
				{path: '', pathMatch: 'full', component: ClassListComponent, data: {name: 'class-list', right: 'products', isAdmin: true}},
				{path: 'categs', component: CategFormComponent, data: {name: 'categ-form', right: 'products', isAdmin: true}},
				{path: 'categs/:id', component: CategFormComponent, data: {name: 'categ-set', right: 'products', isAdmin: true}},
				{path: 'scategs', component: ScategFormComponent, data: {name: 'scateg-form', right: 'products', isAdmin: true}},
				{path: 'scategs/:id', component: ScategFormComponent, data: {name: 'scateg-set', right: 'products', isAdmin: true}},
				{path: 'gammes', component: GammeFormComponent, data: {name: 'gamme-form', right: 'products', isAdmin: true}},
				{path: 'gammes/:id', component: GammeFormComponent, data: {name: 'gamme-set', right: 'products', isAdmin: true}},
				{path: 'marques', component: MarqueFormComponent, data: {name: 'marque-form', right: 'products', isAdmin: true}},
				{path: 'marques/:id', component: MarqueFormComponent, data: {name: 'marque-set', right: 'products', isAdmin: true}},
				{path: 'tags', component: TagFormComponent, data: {name: 'tag-form', right: 'products', isAdmin: true}},
				{path: 'tags/:id', component: TagFormComponent, data: {name: 'tag-set', right: 'products', isAdmin: true}},
				{path: 'banners', component: BannerFormComponent, data: {name: 'banner-form', right: 'products', isAdmin: true}},
				{path: 'banners/:id', component: BannerFormComponent, data: {name: 'banner-set', right: 'products', isAdmin: true}},
			]},
			{path: 'users', component: UserListComponent, canActivate: [RightGuard], data: {name: 'user-list', right: 'users', isAdmin: true}},
			{path: 'users/:id', component: UserAdminComponent, canActivate: [RightGuard], data: {name: 'user-set', right: 'users', isAdmin: true}},
			{path: 'depots', component: DepotPageComponent, canActivateChild: [RightGuard], children: [
				{path: '', pathMatch: 'full', component: DepotListComponent, data: {name: 'depot-list', right: 'stocks', isAdmin: true}},
				{path: 'form', component: DepotFormComponent, data: {name: 'depot-form', right: 'stocks', isAdmin: true}},
				{path: ':id', component: DepotFormComponent, data: {name: 'depot-set', right: 'stocks', isAdmin: true}}
			]},
			{path: 'products', component: ProductAdmin, canActivateChild: [RightGuard], children:[
				{path: '', pathMatch: 'full', component: ProductListAdmin, data: {name: 'product-list', right: 'products', isAdmin: true}},
				{path: 'form', component: ProductFormComponent, data: {name: 'product-form', right: 'products', isAdmin: true}},
				{path: ':id', component: ProductFormComponent, data: {name: 'product-set', right: 'products', isAdmin: true}}
			]},
			{path: 'settings', canActivate: [RightGuard], component: SettingFormComponent, data: {name: 'settings', right: 'settings', isAdmin: true}},
			{path: 'newsletter', canActivate: [RightGuard], component: NewsletterListComponent, data: {name: 'newsletters', right: 'newsletter', isAdmin: true}},
			{path: 'reviews', canActivateChild: [RightGuard], children: [
				{path: '', pathMatch: 'full', component: ReviewListComponent, data: {name: 'review-list', right: 'ratings', isAdmin: true}},
				{path: ':id', component: ReviewPageComponent, data: {name: 'review-set', right: 'ratings', isAdmin: true}}
			]},
			{path: 'dashboards', component: DashboardPageComponent, canActivate: [RightGuard], data: {name: 'dashboard', right: 'dashboard', isAdmin: true}},
			{path: 'groups', component: GroupPageComponent, canActivateChild: [RightGuard], children: [
				{path: '', pathMatch: 'full', component: GroupListComponent, data: {name: 'group-list', right: 'users', isAdmin: true}},
				{path: 'form', component: GroupFormComponent, data: {name: 'group-form', right: 'users', isAdmin: true}},
				{path: ':id', component: GroupFormComponent, data: {name: 'group-set', right: 'users', isAdmin: true}}
			]}
		]
	}
]