<div [loading]="loading">
	<gob-back [url]="route.get('depot-list')"></gob-back>
	<div class="form-content">
		<form class="form-admin-left" #form="ngForm">
			<gob-message [error]="error" [msg]="msg"></gob-message>
			<!-- nom du code -->
			<div class="form-floating mb-3">
			  <input type="text" class="form-control" id="depot-name" placeholder="Nom du dépôt" name="name" [(ngModel)]="depot.name" required />
			  <label for="depot-name">Nom du dépôt</label>
			</div>

			<!-- montant du code -->
			<div class="form-floating mb-3">
			  <input type="text" class="form-control" id="depot-chr" placeholder="ID Chrono-Caisse" name="identChr" [(ngModel)]="depot.identChr"/>
			  <label for="depot-chr">ID Chrono-Caisse</label>
			</div>

			<!-- isMain -->
			<div class="form-check form-switch mb-3">
			  <input class="form-check-input" type="checkbox" id="depot-isMain" name="isMain" [(ngModel)]="depot.isMain" [disabled]="initialIsMain"/>
			  <label class="form-check-label" for="depot-isMain">Dépôt principal</label>
			</div>

			<!-- prénom du destinataire depot -->
			<div class="form-floating mb-3">
			  <input type="text" class="form-control" id="address-first" placeholder="Prénom de livraison" name="addressName" [(ngModel)]="address.firstname" required />
			  <label for="address-first">Prénom de livraison</label>
			</div>

			<!-- nom du destinataire depot -->
			<div class="form-floating mb-3">
			  <input type="text" class="form-control" id="address-last" placeholder="Nom de livraison" name="addressLastname" [(ngModel)]="address.lastname" required />
			  <label for="address-last">Nom de livraison</label>
			</div>

			<!-- raison sociale du destinataire depot -->
			<div class="form-floating mb-3">
			  <input type="text" class="form-control" id="address-social" placeholder="Raison sociale" name="sociale" [(ngModel)]="address.social" />
			  <label for="address-social">Raison sociale</label>
			</div>

			<!-- address depot -->
			<div class="form-floating mb-3">
			  <input type="text" class="form-control" id="address" placeholder="Adresse du dépôt" name="address" [(ngModel)]="address.address" required />
			  <label for="address">Adresse du dépôt</label>
			</div>

			<!-- cp depot -->
			<div class="form-floating mb-3">
			  <input type="text" class="form-control" id="address-cp" placeholder="code postal du dépôt" name="cp" [(ngModel)]="address.cp" required />
			  <label for="address-cp">code postal du dépôt</label>
			</div>

			<!-- city depot -->
			<div class="form-floating mb-3">
			  <input type="text" class="form-control" id="address-city" placeholder="Ville du dépôts" name="city" [(ngModel)]="address.city" required />
			  <label for="address-city">Ville du dépôts</label>
			</div>


            <!-- scategs -->
			<div class="form-floating mb-3">
				<select class="form-select" id="address-pays" name="pays" [(ngModel)]="address.pays" required>
				  <option [ngValue]="null">Selectionnez un pays</option>
				  <option *ngFor="let p of pays" [ngValue]="p.id">{{p.name}}</option>
				</select>
				<label for="address-pays">Selectionnez un pays</label>
			</div>

			<div class="title-content">
				Click & Collect
			</div>
			<div *ngIf="!depot.schedules.length" class="no-content">
				Aucun créneau pour le Click & Collect
			</div>
			<div *ngFor="let schedule of depot.schedules; index as i" class="row g-3">
				<!-- jour -->
			  	<div class="col-sm">
			  		<div class="form-floating mb-3 nsb">
						<select class="form-select" id="cc-day-{{i}}" name="ccday{{i}}" [(ngModel)]="schedule.day" required>
						  <option [ngValue]="null">Selectionnez un jour</option>
						  <option [ngValue]="'lundi'">Lundi</option>
						  <option [ngValue]="'mardi'">Mardi</option>
						  <option [ngValue]="'mercredi'">Mercredi</option>
						  <option [ngValue]="'jeudi'">Jeudi</option>
						  <option [ngValue]="'vendredi'">Vendredi</option>
						  <option [ngValue]="'samedi'">Samedi</option>
						  <option [ngValue]="'dimanche'">Dimanche</option>
						</select>
						<label for="cc-day-{{i}}">Selectionnez un jour</label>
					</div>
				</div>

				<!-- startH -->
			  	<div class="col-sm">
					<div class="form-floating mb-3 nsb">
					  <input type="time" class="form-control" id="cc-start-{{i}}" placeholder="Horaire d'ouverture" name="ccstart{{i}}" [(ngModel)]="schedule.startH" required/>
					  <label for="cc-start-{{i}}">Horaire d'ouverture</label>
					</div>
				</div>

				<!-- endH -->
			  	<div class="col-sm">
					<div class="form-floating mb-3 nsb">
					  <input type="time" class="form-control" id="cc-end-{{i}}" placeholder="Horaire de fermeture" name="ccend{{i}}" [(ngModel)]="schedule.endH" required/>
					  <label for="cc-end-{{i}}">Horaire d'ouverture</label>
					</div>
				</div>

			  	<div class="col-sm-1 schedule-rm">
    				<i (click)="rmSchedule(i)" class="red fa-solid fa-trash"></i>
				</div>
			</div>

			<a (click)="addSchedule()" class="add-schedule">
				<i class="fa-solid fa-layer-plus"></i> Ajouter une plage d'ouverture
			</a>

			<button scrollTo (click)="update(form)" class="btn green" [disabled]="form.invalid">{{((depot.ident)? "Modifier" : "Créer")}}</button>
			<button (click)="rm()" *ngIf="depot.ident" class="btn btn-danger">Supprimer</button>
		</form>
	</div>
</div>