import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { RouteService } from '@services/route.service';
import { UserService } from '@services/user.service';
import { Group } from '@models/user';


@Component({
  selector: 'gob-group-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.css']
})
export class GroupFormComponent implements OnInit {
  loading: boolean=false;
  error: string;
  msg: string;
  group: Group;

  constructor(private router: ActivatedRoute, private userServ: UserService, public route: RouteService) {
    this.loading=true;
    this.group=new Group();
    this.router.paramMap
      .pipe(
        switchMap(param => {
          if(param.get('id'))
            return this.userServ.getGroup(param.get('id'))
              .pipe(
                map(group => JSON.parse(JSON.stringify(group)))
              );
          else
            return of(new Group());
        })
      ).subscribe({
        next: (group: Group) => {
          this.group=group;
          this.loading=false;
        },
        error: e => {
          this.error=e;
          this.loading=false;
        }
      })
  }

  ngOnInit(): void {
  }

  update(form: any){
    this.loading=true;
    this.userServ.setGroup(this.group).subscribe({
      next: group => {
        this.msg="Modifications enregistrées";
        if(this.group.ident)
          this.group=group;
        else{
          this.group=new Group();
          form.reset();
        }
        this.loading=false;
      },
      error: e => {
        this.error=e;
        this.loading=false;
      }
    });
  }

  rm(){
    if(confirm('Êtes-vous sûr de vouloir supprimer ?')){
      this.loading=true;
      this.userServ.rmGroup(this.group.ident).subscribe({
        next: group => {
          this.loading=false;
          this.route.navigate('group-list');
        },
        error: e => {
          this.error=e;
          this.loading=false;
        }
      });
    }
  }

}
