import { Injectable } from '@angular/core';
import { ApiService } from '@services/api.service';
import { StatFilter } from '@models/filter';

@Injectable({
	providedIn: 'root'
})
export class StatService{
	constructor(private api: ApiService){}

	getStats(filter: StatFilter){
		return this.api.get<any>('/dashboards', filter);
	}
}