<div class="list-container" [loading]="loading">
  <gob-message [error]="error" [msg]="msg"></gob-message>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Nom</th>
          <th scope="col">ID Chrono-Caisse</th>
          <th scope="col">Principal</th>
          <th scope="col">Click & Collect</th>
          <th scope="col"><i class="green fa-solid fa-pen-to-square"></i></th>
          <th scope="col"><i class="red fa-solid fa-trash"></i></th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        <tr *ngFor="let depot of depots">
          <td>{{depot.name}}</td>
          <td>{{depot.identChr}}</td>
          <td>{{((depot.isMain)? 'Oui' : 'Non')}}</td>
          <td>{{((depot.schedules.length)? 'Oui' : 'Non')}}</td>
          <td>
          	<a href="" [routerLink]="route.parse('depot-set', [depot.ident])"><i class="green fa-solid fa-pen-to-square"></i></a>
          </td>
          <td>
          	<a (click)="rm(depot.ident)"><i class="red fa-solid fa-trash"></i></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>