import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RatingFilter } from '@models/filter';

@Component({
  selector: 'gob-review-filter',
  templateUrl: './review-filter.component.html',
  styleUrls: ['./review-filter.component.css']
})
export class ReviewFilterComponent implements OnInit {
  @Output() readonly search: EventEmitter<RatingFilter>=new EventEmitter<RatingFilter>();
  filter: RatingFilter=new RatingFilter();

  constructor() { }

  ngOnInit(): void {
  }

}
