import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { RouteService } from '@services/route.service';
import { UserService } from '@services/user.service';


@Component({
  selector: 'gob-log-form',
  templateUrl: './log-form.component.html',
  styleUrls: ['./log-form.component.css']
})
export class LogFormComponent implements OnInit {
  @Output() readonly signup: EventEmitter<boolean>=new EventEmitter<boolean>();
  @Output() readonly forgot: EventEmitter<boolean>=new EventEmitter<boolean>();
  error: string;
  loading: boolean=false;

  constructor(private userServ: UserService, private route: RouteService, private router: ActivatedRoute) { }

  ngOnInit(): void {
  }

  login(mail: string, pass: string){
    this.loading=true;
    this.userServ.login(mail, pass)
      .subscribe({
        next: () => {
          let redirect: string=this.router.snapshot.queryParamMap.get('redirect');
          if(redirect)
            this.route.navigate(redirect);
          else
            this.route.navigate('admin-home');
          this.loading=false;
        },
        error: (error) => {
          this.error=error;
          this.loading=false;
        }
      });
  }
}
