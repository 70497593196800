import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'gob-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnInit, OnDestroy {
  @Input() endDate: Date;
  now: Date;
  timer: any;

  constructor() {
    this.now=new Date();
  }

  ngOnInit(): void {
    this.timer=setInterval(() => this.now=new Date(), 1000);
  }

  ngOnDestroy(){
    if(this.timer)
      clearInterval(this.timer);
  }

  getDays(){
    return Math.floor(this.getDiff()/86400);
  }

  getHours(){
    return 24-(24-Math.floor((this.getDiff()%86400)/3600));
  }

  getMin(){
    return 60-(60-Math.floor(((this.getDiff()%86400)%3600)/60));
  }

  getSec(){
    return 60-(60-Math.floor(this.getDiff()%86400%3600%60));
  }

  getDiff(){
    return (this.endDate.getTime()-this.now.getTime())/1000;
  }
}
