import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '@services/user.service';
import { Product, Price } from '@models/product';
import { Group } from '@models/user';
import { environment } from '@environments/environment';

@Component({
  selector: 'gob-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.css']
})
export class ProductPriceComponent implements OnInit {
  @Input() product: Product;
  loading: boolean=false;
  groups: Group[]=[];

  constructor(private userServ: UserService) {
    this.loading=true;
    this.userServ.getGroups().subscribe(groups => {
      this.groups=groups;
      this.loading=false;
    });
  }

  ngOnInit(): void {
  }

  rm(i: number){
    this.product.prices.splice(i, 1);
  }

  add(){
    this.product.prices.push(new Price());
  }

  onFileSelected(event: any, price: Price, i: number) {
    const file=event.target.files[0];
    price.priceImageFile=file;
  }

  getImage(price: Price){
    return `${environment.imgUrl}/product/${price.img}`;
  }

  rmImage(price: Price){
    price.img=null;
    price.priceImageFile=null;
    if(price.ident)
      price.rmImage=true;
  }
}
