<form (ngSubmit)="search.emit(filter)" #form="ngForm">
	<div class="row g-3">
		<!-- nom -->
	  	<div class="col-sm">
			<div class="form-floating mb-3 nsb">
			  <input type="text" class="form-control" id="user-filter-name" placeholder="Nom, ref..." name="name" [(ngModel)]="filter.name"/>
			  <label for="user-filter-name">Nom, ref...</label>
			</div>
		</div>

		<!-- categ -->
	  	<div class="col-sm">
	      <div class="form-floating mb-3 nsb">
	    	<select class="form-select" id="product-filter-categ" name="categ" [(ngModel)]="filter.categ">
	          <option [ngValue]="null || undefined">Toutes les catégories</option>
	          <option *ngFor="let categ of categs" [ngValue]="categ.ident">{{categ.name}}</option>
	        </select>
	        <label for="product-filter-categ">Catégoies</label>
	      </div>
		</div>

		<!-- scateg -->
	  	<div class="col-sm">
	      <div class="form-floating mb-3">
	    	<select class="form-select" id="product-filter-scateg" name="scateg" [(ngModel)]="filter.scateg">
	          <option [ngValue]="null || undefined">Toutes les sous-catégories</option>
	          <option *ngFor="let scateg of scategs" [ngValue]="scateg.ident">{{scateg.name}}</option>
	        </select>
	        <label for="product-filter-scateg">Sous catégories</label>
	      </div>
		</div>
	</div>

	<div class="row g-3">
		<!-- gamme -->
	  	<div class="col-sm">
	      <div class="form-floating mb-3 nsb">
	    	<select class="form-select" id="product-filter-gamme" name="gamme" [(ngModel)]="filter.gamme">
	          <option [ngValue]="null || undefined">Toutes les gammes</option>
	          <option *ngFor="let gamme of gammes" [ngValue]="gamme.ident">{{gamme.name}}</option>
	        </select>
	        <label for="product-filter-gamme">Gammes</label>
	      </div>
		</div>

		<!-- marque -->
	  	<div class="col-sm">
	      <div class="form-floating mb-3 nsb">
	    	<select class="form-select" id="product-filter-marque" name="marque" [(ngModel)]="filter.marque">
	          <option [ngValue]="null || undefined">Toutes les marques</option>
	          <option *ngFor="let marque of marques" [ngValue]="marque.ident">{{marque.name}}</option>
	        </select>
	        <label for="product-filter-marque">Marques</label>
	      </div>
		</div>

		<!-- tag -->
	  	<div class="col-sm">
	      <div class="form-floating mb-3 nsb">
	    	<select class="form-select" id="product-filter-tag" name="tag" [(ngModel)]="filter.tag">
	          <option [ngValue]="null || undefined">Tous les tags</option>
	          <option *ngFor="let tag of tags" [ngValue]="tag.ident">{{tag.name}}</option>
	        </select>
	        <label for="product-filter-tag">Tags</label>
	      </div>
		</div>

	  	<div class="col-sm-1">
	  		<button type="submit" class="btn btn-primary search">
	  			<i class="fa-light fa-magnifying-glass"></i>
	  		</button>
	  	</div>
	</div>
</form>
