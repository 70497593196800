import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Shipper, Ship } from '@models/shipping';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class ShippingService{
	private shippers: Shipper[];
	private loadAdmin: boolean=false;

	constructor(private api: ApiService){ }

	getShippers(admin: boolean=false){
		if(this.shippers && (!admin || this.loadAdmin))
			return of(this.shippers);
		else
			return this.api.get<Shipper[]>('/shippers', {admin})
				.pipe(
					tap(shippers => {
						this.shippers=shippers;
						if(admin)
							this.loadAdmin=true;
					})
				);
	}

	getShipper(ident: string){
		return this.getShippers()
			.pipe(
				map((shippers: Shipper[]) => shippers.find(s => s.ident==ident))
			);
	}

	setShipper(shipper: Shipper){
		let update: boolean=(shipper.ident!=null);
		let url: string='/shippers';
		if(update)
			url+="/"+shipper.ident;
		return this.api.post<Shipper>(url, shipper)
			.pipe(
				tap(shipper => {
					if(this.shippers){
						if(!update)
							this.shippers.push(shipper);
						else
							this.shippers.splice(this.shippers.findIndex(s => shipper.ident==s.ident), 1, shipper);
					}
				})
			);
	}

	rmShipper(ident: string){
		return this.api.delete(`/shippers/${ident}`)
			.pipe(
				tap(() => {
					if(this.shippers)
						this.shippers.splice(this.shippers.findIndex(s => s.ident==ident), 1);
				})
			);
	}

	getColissimoToken(){
		return this.api.get<any>('/shippings/colissimo/token');
	}

	getShips(order: string, admin: boolean=false){
		let url: string='/shippings/my-orders/'+order;
		if(admin)
			url='/shippings/orders/'+order;
		return this.api.get<Ship>(url);
	}

	doExpColissimo(order: string){
		return this.api.get<Ship>(`/shippings/colissimo/exepditions/${order}`);
	}

	doExpMR(order: string){
		return this.api.get<Ship>(`/shippings/mondial-relay/exepditions/${order}`);
	}

	tagColissimo(order: string){
		return this.api.openGetPDF(`/shippings/colissimo/labels/${order}`);
	}
}