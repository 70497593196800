<gob-header></gob-header>
<div class="block">
	<div class="home-container">
		<div>
			<img src="assets/img/logo/logo.png"/>
		</div>
		<div>
			<h1>Bonjour, {{user.name}} !</h1>
			Ravi de vous revoir, voici ce qui se passe aujourd'hui :
		</div>
	</div>

	<div class="action-container">
		<!-- manager -->
		<a [routerLink]="route.get('order-list')"*ngIf="userServ.hasRight('orders')" class="action border-gradient box-shadow">
			<span class="action-icon">
				<i class="fad fa-file-invoice-dollar"></i>
			</span>
			<span class="action-content">
				<b>Les commandes</b><br/>
				Gérer les commandes
			</span>
		</a>

		<a [routerLink]="route.get('product-list')"*ngIf="userServ.hasRight('products')" class="action border-gradient box-shadow">
			<span class="action-icon">
				<i class="fa-light fa-game-console-handheld"></i>
			</span>
			<span class="action-content">
				<b>Les produits</b><br/>
				Gerer les produits
			</span>
		</a>

		<a [routerLink]="route.get('review-list')"*ngIf="userServ.hasRight('ratings')" class="action border-gradient box-shadow">
			<span class="action-icon">
				<i class="fa-light fa-star-sharp-half-stroke"></i>
			</span>
			<span class="action-content">
				<b>Les avis</b><br/>
				Valider les avis
			</span>
		</a>

		<!-- client -->
		<a [routerLink]="route.get('my-order')" class="action border-gradient box-shadow">
			<span class="action-icon">
				<i class="fa-light fa-box"></i>
			</span>
			<span class="action-content">
				<b>Mes commandes</b><br/>
				Voir mes commandes
			</span>
		</a>

		<a [routerLink]="route.get('my-account')" class="action border-gradient box-shadow">
			<span class="action-icon">
				<i class="fa-light fa-user"></i>
			</span>
			<span class="action-content">
				<b>Mes informations</b><br/>
				Voir mes info
			</span>
		</a>

		<a [routerLink]="route.get('my-addresses')" class="action border-gradient box-shadow">
			<span class="action-icon">
				<i class="fa-light fa-address-book"></i>
			</span>
			<span class="action-content">
				<b>Mes Adresses</b><br/>
				Voir mes commandes
			</span>
		</a>
	</div>
</div>