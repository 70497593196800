import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { GlobalService } from '@services/global.service';
import { Categ, SCateg, Gamme, Marque, Tag } from '@models/product';
import { MenuItem, Setting } from '@models/global';
import { environment } from '@environments/environment';

@Component({
  selector: 'gob-setting-form',
  templateUrl: './setting-form.component.html',
  styleUrls: ['./setting-form.component.css']
})
export class SettingFormComponent implements OnInit {
  loading: boolean=false;
  error: string;
  msg: string;
  categs: Categ[];
  scategs: SCateg[];
  gammes: Gamme[];
  marques: Marque[];
  tags: Tag[];
  settings: Setting[]=[];
  img: File;
  endH: string; 

  set isNews(value: boolean){
    let setting: Setting=this.settings.find(s => s.name=='selection.news.active');
    if(value)
      setting.val='true';
    else
      setting.val='false';
  }

  get isNews(){
    return this.settings.find(s => s.name=='selection.news.active').val=='true';
  }

  set isPromo(value: boolean){
    let setting: Setting=this.settings.find(s => s.name=='selection.promo.active');
    if(value)
      setting.val='true';
    else
      setting.val='false';
  }

  get isPromo(){
    return this.settings.find(s => s.name=='selection.promo.active').val=='true';
  }

  set isPos3(value: boolean){
    let setting: Setting=this.settings.find(s => s.name=='selection.pos3.active');
    if(value)
      setting.val='true';
    else
      setting.val='false';
  }

  get isPos3(){
    return this.settings.find(s => s.name=='selection.pos3.active').val=='true';
  }

  set pos3Type(value: string){
    this.settings.find(s => s.name=='selection.pos3.type').val=value;
  }

  get pos3Type(){
    return this.settings.find(s => s.name=='selection.pos3.type').val;
  }

  set pos3Value(value: string){
    this.settings.find(s => s.name=='selection.pos3.val').val=value;
  }

  get pos3Value(){
    return this.settings.find(s => s.name=='selection.pos3.val').val;
  }

  set pos3Name(value: string){
    this.settings.find(s => s.name=='selection.pos3.name').val=value;
  }

  get pos3Name(){
    return this.settings.find(s => s.name=='selection.pos3.name').val;
  }

  set isPos4(value: boolean){
    let setting: Setting=this.settings.find(s => s.name=='selection.pos4.active');
    if(value)
      setting.val='true';
    else
      setting.val='false';
  }

  get isPos4(){
    return this.settings.find(s => s.name=='selection.pos4.active').val=='true';
  }

  set pos4Type(value: string){
    this.settings.find(s => s.name=='selection.pos4.type').val=value;
  }

  get pos4Type(){
    return this.settings.find(s => s.name=='selection.pos4.type').val;
  }

  set pos4Value(value: string){
    this.settings.find(s => s.name=='selection.pos4.val').val=value;
  }

  get pos4Value(){
    return this.settings.find(s => s.name=='selection.pos4.val').val;
  }

  set pos4Name(value: string){
    this.settings.find(s => s.name=='selection.pos4.name').val=value;
  }

  get pos4Name(){
    return this.settings.find(s => s.name=='selection.pos4.name').val;
  }

  set endDate(value: string){
    let date: string=this.dPipe.transform(value, 'yyyy-MM-dd');
    this.settings.find(s => s.name=='home.countdown.end').val=date;
  }

  get endDate(){
    return this.dPipe.transform(this.settings.find(s => s.name=='home.countdown.end').val, 'yyyy-MM-dd');
  }

  set title(value: string){
    this.settings.find(s => s.name=='home.countdown.titre').val=value;
  }

  get title(){
    return this.settings.find(s => s.name=='home.countdown.titre').val;
  }

  set details(value: string){
    this.settings.find(s => s.name=='home.countdown.details').val=value;
  }

  get details(){
    return this.settings.find(s => s.name=='home.countdown.details').val;
  }

  set url(value: string){
    this.settings.find(s => s.name=='home.countdown.url').val=value;
  }

  get url(){
    return this.settings.find(s => s.name=='home.countdown.url').val;
  }

  constructor(private gServ: GlobalService, private dPipe: DatePipe) {
    this.loading=true;
    this.initSettings();
    this.gServ.getClassification().subscribe(elements => {
      this.categs=elements.categs;
      this.scategs=elements.scategs;
      this.gammes=elements.gammes;
      this.marques=elements.marques;
      this.tags=elements.tags;
      this.settings=elements.settings;
      this.endH=this.dPipe.transform(this.settings.find(s => s.name=='home.countdown.end').val, 'HH:mm');
      this.loading=false;
    });
  }

  ngOnInit(): void {
  }

  changeSettings(){
    this.loading=true;
    this.settings.find(setting => setting.name=='home.countdown.end').val+=' '+this.endH+':00';
    this.gServ.setSettings(this.settings, this.img).subscribe({
      next: () => {
        this.msg='Modifications enregistrées';
        this.loading=false;
      },
      error: e => {
        this.error=e;
        this.loading=false;
      }
    });
  }

  getImage(){
    return `${environment.imgUrl}/skin/countdown.jpg`;
  }

  setFile(event: any){
    this.img=event.target.files[0];
  }

  setPosName(pos: number){
    //get type
    let type: string=this.pos3Type;
    if(pos==4)
      type=this.pos4Type;

    //get val
    let val: string=this.pos3Value;
    if(pos==4)
      val=this.pos4Value;

    //get name
    let name=null;
    switch(type){
      case 'categ':
        name=this.categs.find(categ => categ.ident==val)?.name;
        break;
      case 'scateg':
        name=this.scategs.find(scateg => scateg.ident==val)?.name;
        break;
      case 'gamme':
        name=this.gammes.find(gamme => gamme.ident==val)?.name;
        break;
      case 'marque':
        name=this.marques.find(marque => marque.ident==val)?.name;
        break;
      case 'tag':
        name=this.tags.find(tag => tag.ident==val)?.name;
        break;
      default:
        break;
    }

    if(pos==3)
      this.pos3Name=name;
    else
      this.pos4Name=name;
  }

  initSettings(){
    //produit nouveaté 
    this.settings.push({
      name: 'selection.news.active',
      val: 'true',
      display: 'Nouveauté'
    });

    //produit promo
    this.settings.push({
      name: 'selection.promo.active',
      val: 'true',
      display: 'Promo'
    });

    //position 3
    this.settings.push({
      name: 'selection.pos3.active',
      val: 'true',
      display: 'Position 3 - active'
    });
    this.settings.push({
      name: 'selection.pos3.type',
      val: null,
      display: 'Position 3 - type'
    });
    this.settings.push({
      name: 'selection.pos3.val',
      val: null,
      display: 'Position 3 - value'
    });
    this.settings.push({
      name: 'selection.pos3.name',
      val: null,
      display: 'Position 3 - name'
    });

    //position 4
    this.settings.push({
      name: 'selection.pos4.active',
      val: 'true',
      display: 'Position 4 - active'
    });
    this.settings.push({
      name: 'selection.pos4.type',
      val: null,
      display: 'Position 4 - type'
    });
    this.settings.push({
      name: 'selection.pos4.val',
      val: null,
      display: 'Position 4 - value'
    });
    this.settings.push({
      name: 'selection.pos4.name',
      val: null,
      display: 'Position 4 - name'
    });

    //compte à rebours
    this.settings.push({
      name: 'home.countdown.end',
      val: null,
      display: 'Date de fin'
    });

    this.settings.push({
      name: 'home.countdown.titre',
      val: '',
      display: 'Titre'
    });

    this.settings.push({
      name: 'home.countdown.details',
      val: '',
      display: 'Détails'
    });

    this.settings.push({
      name: 'home.countdown.url',
      val: '',
      display: 'url'
    });

  }

}
