<div *ngIf="url" class="back">
	<a [routerLink]="getUrl()">
		<i class="fa-solid fa-arrow-left-to-line"></i> Retour
	</a>
</div>
<div *ngIf="!url" class="back">
	<a (click)="back.emit(true)">
		<i class="fa-solid fa-arrow-left-to-line"></i> Retour
	</a>
</div>
