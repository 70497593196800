import { Component, OnInit } from '@angular/core';
import { RouteService } from '@services/route.service';

@Component({
  selector: 'gob-role-page',
  templateUrl: './role-page.component.html',
  styleUrls: ['./role-page.component.css']
})
export class RolePageComponent implements OnInit {

  constructor(public route: RouteService){ }

  ngOnInit(): void {
  }
}
