import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { tap, map, shareReplay } from 'rxjs/operators';
import { ApiService } from '@services/api.service';
import { Depot } from '@models/stock';
import { Address } from '@models/user';

@Injectable({
	providedIn: 'root'
})
export class StockService{
	private readonly depotCCObs: Observable<Depot[]>=this.getDepotCcObs();

	constructor(private api: ApiService){ }

	getDepots(){
		return this.api.get<Depot>('/depots');
	}

	getDepot(ident: string){
		return this.getDepots()
			.pipe(
				map((depots: Depot[]) => depots.find(depot => depot.ident==ident))
			)
	}

	getDepotCcObs(){
		return this.api.get<Depot[]>('/depots/click-collect')
			.pipe(
				shareReplay(1)
			);
	}

	getDepotsClickCollect(){
		return this.depotCCObs;
	}

	getAddress(depot: string){
		return this.api.get<Address>(`/depots/${depot}/address`);
	}

	setDepot(depot: Depot, address: Address){
		let url: string='/depots';
		if(depot.ident!=null)
			url+='/'+depot.ident;
		let data: any=JSON.parse(JSON.stringify(depot));
		data.address=address;
		return this.api.post<Depot>(url, data);
	}

	rmDepot(ident: string){
		return this.api.delete(`/depots/${ident}`);
	}
}