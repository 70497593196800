import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { RouteService } from '@services/route.service';
import { UserService } from '@services/user.service';
import { Role, Right } from '@models/user';

@Component({
  selector: 'gob-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.css']
})
export class RoleFormComponent implements OnInit {
  error: string;
  msg: string;
  loading: boolean=false;
  role: Role;
  rights: Right[]=[];

  constructor(public route: RouteService, private router: ActivatedRoute, private userServ: UserService) {
    this.role=new Role();
    this.loading=true;
    this.userServ.getRights()
      .pipe(
        tap(rights => this.rights=rights),
        switchMap(() => this.router.paramMap),
        switchMap(param => {
          if(param.get('id'))
            return this.userServ.getRole(param.get('id'));
          else
            return of(new Role());
        }),
        tap(role => this.role=this.clone(role))
      ).subscribe({
        next: () => this.loading=false,
        error: e => {
          this.loading=false;
          this.error=e;
        }
      });
  }

  getRightList(){
    let result: Right[]=[];
    this.rights.forEach(right => {
      if(!this.role.rights.find(r => r==right.name))
        result.push(right);
    });
    return result;
  }

  addRight(right: string){
    this.role.rights.push(right);
  }

  rmRight(right: string){
    let ind: number=this.role.rights.findIndex(e => e==right);

    if(ind!=-1)
      this.role.rights.splice(ind, 1);
  }

  update(form: any){
    this.loading=true;
    this.userServ.setRole(this.role).subscribe({
      next: () => {
        if(!this.role.ident){
          this.role=new Role();
          form.reset();
        }
        this.msg='Modifications enregistrées';
        this.loading=false;
      },
      error: e => {
        this.error=e;
        this.loading=false;
      }
    });
  }

  clone(role: Role){
    return JSON.parse(JSON.stringify(role));
  }

  ngOnInit(): void {
  }

}
