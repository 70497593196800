<div>
	<gob-header></gob-header>
	<div class="block form-admin">
		<gob-step-order [ind]="3"></gob-step-order>
		
		<div class="valid-container">
			<div>
				<div class="icon">
					<i class="fa-light fa-circle-check"></i>
				</div>
				<div>
					Votre commande a été validée.
				</div>
				<a [routerLink]="route.get('home')" class="btn green">Retour à la boutique</a>
			</div>
		</div>
	</div>
</div>