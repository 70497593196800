import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '../../ui/ui.module';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { DashboardFilterComponent } from './dashboard-filter/dashboard-filter.component';
import { PartialModule } from '@components/page/partial/partial.module';
import { HighchartsChartModule } from 'highcharts-angular';

@NgModule({
  declarations: [
    DashboardPageComponent,
    DashboardFilterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UiModule,
    PartialModule,
    HighchartsChartModule
  ],
  exports: [
    DashboardPageComponent
  ]
})
export class DashboardModule { }
