<gob-back (back)="back.emit(true)"></gob-back>
<form (ngSubmit)="doRefund(form)" #form="ngForm">
	<!-- liste des produits -->
	<div class="table-responsive">
		<table class="table">
		    <thead>
		      <tr>
		        <th scope="col">Ref</th>
		        <th scope="col">Nom</th>
		        <th scope="col">Qte</th>
		      </tr>
		    </thead>
		    <tbody class="table-group-divider">
		      <tr *ngFor="let refunded of request.transactions">
		        <td>{{getTransaction(refunded.transaction).ref}}</td>
		        <td>{{getTransaction(refunded.transaction).name}}</td>
		        <td class="qte">
					<input type="number" class="form-control" placeholder="Quantité" name="name" value="0" [(ngModel)]="refunded.qte" min="0" />
		        </td>
		      </tr>
		  </tbody>
		</table>
	</div>

	<!-- retsock -->
	<div class="form-check form-switch mb-3">
	  <input class="form-check-input" type="checkbox" id="refund-restock" name="restock" [(ngModel)]="request.restock"/>
	  <label class="form-check-label" for="refund-restock">Remettre les produits en stock</label>
	</div>

	<!-- remboursement -->
	<div class="form-floating mb-3">
		<select class="form-select" id="refund-paymentType" name="paymentType" [(ngModel)]="request.paymentType" required>
			<option [ngValue]="null">Selectionnez un mode de remboursement</option>
			<option value="paypal-payment">Paypal</option>
			<option value="tpe-payment">Monetico</option>
			<option value="divers-payment">Autre</option>
		</select>
		<label for="refund-paymentType">Mode de remboursement</label>
	</div>

	<!-- refund -->
	<div *ngIf="order.paymentObj?.type=='paypal-payment' && request.paymentType=='paypal-payment'" class="form-check form-switch mb-3">
	  <input class="form-check-input" type="checkbox" id="refund-refund" name="restock" [(ngModel)]="request.refund"/>
	  <label class="form-check-label" for="refund-refund">Remboursement Paypal auto</label>
	</div>

	<button scrollTo type="submit" class="btn green" [disabled]="form.invalid">Valider</button>
</form>
