import { Component, OnInit, Input } from '@angular/core';
import { Order } from '@models/order';

@Component({
  selector: 'gob-refund-list',
  templateUrl: './refund-list.component.html',
  styleUrls: ['./refund-list.component.css']
})
export class RefundListComponent implements OnInit {
  @Input() order: Order;
  loading: boolean=false;

  constructor() { }

  ngOnInit(): void {
  }

  getRefunded(refund: string){
    return this.order.transactions.filter(t => t.refund==refund);
  }

}
