import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'gob-step-order',
  templateUrl: './step-order.component.html',
  styleUrls: ['./step-order.component.css']
})
export class StepOrderComponent implements OnInit {
  @Input() ind: number=0;

  constructor() { }

  ngOnInit(): void {
  }

}
